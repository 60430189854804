import Applications from "../../services/api/Applications";

const state = () => ({
  runners: [],
  possibilitiesModal: false,
  currentRunner: null,
});

const getters = {
  getRunners(state) {
    return state.runners;
  },
  getCurrentRunner(state) {
    return state.currentRunner;
  },
  possibilitiesModal(state) {
    return state.possibilitiesModal
  },
};

const actions = {
  setRunners({ commit }, { page, pageSize, search}) {
    return new Promise((resolve, reject) => {
      Applications.getRunners(page, pageSize, search).then(response => {
        if (page > 1) {
          commit("APPEND_RUNNERS", response.data.runners); // Append for pagination
        } else {
          commit("SET_RUNNERS", response.data.runners); // Set new for fresh load or specific search
        }
        console.log(response)
        resolve(response.data);
      }).catch(error => {
        console.error("Error fetching applications:", error);
        reject(error);
      });
    });
  },
  getRunner({ commit }, runnerId) {
    return new Promise((resolve, reject) => {
      Applications.getRunner(runnerId).then(response => {
        commit("SET_CURRENT_RUNNER", response.data);
        resolve(response.data);
      }).catch(error => {
        console.error("Error fetching application details:", error);
        reject(error);
      });
    });
  },
  addRunner({ commit }, newApplication) {
    return new Promise((resolve, reject) => {
      Applications.addApplication(newApplication).then(response => {
        commit("ADD_RUNNERS", response.data);
        resolve(response.data);
      }).catch(error => {
        console.error("Error adding new application:", error);
        reject(error);
      });
    });
  },
  togglePossibilitiesModal({commit}) {
    commit('TOGGLE_POSSIBILITIES_MODAL');
  },
};

const mutations = {
  SET_RUNNERS(state, runners) {
    state.runners = runners;
  },
  APPEND_RUNNERS(state, runners) {
    state.runners = [...state.runners, ...runners]; // Combine old applications with new ones
  },
  SET_CURRENT_RUNNER(state, runner) {
    state.currentRunner = runner;
  },
  ADD_RUNNERS(state, newApplication) {
    state.runners.push(newApplication);
  },
  TOGGLE_POSSIBILITIES_MODAL(state) {
    state.possibilitiesModal = !state.possibilitiesModal;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
