<template>
  <div class="fill-height">
    <v-row
        v-if="Object.keys(getSelectedLocation).length === 0"
        class="text-center fill-height"
        align="center">
      <v-col>
        <v-progress-circular
            color="primary"
            indeterminate
            size="48">
        </v-progress-circular>
      </v-col>
    </v-row>
    <template v-else>
      <v-row>
        <v-col>
          <FlyoutMenuHead
              :selectedItem="getSelectedLocation"
              :type="type"
              route="locations"/>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-tabs
        v-if="getSelectedLocation"
        class="px-3 d-block transparent"
        v-model="selectedTab"
        active-class="selected-tab">
          <v-tab
              class="tab px-6 transparent"
              @click="selectTab('general')
              ">
            {{ $t('assets.devices.deviceInfo.tabs.general') }}
          </v-tab>
          <v-tab
              class="tab px-6 transparent"
              @click="selectTab('devices')">
            {{ $t('assets.devices.deviceInfo.tabs.devices') }}
          </v-tab>
          <v-tab
              class="tab px-6 transparent"
              @click="selectTab('global')">
            {{ $t('assets.devices.deviceInfo.tabs.working_time') }}
          </v-tab>
        </v-tabs>
      </v-row>
      <v-divider></v-divider>
      <LocationInfoGeneral
          @switchTab="(tab) => { selectTab(tab) }"
         v-if="$route.params.tab === 'general'"
          :location="getSelectedLocation"/>
      <LocationDevicesList
          @switchTab="(tab) => { selectTab(tab) }"
          v-else-if="$route.params.tab === 'devices'"
          :location="getSelectedLocation"/>
      <working-time-locations
           @switchTab="(tab) => { selectTab(tab) }"
           :viewMode="this.$route.params.tab"
           v-else
      />
    </template>
  </div>

</template>

<script>
import {mapGetters} from 'vuex';
import FlyoutMenuHead from '../FlyoutMenuHead';
import LocationInfoGeneral from './LocationInfoGeneral';
import LocationDevicesList from './LocationDevicesList';
import WorkingTimeLocations from './WorkingTimeLocations.vue';



export default {
  name: 'LocationInfo',
  components: {
    FlyoutMenuHead,
    LocationInfoGeneral,
    LocationDevicesList,
    WorkingTimeLocations,
 
  },
  props: ['location', 'type', 'global'],
  data: () => ({
    tabs: ['general', 'devices', 'global'],
    selectedTab: 'general',
  }),
  watch: {
    getSelectedLocation: {
      handler() {
        this.$forceUpdate()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('locations', ['getSelectedLocation'], ['getLocationDevices']),
  },
  methods: {
    selectTab(tab) {
    if (this.selectedTab !== tab) {
      this.$router.push({
        name: 'location',
        params: { ...this.$route.params, tab: tab }
      }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          console.error(error);
        }
      });
    }
  },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .theme--light.v-tabs > .v-tabs-bar {
    background-color: transparent !important;
  }

  .v-tab {
    letter-spacing: normal;
  }

  div.v-slide-group__prev.v-slide-group__prev--disabled {
    display: none !important;
  }
}

.selected-tab {
  color: var(--v-textColor-base);
}
</style>
