<template>
  <div class="pa-4 overflow-y-auto scroll-right-md-y scroll-right-lg-y">
   <div v-if="getSelectedDevice.online" class="report-overview mb-6">
     <v-row class="align-center ma-0 pa-0">
       <v-col cols="9"><span>Last check on {{ localTimeTelemetryDate }}. To refresh data, click "Refresh Data".</span></v-col>
       <v-col cols="3"><v-btn class="btn_style" @click="updateFullTelemetry()" small elevation="0" color="primary" >Refresh Data</v-btn></v-col>
     </v-row>
     </div>
   <div v-else class="report-overview-offline  mb-6">
     <v-row class="align-center ma-0 pa-0">
       <v-col cols="9"><span>Last check on {{ getSelectedDevice.telemetry_updated_at }}. Device is currently offline - to refresh data, make sure the device is turned on first.</span></v-col>
     </v-row>
     </div>
   <span ><b>Hardware & Network</b></span>
   <v-divider class="mt-4"></v-divider>
   <v-expansion-panels>
     <v-expansion-panel>
       <v-expansion-panel-header class="text-uppercase font-weight-medium">
        <span>CPU | <span :class="[colors[`${getSelectedDevice.telemetry.device.cpu.status}`].text]" class="text-capitalize font-weight-regular"> 
         {{ colors[getSelectedDevice.telemetry.device.cpu.status].value }}
       </span>
       <v-icon
         size="20"
         :color="colors[getSelectedDevice.telemetry.device.cpu.status].color">{{
         icons[getSelectedDevice.telemetry.device.cpu.status].icon
         }}</v-icon>
     </span> 
       </v-expansion-panel-header>
       <v-expansion-panel-content>
         <v-card outlined class="d-flex align-center justify-space-between pa-3">
         <div class="ml-1">
             <v-icon color="black" size="20" class="mr-2">{{ icons[getSelectedDevice.telemetry.device.cpu.status].icon }}</v-icon>
             <span class="subtitle-2 mt-1">Current CPU Utilization: <span :class="[colors[`${getSelectedDevice.telemetry.device.cpu.status}`].text]">{{ getSelectedDevice.telemetry.device.cpu.cores }} cores</span><span :class="[colors[`${getSelectedDevice.telemetry.device.cpu.status}`].text]">{{ ", " + getSelectedDevice.telemetry.device.cpu.threads }} threads</span></span>
         </div>
         </v-card>
         <v-card outlined class="d-flex align-center justify-space-between pa-3 mt-2">
         <div class="ml-1">
             <v-icon color="black" size="20" class="mr-2">{{ icons[getSelectedDevice.thermal_status].icon }}</v-icon>
             <span class="subtitle-2 mt-1">Current CPU Temperature:  <span :class="[colors[`${getSelectedDevice.thermal_status}`].text]">{{ getSelectedDevice.cpu_temperature + " ºC"}}</span></span>
         </div>
         </v-card>
         <v-row>
           <v-col class="pr-0">
         <v-card outlined elevation="0" class="pa-2">
           <div style="display: flex; al">
             <div style="flex: 0.1; margin:auto;">
               <v-icon color="black" size="20" class="mr-2">{{ icons[getSelectedDevice.telemetry.device.cpu.status].icon }}</v-icon>
             </div>
             <div style="flex: 0.9; display: flex; flex-direction: column;">
               <span class="spn_cpu_load">1-minute Load:</span>
               <span class="spn_cpu_load" :class="[colors[`${getSelectedDevice.telemetry.device.cpu.status}`].text]">{{ parseFloat(getSelectedDevice.telemetry.device.cpu.load1).toFixed(2) + " (" + parseFloat((getSelectedDevice.telemetry.device.cpu.load1 / getSelectedDevice.telemetry.device.cpu.threads) * 100).toFixed(2) +" %)"}}</span>
             </div>
           </div>
         </v-card>
           </v-col>
           <v-col class="pr-0">
             <v-card outlined elevation="0" class="pa-2">
             <div style="display: flex; al">
             <div style="flex: 0.1; margin:auto;">
               <v-icon color="black" size="20" class="mr-2">{{ icons[getSelectedDevice.telemetry.device.cpu.status].icon }}</v-icon>
             </div>
             <div style="flex: 0.9; display: flex; flex-direction: column;">
               <span class="spn_cpu_load">5-minute Load:</span>
               <span class="spn_cpu_load" :class="[colors[`${getSelectedDevice.telemetry.device.cpu.status}`].text]">{{ parseFloat(getSelectedDevice.telemetry.device.cpu.load5).toFixed(2) + " (" + parseFloat((getSelectedDevice.telemetry.device.cpu.load5 / getSelectedDevice.telemetry.device.cpu.threads) * 100).toFixed(2) +" %)" }}</span>
             </div>
           </div>
           </v-card></v-col>
           <v-col><v-card outlined elevation="0" class="pa-2">
             <div style="display: flex; al">
             <div style="flex: 0.1; margin:auto;">
               <v-icon color="black" size="20" class="mr-2">{{ icons[getSelectedDevice.telemetry.device.cpu.status].icon }}</v-icon>
             </div>
             <div style="flex: 0.9; display: flex; flex-direction: column;">
               <span class="spn_cpu_load">15-minute Load: <span class="spn_cpu_load" :class="[colors[`${getSelectedDevice.telemetry.device.cpu.status}`].text]">{{ parseFloat(getSelectedDevice.telemetry.device.cpu.load15).toFixed(2) + " (" + parseFloat((getSelectedDevice.telemetry.device.cpu.load15 / getSelectedDevice.telemetry.device.cpu.threads) * 100).toFixed(2) +" %)" }}</span></span>
             </div>
           </div>
           </v-card></v-col>
         </v-row>
       </v-expansion-panel-content>
     </v-expansion-panel>
   </v-expansion-panels>
   <v-divider></v-divider>
   <v-divider class="mt-4"></v-divider>
   <v-expansion-panels>
     <v-expansion-panel>
       <v-expansion-panel-header class="text-uppercase font-weight-medium">
        <span>UPTIME | <span :class="[colors[`${getSelectedDevice.telemetry.device.uptime.status}`].text]" class="text-capitalize font-weight-regular"> {{ colors[getSelectedDevice.telemetry.device.uptime.status].value }}</span>
        <v-icon
         class="ml-1"
         size="20"
         :color="colors[getSelectedDevice.telemetry.device.uptime.status].color">{{
         icons[getSelectedDevice.telemetry.device.uptime.status].icon
         }}</v-icon>
         </span> 
       </v-expansion-panel-header>
       <v-expansion-panel-content>
         <v-card outlined class="d-flex align-center justify-space-between pa-3 mb-3">
         <div class="d-flex flex-row ml-1">
             <v-icon color="black" size="20" class="mr-2">{{ icons[getSelectedDevice.telemetry.device.uptime.status].icon }}</v-icon>
             <span class="subtitle-2 mt-1">Uptime: <span :class="[colors[`${getSelectedDevice.telemetry.device.uptime.status}`].text]">{{ secondsToHourlyFormat(getSelectedDevice.telemetry.device.uptime.uptime) }}</span></span>
         </div>
         <v-btn class="btn_style_rbt" elevation="0" v-if="getSelectedDevice.telemetry.device.uptime.status !== 0">Reboot Now</v-btn>
         </v-card>       
         <v-card outlined class="d-flex align-center justify-space-between pa-3">
         <div class="d-flex flex-row ml-1">
             <v-icon color="black" size="20" class="mr-2">{{ icons[getSelectedDevice.telemetry.device.uptime.status].icon }}</v-icon>
             <span class="subtitle-2 mt-1">Idle: <span :class="[colors[`${getSelectedDevice.telemetry.device.uptime.status}`].text]">{{ secondsToHourlyFormat(getSelectedDevice.telemetry.device.uptime.idle) }}</span></span>
         </div>
         </v-card>       
       </v-expansion-panel-content>
     </v-expansion-panel>
   </v-expansion-panels>
   <v-divider></v-divider>
   <v-divider class="mt-4"></v-divider>
   <v-expansion-panels>
     <v-expansion-panel>
       <v-expansion-panel-header class="text-uppercase font-weight-medium">
        <span>MEMORY | <span :class="[colors[`${getSelectedDevice.telemetry.device.ram.status}`].text]" class="text-capitalize font-weight-regular">{{ colors[getSelectedDevice.telemetry.device.ram.status].value  }}
       </span>
       <v-icon
         size="20"
         :color="colors[getSelectedDevice.telemetry.device.ram.status].color">{{
         icons[getSelectedDevice.telemetry.device.ram.status].icon
         }}</v-icon>
     </span>  
       </v-expansion-panel-header>
       <v-expansion-panel-content>
         <v-card outlined class="d-flex align-center justify-space-between pa-3 mb-4">
         <div class="d-flex flex-row ml-1">
             <v-icon color="black" size="20" class="mr-2">{{ icons[getSelectedDevice.telemetry.device.ram.status].icon }}</v-icon>
             <span class="subtitle-2 mt-1">Ram: <span :class="[colors[`${getSelectedDevice.telemetry.device.ram.status}`].text]">{{ ramUsed }} GB <span class="black--text font-weight-regular">used</span> / {{ ramTotal }} GB <span class="black--text font-weight-regular">{{ "(" + ramPercent + "%" + ")" }}</span></span></span>
         </div>
         </v-card>   
         <v-card outlined class="d-flex align-center justify-space-between pa-3">
         <div class="d-flex flex-row ml-1">
             <v-icon color="black" size="20" class="mr-2">{{ icons[getSelectedDevice.telemetry.device.ram.status].icon }}</v-icon>
             <span class="subtitle-2 mt-1">Swap: <span :class="[colors[`${getSelectedDevice.telemetry.device.ram.status}`].text]">{{ swapFree }} GB <span class="black--text font-weight-regular">used</span> / {{ swapTotal }} GB <span class="black--text font-weight-regular">{{ "(" + swapPercent + "%" + ")" }}</span></span></span>
         </div>
         </v-card>   
       </v-expansion-panel-content>
     </v-expansion-panel>
   </v-expansion-panels>
   <v-divider></v-divider>
   <v-divider class="mt-4"></v-divider>
   <v-expansion-panels>
     <v-expansion-panel>
       <v-expansion-panel-header class="text-uppercase font-weight-medium">
        <span>DISK | <span :class="[colors[`${getSelectedDevice.telemetry.device.storage.status}`].text]" class="text-capitalize font-weight-regular">{{ colors[getSelectedDevice.telemetry.device.storage.status].value }}
       </span>
       <v-icon
         size="20"
         :color="colors[getSelectedDevice.telemetry.device.storage.status].color">{{
         icons[getSelectedDevice.telemetry.device.storage.status].icon
         }}</v-icon>
     </span>  
       </v-expansion-panel-header>
       <v-expansion-panel-content class="mb-3">
         <v-card outlined class="d-flex align-center justify-space-between pa-3 mb-3">
         <div class="d-flex flex-row ml-1">
         <v-icon color="black" size="20" class="mr-2">{{ icons[getSelectedDevice.telemetry.device.storage.status].icon }}</v-icon>
         <span class="subtitle-2 mt-1">Partitions: <span :class="[colors[`${getSelectedDevice.telemetry.device.storage.status}`].text]">{{ getSelectedDevice.telemetry.device.storage.partitions.length }}</span></span> 
         </div>
       </v-card>
         <v-card v-for="(item, i) in getSelectedDevice.telemetry.device.storage.partitions" :key="i" outlined class="d-flex align-center justify-space-between pa-3 mb-3">
         <div class="d-flex flex-row ml-1">
         <v-icon color="black" size="20" class="mr-2">{{ icons[getSelectedDevice.telemetry.device.storage.status].icon }}</v-icon>
         <span class="subtitle-2 mt-1">{{ item.name }}: <span :class="[colors[`${getSelectedDevice.telemetry.device.storage.status}`].text]">
           {{ ( (item.size - item.free) / (1024*1024*1024)).toFixed(2) }} GB 
           <span class="secondary--text font-weight-regular">used / </span> {{ (item.size / (1024*1024*1024)).toFixed(2) }} GB 
           <span class="black--text font-weight-regular">{{ "(" + (((item.size - item.free)/item.size) * 100).toFixed(2) + "%" + ")" }}</span></span></span> 
         </div>
       </v-card>
       </v-expansion-panel-content>
     </v-expansion-panel>
   </v-expansion-panels>
   <v-divider></v-divider>
   <v-divider class="mt-4"></v-divider>
   <v-expansion-panels disabled>
     <v-expansion-panel>
       <v-expansion-panel-header class="text-uppercase font-weight-medium">
        <span >GPU UTIL | <span :class="[colors[`${getSelectedDevice.telemetry.device.storage.status}`].text]" class="text-capitalize font-weight-regular" >{{ colors[getSelectedDevice.telemetry.device.storage.status].value }}
       </span>
       <v-icon
         size="20"
         :color="colors[getSelectedDevice.telemetry.device.storage.status].color">{{
         icons[getSelectedDevice.telemetry.device.storage.status].icon
         }}</v-icon>
     </span> 
       </v-expansion-panel-header>
       <v-expansion-panel-content>
         Partitions: {{ getSelectedDevice.telemetry.device.storage.partitions.length }}
       </v-expansion-panel-content>
       <v-expansion-panel-content>
         {{ getSelectedDevice.telemetry.device.storage.partitions.length }}
       </v-expansion-panel-content>
     </v-expansion-panel>
   </v-expansion-panels>
   <v-divider class="pt-4"></v-divider>
   <v-divider></v-divider>
   <v-expansion-panels>
     <v-expansion-panel>
       <v-expansion-panel-header class="text-uppercase font-weight-medium">
        <span>NETWORK | <span :class="[colors[`${getSelectedDevice.telemetry.device.storage.status}`].text]" class="text-capitalize font-weight-regular">{{ colors[getSelectedDevice.telemetry.device.storage.status].value }}
       </span>
       <v-icon
         size="20"
         :color="colors[getSelectedDevice.telemetry.device.storage.status].color">{{
         icons[getSelectedDevice.telemetry.device.storage.status].icon
         }}</v-icon>
     </span> 
       </v-expansion-panel-header>
       <v-expansion-panel-content>
         <v-card outlined class="d-flex align-center justify-space-between pa-3 mb-3">
         <div class="d-flex flex-row ml-1">
         <v-icon color="black" size="20" class="mr-2">{{ icons[getSelectedDevice.telemetry.device.storage.status].icon }}</v-icon>
         <span class="spn_dsgn"><span>Network Status: </span><span :class="[colors[`${getSelectedDevice.telemetry.device.storage.status}`].text]" class="font-weight-normal spn_dsgn">{{ colors[getSelectedDevice.telemetry.device.storage.status].value }}</span></span>  
       </div>
       </v-card>
         <v-card outlined class="d-flex align-center justify-space-between pa-3 mb-3">
         <div class="d-flex flex-row ml-1">
         <v-icon color="black" size="20" class="mr-2">{{ icons[getSelectedDevice.telemetry.device.storage.status].icon }}</v-icon>
         <span class="spn_dsgn"><span>Inbound Traffic: </span><span :class="[colors[`${getSelectedDevice.telemetry.device.storage.status}`].text]" class="font-weight-normal spn_dsgn">{{ inboundTraffic }}</span></span>  
       </div>
       </v-card>
         <v-card outlined class="d-flex align-center justify-space-between pa-3 mb-3">
         <div class="d-flex flex-row ml-1">
         <v-icon color="black" size="20" class="mr-2">{{ icons[getSelectedDevice.telemetry.device.storage.status].icon }}</v-icon>
         <span class="spn_dsgn"><span>Outbound Traffic: </span><span :class="[colors[`${getSelectedDevice.telemetry.device.storage.status}`].text]" class="font-weight-normal spn_dsgn">{{ outboundTraffic }}</span></span>  
         </div>
       </v-card>
       </v-expansion-panel-content>
     </v-expansion-panel>
   </v-expansion-panels>
   <v-divider class="pt-4"></v-divider>
   <v-divider></v-divider>
     <v-expansion-panels v-if="this.getSelectedDevice.telemetry.peripherals.devicesInfo.length > 0">
     <v-expansion-panel v-for="(item, i) in uniqueStatusPeripherals" :key="i">
       <v-expansion-panel-header class="text-uppercase font-weight-medium">
        <span>{{ item.type }} | <span class="text-capitalize font-weight-regular" :class="[colors[`${item.combined_status}`].text]" >{{ colors[item.combined_status].value }}
       </span>
       <v-icon
         size="20"
         :color="colors[item.combined_status].color">{{
         icons[item.combined_status].icon
         }}</v-icon>
     </span> 
       </v-expansion-panel-header>
       <v-expansion-panel-content>
         <v-card outlined class="d-flex align-center justify-space-between pa-3">
         <div class="d-flex flex-row ml-1">
         <v-icon color="black" size="20" class="mr-2">{{ icons[item.combined_status].icon }}</v-icon>
         <span class="spn_dsgn" ><span>Name: </span><span :class="[colors[`${ item.combined_status }`].text]"  class="font-weight-normal spn_dsgn">{{ item.name }}</span></span>  
       </div>
       </v-card>
       </v-expansion-panel-content>
       <v-expansion-panel-content>
         <v-card outlined class="d-flex align-center justify-space-between pa-3">
         <div class="d-flex flex-row ml-1">
         <v-icon color="black" size="20" class="mr-2">{{ icons[getSelectedDevice.telemetry.device.storage.status].icon }}</v-icon>
         <span class="spn_dsgn"><span>Enabled: </span><span :class="[colors[`${ item.enabled}`].text]" class="font-weight-normal spn_dsgn">{{ item.enabled === false ? 'No':'Yes' }}</span></span>  
       </div>
       </v-card>
       </v-expansion-panel-content>
       <v-expansion-panel-content>
         <v-card outlined class="d-flex align-center justify-space-between pa-3"  v-if="item.enabled">
         <div class="d-flex flex-row ml-1">
         <v-icon color="black" size="20" class="mr-2">{{ icons[getSelectedDevice.telemetry.device.storage.status].icon }}</v-icon>
         <span class="spn_dsgn"><span>Status: </span><span v-if="item.combined_status === 0" class="primary--text"> Operational </span><span v-else v-for="(item, i) in item.status" :key="i" :class="colors[item.status].text" class="font-weight-normal spn_dsgn">{{ item.name }}<span>, </span> </span></span>  
       </div>
       </v-card>
       </v-expansion-panel-content>
       <v-divider class="pt-4"></v-divider>
     </v-expansion-panel>
   </v-expansion-panels>
  </div>
 </template>
 <script>
 import Monitoring from "@/services/api/Monitoring";
 import { mapActions, mapGetters } from 'vuex';
 import { mdiCheckCircle, mdiAlertCircle, mdiAlertOctagram } from '@mdi/js';
 export default {
 name: "MonitorDetailView",
 data: () => ({
   colors: {
       0 : {
         "text": "primary--text",
         "color": "primary",
         "value": "Healthy",
         "icon": mdiCheckCircle
       },
       1 : {
         "text": "amber--text",
         "color": "amber",
         "value": "Warning",
         "icon": mdiAlertCircle
       },
       2 : {
         "text": "red--text",
         "color": "red",
         "value": "Critical",
         "icon": mdiAlertOctagram
       },
       3 : {
         "text": "red--text",
         "color": "red",
         "value": "Critical",
         "icon": mdiAlertOctagram
       },
       true : {
         "text": "primary--text",
         "color": "primary",
         "value": "Yes",
         "icon": mdiCheckCircle
       },
       false: {
         "text": "red--text",
         "color": "red",
         "value": "No",
         "icon": mdiCheckCircle
       },
     },
     icons:{
       0: {
         "icon": mdiCheckCircle
       },
       1 : {
         "icon": mdiAlertCircle
       },
       2 : {
         "icon": mdiAlertOctagram
       },
       3 : {
         "icon": mdiAlertOctagram
       },
       true : {
         "icon": mdiCheckCircle
       },
       false: {
         "icon": mdiCheckCircle
       },
     }
 }),
 computed:{
   ...mapGetters({'getSelectedDevice':'devices/getSelectedDevice'}),
   localTimeTelemetryDate(){
     const utcDate = this.getSelectedDevice.telemetry_updated_at;
     const date = new Date(utcDate);
     return date.toLocaleString();
   },
   inboundTraffic(){
     const rx =  this.getSelectedDevice.telemetry.device.network.device[0].rx_bytes;
     return this.calculateDataUsage(rx);
   },
   outboundTraffic(){
     const tx =  this.getSelectedDevice.telemetry.device.network.device[0].tx_bytes;
     return this.calculateDataUsage(tx);
   },
   swapPercent(){
     const initialValue = this.getSelectedDevice.telemetry.device.ram.swap_free;
     const totalValue = this.getSelectedDevice.telemetry.device.ram.swap_total;
     const percentage = (((totalValue - initialValue)/ totalValue))
     if(percentage * 100 < 1){
      return percentage.toFixed(2);
     }else{
      return (percentage * 100).toFixed(2);
     }
   },
   ramPercent(){
     const initialValue = this.getSelectedDevice.telemetry.device.ram.free;
     const totalValue = this.getSelectedDevice.telemetry.device.ram.total;
     const remainder = totalValue - initialValue;
     const percentage = ((remainder / totalValue)*100)
     return percentage.toFixed(2);
   },
   ramUsed(){
     return ((this.getSelectedDevice.telemetry.device.ram.total - this.getSelectedDevice.telemetry.device.ram.free) / (1024*1024)).toFixed(2) 
   },
   ramTotal(){
     return (this.getSelectedDevice.telemetry.device.ram.total / (1024*1024)).toFixed(2) 
   },
   swapFree(){
     return ((Math.round(this.getSelectedDevice.telemetry.device.ram.swap_total - this.getSelectedDevice.telemetry.device.ram.swap_free)) / (1024*1024)).toFixed(2)
   },
   swapTotal(){
     return ((Math.round(this.getSelectedDevice.telemetry.device.ram.swap_total)) / (1024*1024)).toFixed(2)
   },
   uniqueStatusPeripherals(){
       let peripherals_status = this.getSelectedDevice.peripherals_status;
       let arr = this.getSelectedDevice.telemetry.peripherals.devicesInfo;
       let uniqueperipherals = arr.filter((obj, index) => {
                 return index === arr.findIndex(o => obj.name === o.name);
               });
               uniqueperipherals.forEach(function (arrayItem) {
                const keysArray = Object.keys(arrayItem); 
                console.log(keysArray)
              });
       let statusMapping = {};
       peripherals_status.forEach(item => {
           statusMapping[item.device] = item.status;
       });

       return uniqueperipherals;
       }
     },
 methods:{
   ...mapActions({"getSpecificDevice": "devices/getSpecificDevice"}),
   updateFullTelemetry(){
     Monitoring.getFullTelemetryInfo(this.getSelectedDevice.uuid);
   },
  secondsToHourlyFormat(seconds){
      var date = new Date(null);
      date.setSeconds(seconds);
      return date.toISOString().substr(11, 8); 
    },
  calculateDataUsage(totalData) {
    let sizeUnit;
    let sizeRepresentation;
    if (totalData >= 1000000000) {
      sizeUnit = 'GB';
      sizeRepresentation = (totalData / (1024*1024*1024)).toFixed(2);
      }else if (totalData >= 10000) {
      sizeUnit = 'MB';
      sizeRepresentation = (totalData / (1024*1024)).toFixed(2);
      }else if(totalData < 10000){
      sizeUnit = 'KB';
      sizeRepresentation = (totalData/1024).toFixed(2);
    }
  return `${sizeRepresentation} ${sizeUnit}`
 }
 },

 
 }
 </script>
 <style>
 .report-overview {
   background: #EFF7FF;
   border: 1px solid #2B85D8;
   box-sizing: border-box;
   border-radius: 4px;
   font-size: small;
 }
 .report-overview-offline {
   background: #FFEBEE;
   border: 1px solid #D32F2F;
   box-sizing: border-box;
   border-radius: 4px;
   font-size: small;
 }
 .btn_style{
   text-transform: unset;
 }
 .header_panel{
   font-weight: 600;
 }
 .btn_style_rbt{
 color: var(--white-theme-primary-text-white-theme, #222);
 text-transform: unset;
 text-align: center;
 font-family: Roboto;
 font-size: 14px;
 font-style: normal;
 font-weight: 400;
 line-height: normal;
 }
 .spn_dsgn{
   text-transform: unset !important;
   font-family: Roboto;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
 }
 .spn_cpu_load{
  font-size: 15px;
  font-weight: 500;
 }
 </style>
