var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"feed-item pa-2",attrs:{"to":_vm.routeId,"flat":"","outlined":""}},[_c('v-card-title',{staticClass:"pa-0"},[_c('div',{class:[
        _vm.data['connected_status'] ==='connected' && _vm.data['online'] === true ? 'online_device': 
        _vm.data['connected_status'] ==='connected' && _vm.data['online'] === false ? 'offline_device': 
        _vm.data['connected_status'] ==='non_connected'? 'non_connected': 
        _vm.data['connected_status'] ==='disconnected' || 
        _vm.data['connected_status'] === 'archived' || 
        _vm.data['connected_status'] ==='installation_pending' ? 'offline': 'uninstalled_device',
        'item-icon', this.isDarkTheme ? 'dark-border' : 'light-border'
        ]},[_c('v-icon',{class:_vm.data['connected_status'] === 'connected' 
          ? 'connected_icon' : _vm.data['connected_status'] === 'disconnected' || 
          _vm.data['connected_status'] === 'installation_pending' || 
          _vm.data['connected_status'] === 'archived'
          ? 'disconnected_icon' : _vm.data['connected_status']==='non_connected' 
          ? 'non_connected_icon': null,attrs:{"size":"18"}},[_vm._v(_vm._s(_vm.data["connected_status"] === "connected" || _vm.data["connected_status"] === "disconnected" || _vm.data["connected_status"] === "installation_pending" || _vm.data['connected_status'] === 'archived' ? _vm.icons[_vm.data["device_type_name"]] : _vm.icons.mdiDevices)+" ")])],1),_c('div',{staticClass:"item-info"},[_c('v-card-text',{staticClass:"px-0 py-1"},[(_vm.data)?_c('v-list-item-title',{staticClass:"font-weight-medium letter-spacing-normal"},[_vm._v(" "+_vm._s(_vm.data["name"])+" "),(_vm.data['online'] && _vm.type !== 'location')?_c('v-icon',{staticStyle:{"margin-bottom":"2px"},attrs:{"size":"8","color":"green"}},[_vm._v(" "+_vm._s(_vm.$vuetify.icons.values.circle)+" ")]):(!_vm.data['online'] && _vm.type !== 'location')?_c('v-icon',{staticStyle:{"margin-bottom":"2px"},attrs:{"size":"8","color":"red"}},[_vm._v(" "+_vm._s(_vm.$vuetify.icons.values.circle)+" ")]):(
              _vm.data['connected_status'] === 'installation_pending' &&
              _vm.type !== 'location'
            )?_c('v-icon',{staticStyle:{"margin-bottom":"2px"},attrs:{"size":"8","color":"blue"}},[_vm._v(" "+_vm._s(_vm.$vuetify.icons.values.circle)+" ")]):_vm._e()],1):_vm._e()],1),(_vm.type !== 'location')?_c('span',{staticClass:"subtitle-2 font-weight-regular text-secondary"},[_vm._v(_vm._s(_vm.data["device_type_name"] !== "Unknown" ? _vm.data["device_type_name"] : "Unknown Device Type"))]):_vm._e(),(_vm.type === 'location')?_c('span',{staticClass:"subtitle-2 font-weight-regular text-secondary"},[_vm._v(_vm._s(_vm.data["address"]))]):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }