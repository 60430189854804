<template>
  <v-card 
  :to="routeId"
  flat class="feed-item pa-2" outlined >
    <v-card-title class="pa-0">
      <!-- Dynamic Icon for Devices -->
      <div
      v-bind:class="[
          data['connected_status'] ==='connected' && data['online'] === true ? 'online_device': 
          data['connected_status'] ==='connected' && data['online'] === false ? 'offline_device': 
          data['connected_status'] ==='non_connected'? 'non_connected': 
          data['connected_status'] ==='disconnected' || 
          data['connected_status'] === 'archived' || 
          data['connected_status'] ==='installation_pending' ? 'offline': 'uninstalled_device',
          'item-icon', this.isDarkTheme ? 'dark-border' : 'light-border'
          ]"
      >
        <v-icon size="18"
        v-bind:class="data['connected_status'] === 'connected' 
            ? 'connected_icon' : data['connected_status'] === 'disconnected' || 
            data['connected_status'] === 'installation_pending' || 
            data['connected_status'] === 'archived'
            ? 'disconnected_icon' : data['connected_status']==='non_connected' 
            ? 'non_connected_icon': null"
        >{{
            data["connected_status"] === "connected" || 
            data["connected_status"] === "disconnected" || 
            data["connected_status"] === "installation_pending" || 
            data['connected_status'] === 'archived'
                ? icons[data["device_type_name"]]
                : icons.mdiDevices
          }}
        </v-icon>
      </div>


      <!-- Info container nsoft mostar-->

      <div class="item-info">
        <v-card-text class="px-0 py-1">
          <v-list-item-title
              class="font-weight-medium letter-spacing-normal"
              v-if="data"
          >
            {{ data["name"] }}

            <v-icon
                v-if="data['online'] && type !== 'location'"
                size="8"
                color="green"
                style="margin-bottom: 2px"
            >
              {{ $vuetify.icons.values.circle }}
            </v-icon>
            <v-icon
                v-else-if="!data['online'] && type !== 'location'"
                size="8"
                color="red"
                style="margin-bottom: 2px"
            > {{ $vuetify.icons.values.circle }}
            </v-icon>

            <v-icon
                v-else-if="
                data['connected_status'] === 'installation_pending' &&
                type !== 'location'
              "
                size="8"
                color="blue"
                style="margin-bottom: 2px"
            >
              {{ $vuetify.icons.values.circle }}
            </v-icon>
          </v-list-item-title>
        </v-card-text>
        <span
            class="subtitle-2 font-weight-regular text-secondary"
            v-if="type !== 'location'"
        >{{
            data["device_type_name"] !== "Unknown"
                ? data["device_type_name"]
                : "Unknown Device Type"
          }}</span
        >
        <span
            class="subtitle-2 font-weight-regular text-secondary"
            v-if="type === 'location'"
        >{{ data["address"] }}</span
        >
      </div>
    </v-card-title>
  </v-card>
</template>


<script>
import {mdiCctv, mdiDevices, mdiLaptop, mdiRaspberryPi, mdiTelevision} from "@mdi/js";
import { mapActions } from 'vuex';


export default {
  props: ["data", "type"],
  data: () => ({
    open: false,
    routeId: {
      name: "",
      params: {},
    },
    icons: {
      "mdiDevices": mdiDevices,
      "Linux Computer": mdiLaptop,
      "IP Camera": mdiCctv,
      "RaspberryPi": mdiRaspberryPi,
      "Android TV": mdiTelevision,
      "Unknown": mdiDevices,
      "Windows Computer": mdiLaptop,
    },
  }),
   watch: {
    data: {
      handler() {
        this.$forceUpdate();
      },
      deep: true
    }
  },
  methods: {
     ...mapActions({
      "getSpecificDevice": "devices/getSpecificDevice",
    }),
    avatarName(string) {
      var matches = string.match(/\b(\w)/g);
      matches.splice(3);
      return matches.join("");
    },
    getColor(type) {
      var colors = {
        "Office Building": "#FF005C",
        "Single Office": "#E65639",
        Shop: "#006BF5",
        Factory: "#232C33",
        Warehouse: "#8335DB",

        Home: "#01A952",
        Apartment: "#83CC00",
        "Event Venue": "#F7B500",
        Mall: "#00BCB5",
        Land: "#EBCC09",
        Mobile: "#425BD9",
        Other: "#BCBCBC",
      };
      return colors[type];
    },

  
  },
  computed:{
    isDarkTheme() {
      return this.$vuetify.theme.dark || false;
    },
  },
  mounted(){
      this.routeId = {
        name: "device_location",
        params: {
          device_type: this.$props.type || 'device',
          action: "view",
          uuid: this.$props.data.uuid,
          tab: "general",
        },
      };
    }
};
</script>
<style scoped lang="scss">
::v-deep {
  .short{
  width:100px;
}
.short span{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
  .v-chip {
    height: 16px;
    background-color: #e4e5e6 !important;
    border-radius: 4px !important;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: -1px;
  }
  

  .v-chip__content {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: var(--v-secondaryText-base);
  }
}
.icon_item{
  margin-left:0;
}
.feed-item {
  transition: all 0.1s ease;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    border: solid 1px var(--v-secondaryText-base);
  }

  .slight-radius_light {
    border-radius: 4px !important;
    border: 1px solid black !important;
    
  }
  .slight-radius_dark {
    border-radius: 4px !important;
    border: 1px solid white !important;
    
  }

  .item-icon {
    padding: 20px;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    
  }

  .installed {
    background-color: var(--v-blueishGray-base) !important; //background color for icon
    border: solid 1px var(--v-borderIcon-base);
  }

  .offline {
    background-color: #222222 !important;
    border: solid 1px var(--v-borderIcon-base);
  }
  .non_connected{
    background-color: var(--v-lightGrey-base) !important;
  }

  .connected_icon {
    color: var(--v-general_bckgr-base) !important;
  }

  .disconnected_icon {
    color: var(--v-secondaryText-base) !important;
  }

  .non_connected_icon {
    color: var(--v-secondaryText-base) !important;
  }
  .online_device{
    background-color: #2b85d8 !important;
    opacity:1;
  }
  .offline_device{
    background-color: #95C2EC !important;
    opacity: 0.8;
  }
  .uninstalled_device{
    border: 1px solid black;
  }


}

.item-info {
  margin-left: 16px;
  margin-top: -1px;
  text-overflow: ellipsis;
  max-width: 15ch !important;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  
}
.dark-border {
  border:1px solid white;
}

.light-border {
  border:1px solid black;
}

</style>
