import Vue from 'vue'
import Vuex from 'vuex'
import devices from './modules/devices.store'
import locations from './modules/locations.store'
import global from './modules/global.store'
import iam from './modules/iam.store'
import accounts from './modules/accounts.store'
import tags from './modules/tags.store'
import monitoring from './modules/monitoring.store'
import applications from './modules/applications.store'
import runners from './modules/runners.store'
import groups from './modules/groups.store'
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    devices,
    locations,
    global,
    accounts,
    iam,
    tags,
    monitoring,
    applications,
    runners,
    groups
  },
  state: {
    locationDisplayId: "",
    alert: {
      type: "",
      message: "",
      shown: false,
      constant: false,
      buttonLink: null,
    },
    messageType: null,
    showDeviceInfo: false,
    showAddLocation: false,
    showLocationInfo: false,
    showAddDeviceRunner: false,
    showEditDevice: false,
    showEditLocation: false,
    selectedDevice: null,
    selectedLocation: null,
    lang: localStorage.getItem('lang'),
    view: "grid",
    user_email: ""
  },
  mutations: {
    SET_MESSAGE_TYPE(state, payload){
      state.messageType = payload;
    },
    SET_LOCATION_DISPLAY_ID(state, payload){
      state.locationDisplayId = payload;
    },
    CLEAR_DISPLAY_ID_ERROR(state) {
      state.locationDisplayId = "";
    },
    setView(state, payload){
      state.view = payload;
    },
    SET_USER_EMAIL(state, payload){
      state.user_email = payload;
    },
    updateDevices: (state, payload) => {
      state.devices = payload;
    },
    updateAlert: (state, payload) => {
      state.alert = payload;
    },
    updateAlertVisibility: (state, payload) => {
      state.alert.shown = payload;
    },
    updateSelectedDevice: (state, payload) => {
      state.selectedDevice = payload;
    },
    updateShowDeviceInfo: (state, payload) => {
      state.showDeviceInfo = payload
    },
    updateShowAddLocation: (state, payload) => {
      state.showAddLocation = payload
    },
    updateShowLocationInfo: (state, payload) => {
      state.showLocationInfo = payload
    },
    updateSelectedLocation: (state, payload) => {
      state.selectedLocation = payload;
    },
    updateShowEditDevice: (state, payload) => {
      state.showEditDevice = payload
    },
    updateShowEditLocation: (state, payload) => {
      state.showEditLocation = payload
    },
    updateShowAddDeviceRunner: (state, payload) => {
      state.showAddDeviceRunner = payload
    },
    updateLang: (state, payload) => {
      state.lang = payload
    },
    updateDevice: (state, payload) => {
      state.devices = [
        ...state.devices.filter(element => element.uuid !== payload.uuid), payload
      ]
    },
    replaceDevice: (state, payload) => {
      state.devices = [
        ...state.devices.filter(element => element.uuid !== payload.uuid), payload.device
      ]
    }
  },
  getters: {
    getMessage(state){
      return state.messageType;
    },
    getLocationDisplayId(state){
      return state.locationDisplayId;
    },
    getDevices(state) {
      return state.devices;
    },
    getLocations(state) {
      return state.locations
    },
    getUserEmail(state){
      return state.user_email;
    },
    getSelectedLocation(state) {
      return state.selectedLocation;
    },
    getSelectedDevice(state) {
      return state.selectedDevice;
    },
    getAlert(state) {
      return state.alert;
    },
    getAlertType(state) {
      return state.alert.type;
    },
    getAlertMessage(state) {
      return state.alert.message;
    },
   
  }
})