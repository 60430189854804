import { render, staticRenderFns } from "./GroupInfo.vue?vue&type=template&id=62917cfa&scoped=true"
import script from "./GroupInfo.vue?vue&type=script&lang=js"
export * from "./GroupInfo.vue?vue&type=script&lang=js"
import style0 from "./GroupInfo.vue?vue&type=style&index=0&id=62917cfa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62917cfa",
  null
  
)

export default component.exports