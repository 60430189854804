import Applications from "../../services/api/Applications";

const state = () => ({
  applications: [],
  possibilitiesModal: false,
  currentApplication: null,
});

const getters = {
  getApplications(state) {
    return state.applications;
  },
  getCurrentApplication(state) {
    return state.currentApplication;
  },
  possibilitiesModal(state) {
    return state.possibilitiesModal
  },
};

const actions = {
  setApplications({ commit }, { page, pageSize, search }) {
    return new Promise((resolve, reject) => {
      Applications.get(page, pageSize, search).then(response => {
        if (page > 1) {
          commit("APPEND_APPLICATIONS", response.data.applications); // Append for pagination
        } else {
          commit("SET_APPLICATIONS", response.data.applications); // Set new for fresh load or specific search
        }
        resolve(response.data.applications);
      }).catch(error => {
        console.error("Error fetching applications:", error);
        reject(error);
      });
    });
  },
  getApplication({ commit }, appId) {
    return new Promise((resolve, reject) => {
      Applications.getApplication(appId).then(response => {
        commit("SET_CURRENT_APPLICATION", response.data);
        resolve(response.data);
      }).catch(error => {
        console.error("Error fetching application details:", error);
        reject(error);
      });
    });
  },
  updateApplication({ commit }, { appId, updateData }) {
    return new Promise((resolve, reject) => {
      Applications.updateApplication(appId, updateData).then(response => {
        commit("UPDATE_APPLICATION", response.data);
        resolve(response.data);
      }).catch(error => {
        console.error("Error updating application:", error);
        reject(error);
      });
    });
  },
  addApplication({ commit }, newApplication) {
    return new Promise((resolve, reject) => {
      Applications.addApplication(newApplication).then(response => {
        commit("ADD_APPLICATION", response.data);
        resolve(response.data);
      }).catch(error => {
        console.error("Error adding new application:", error);
        reject(error);
      });
    });
  },
  togglePossibilitiesModal({commit}) {
    commit('TOGGLE_POSSIBILITIES_MODAL');
  },
};

const mutations = {
  SET_APPLICATIONS(state, applications) {
    state.applications = applications;
  },
  APPEND_APPLICATIONS(state, applications) {
    state.applications = [...state.applications, ...applications]; // Combine old applications with new ones
  },
  SET_CURRENT_APPLICATION(state, application) {
    state.currentApplication = application;
  },
  UPDATE_APPLICATION(state, updatedApplication) {
    const index = state.applications.findIndex(app => app.id === updatedApplication.id);
    if (index !== -1) {
      state.applications.splice(index, 1, updatedApplication);
    }
  },
  ADD_APPLICATION(state, newApplication) {
    state.applications.push(newApplication);
  },
  TOGGLE_POSSIBILITIES_MODAL(state) {
    state.possibilitiesModal = !state.possibilitiesModal;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
