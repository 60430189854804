<template>
  <v-list v-if="selectedItem" class="pt-1 pb-1">
    <v-list-item class="px-0">
      <v-list-item-avatar
          class="ma-0 border-radius-4 mr-4"
          :class="this.isDarkTheme ? 'dark-border' : 'light-border'"
          tile
          size="64"
          :color="getColor(selectedItem['online_status'])"
          v-if="type === 'location' || route === 'locations'"
      > 
        <span v-if="selectedItem.name" class="white--text headline">{{
            avatarName(selectedItem.name).toUpperCase()
          }}</span>
      </v-list-item-avatar>
      <v-list-item-avatar
          class="ma-0 border-radius-4 mr-4"
          tile
          size="64"
          v-else-if="type === 'device'  || type === 'camera'"
      >
        <div
            v-bind:class="[
            selectedItem.connected_status ==='connected' && selectedItem.online === true ? 'online_device': 
            selectedItem.connected_status ==='connected' && selectedItem.online === false ? 'offline_device': 
            selectedItem.connected_status ==='disconnected' || 
            selectedItem.connected_status === 'archived' || 
            selectedItem.connected_status ==='installation_pending' ? 'offline': 'uninstalled_device',
            'item-icon',
            'device-icon',
            this.isDarkTheme ? 'dark-border' : 'light-border'
            ]"
        >
          <v-icon
              size="24"
              v-bind:class="selectedItem.connected_status === 'connected' ? 'connected_icon' : 
              selectedItem.connected_status === 'disconnected' || 
              selectedItem.connected_status === 'archived' ? 'disconnected_icon' :  
              selectedItem.connected_status === 'non_connected' ||
              selectedItem.connected_status === 'installation_pending' 
              ? 'non_connected_icon': null"
          >{{ icons[selectedItem["device_type_name"]] }}
          </v-icon>
        </div>
      </v-list-item-avatar>
      <v-list-item-avatar
          size="64"
          tile
          class="ma-0 border-radius-4 mr-4"
          v-else-if="type === 'group' || (route ==='groups' && selectedItem.name !== 'Add Location Group')"
          :class="[
            selectedItem.active ? 'active_group' : 'unactive_group',
            'item-icon', this.isDarkTheme ? 'dark-border' : 'light-border'
          ]">
        <v-icon size="24" :color="groupIconColor">
          {{ icons.mdiMapMarkerMultiple }}
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title
            class="float-right headline font-weight-bold text-primary"
        >
          <span v-if="type === 'edit'">Edit</span>
          {{ selectedItem.name }}
          <!--Need to keep in mind using v-show vs v-if-->
          <span>
            <v-icon v-if="type === 'device'" 
            size="10" 
            class="text-primary" 
            :color="selectedDevice.online ? 'green' : 'red'">
            {{ $vuetify.icons.values.circle }}
          </v-icon>
        </span>
        </v-list-item-title>
        <v-list-item-subtitle
            v-if="selectedItem.subtitle"
            class="text-secondary">
          {{ selectedItem.subtitle }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-col class="pa-0">
          <v-menu offset-y left v-if="type">
            <template v-slot:activator="{ on }">
              <v-btn v-if="type !== 'edit'" class="mr-2" icon v-on="on" plain>
                <v-icon class="text-primary">{{ $vuetify.icons.values.dotsVertical }}</v-icon>
              </v-btn>
            </template>
            <v-list dense width="224px">
              <v-list-item
                  v-for="(item, i) in showActionItems"
                  :key="i"
                  class="v-list-hover"
                  @click="openModal(item)"
              >
                <v-list-item-icon class="pr-0 mr-4">
                  <v-icon class="text-secondary">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="pr-4 font-weight-regular text-primary">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn @click="closeDialog()" plain icon>
            <v-icon class="text-primary">{{ $vuetify.icons.values.close }}</v-icon>
          </v-btn>
        </v-col>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mdiTelevision, mdiArchive, mdiCctv, mdiContentCopy, mdiDelete, mdiLaptop, mdiPencil, mdiRaspberryPi, mdiDevices, mdiMapMarkerMultiple} from "@mdi/js";

export default {
  name: "FlyoutMenuHead",
  props: ["selectedItem", "type", "route", "channel"],
  data() {
    return {
      route_name: null,
      icons: {
        'Linux Computer': mdiLaptop,
        "Windows Computer": mdiLaptop,
        'camera': mdiCctv,
        'RaspberryPi': mdiRaspberryPi,
        'Unknown': mdiDevices,
        'mdiMapMarkerMultiple': mdiMapMarkerMultiple,
        'Android TV': mdiTelevision
      },
      actionItems: [
        {
          title: "Edit",
          icon: mdiPencil,
        },
        {
          title: "Delete",
          icon: mdiDelete,
        },
        {
          title: "Archive",
          icon: mdiArchive,
        },
        {
          title: "Copy Device URL",
          icon: mdiContentCopy,
          message: "Device URL copied to clipboard",
          action: this.getUrl()
        },
        {
          title: "Unarchive",
          icon: mdiArchive,
        }
      
      ],
      modals: {
        "device-delete": false,
        "device-archive": false,
      },
      data: {
        editDevice: true,
        editLocation: true,
        editGroup: true,
        deleteDevice: true,
        deleteLocation: true, 
        deleteGroup: true     
      },
    };
  },
  computed: {
    ...mapGetters({selectDevice: "devices/getSelectedDevice"}),
    selectedDevice() {
      return this.selectDevice;
    },
    showActionItems() {
      if(this.selectedItem['connected_status'] === 'disconnected'){
        return this.actionItems.filter(item => item.title !== 'Delete' && item.title !== 'Unarchive' && item.title !== 'Copy Device URL');
      }else if(this.selectedItem['connected_status'] === 'connected'){
        return this.actionItems.filter(item => item.title !== 'Unarchive' && item.title !== 'Copy Device URL' && item.title !== 'Delete');
      }else if(this.selectedItem['connected_status'] === 'non_connected'){
        return this.actionItems.filter(item => item.title !== 'Archive' && item.title !== 'Copy Device URL' && item.title !== 'Unarchive');
      } else if (this.selectedItem['connected_status'] === 'archived') {
        return this.actionItems.filter(item => item.title !== 'Archive' && item.title !== 'Copy Device URL' && item.title !== 'Delete');
      } else if(this.type === 'location') {
        return this.actionItems.filter(item => item.title !== 'Copy Device URL' && item.title !== 'Archive' && item.title !== 'Unarchive');
      }else{
        return this.actionItems.filter(item => item.title !== 'Copy Device URL' && item.title !== 'Archive' && item.title !== 'Unarchive');
      }
    },
    isDarkTheme() {
      return this.$vuetify.theme.dark || false;
    },
    groupIconColor() {
      return this.selectedItem.active ? 'white' : 'gray';
    },
  },
  methods: {
    ...mapActions("devices", ["setDeviceFlyout", "deleteDevice", "getDevices"]),
    ...mapActions("locations", ["setLocationFlyout", "deleteLocation"]),
    ...mapActions("groups", ["deleteGroup"]),
    openModal(type) {
      if ((this.route === 'devices' && type.title === 'Edit')  || 
          (this.route === "locations" && type.title === "Edit") || 
          (this.route === "monitoring" && type.title === "Edit") ||
          (this.route === "group" && type.title === "Edit")
        ) {
        let newRoute = this.$route;
        newRoute.params.action = "edit";
        this.$router.push(newRoute);
        // if (this.type === 'device' || this.type === 'camera') {
        //   this.setDeviceFlyout(this.data);
        // } else if (this.type === 'location') {
        //   this.setLocationFlyout(this.data);
        // }
      } else if (this.type === 'device' && type.title === "Delete") {
        this.deleteDevice(this.$props.selectedItem);
        this.$router.push('/devices');
      }else if(this.type === 'device' && type.title === "Archive"){
        this.$store.dispatch('devices/archiveDevice', this.$props.selectedItem);  
        this.$router.push('/devices');
      }else if(this.type === 'device' && type.title === "Unarchive"){
        this.$store.dispatch('devices/unarchiveDevice', this.$props.selectedItem).then(() => {
          this.getDevices();
        });
        this.$router.push('/devices');
      }else if(this.type === 'location' && type.title === "Delete"){
         this.deleteLocation(this.$props.selectedItem);
         this.$router.push('/locations');
      }else if(this.type === 'group' && type.title === "Delete"){
         this.deleteGroup(this.$props.selectedItem.uuid);
         this.$router.push('/groups');
      }
      else if(this.$props.route === 'device_location' && type.title === "Copy device URL" && this.type === 'device'){
         this.getUrl();  
      }
    },
    closeDialog() {
      if(this.$route.name === 'location' && this.$route.params.action === 'view'){
          this.$router.replace(`/locations`)
          }
      if (
         this.$route.name === 'location' 
      || this.$route.name === 'device' 
      || this.$route.name === 'account' 
      || this.$route.name === 'device_location' 
      || this.$route.name === 'monitoring' 
      || this.$route.name === 'activity' 
      || this.route_name === "group"
    ) {
        if (this.$route.params.fromLocation) {
          this.$router.replace(`/devices`)
        }else if(this.route === 'group'){
          this.$router.replace(`/groups`)
          this.$store.commit("groups/setGroup", '');
        }
        else if(this.$route.params.action === 'detectDevices'){
          this.$store.commit('locations/SET_DETECTED_DEVICES', []);
          this.$store.commit("devices/SET_NO_DETECT_DEVICES", false);
        } else {
          if (window.history.length > 2) {
            this.$router.go(-1);
            this.$store.commit('monitoring/SET_SELECTED_DEVICE', "");
          }else if(this.$route.name === 'device_location'){
          this.$router.replace(`/locations/location/view/${this.selectedItem.location_uuid}`)
          } 
          else {
            this.$router.replace(`/${this.$route.name}s`);
          }
        }
        this.$store.commit('devices/SET_SELECTED_DEVICE', "");
        this.$store.commit('devices/SET_ACTIVE_PERIPHERAL', "");
        this.$store.commit('monitoring/SET_SELECTED_DEVICE', "");
      }
      if (this.$route.name === 'accounts_action' || 
          this.$route.name === 'location_action' || 
          this.$route.name === 'device_action' || 
          this.$route.name === 'group_action') {
        if (window.history.length > 0) {
          this.$router.go(-1);
        } 
      }
      this.$store.commit('devices/SET_SELECTED_DEVICE', "");
      this.$store.commit('monitoring/SET_SELECTED_DEVICE', "");
      this.channel.unbind();
      this.channel.unsubscribe();
      
    },
    getUrl(){
      //let url = window.location.origin;
      //let newUrl =`${url}/devices/device/view/${this.$props.selectedItem.uuid}/general`;
      //navigator.clipboard.writeText(newUrl);
    },
    avatarName(string) {
      var matches = string.match(/\b(\w)/g);
      return matches.join("");
    },
    getColor(type) {
      var colors = {
         "online": "#2B85D8",
        "partially_online": "#3AB0C0",
        "offline": "#7A7A7A",
      };
      return colors[type];
    },

  },
  mounted(){
    this.route_name = this.$route.name;
  }
};
</script>

<style scoped>
.device-icon {
  padding: 20px;
  background-color: var(--v-lightGrey-base);
  width: 64px !important;
  height: 64px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px !important;
}

.installed {
  background-color: var(--v-blueishGray-base) !important;

}

.offline {
  background-color: #222222 !important;
}

.connected_icon {
  color: var(--v-general_bckgr-base) !important;
}

.disconnected_icon {
  color: var(--v-secondaryText-base) !important;
  
}
 .non_connected_icon {
    color: var(--v-secondaryText-base) !important;
  }
.online {
  background-color: green !important;
}
.offline-icon {
  background-color: red !important;
}
.border-radius-4 {
  border-radius: 4px !important;
}
.online_device{
    background-color: #2b85d8 !important;
    opacity:1;
    border: 1px solid black;
  }
  .offline_device{
    background-color: #95C2EC !important;
    opacity: 0.8;
    border: 1px solid black;
    
  }
  .uninstalled_device{
    border: 1px solid black;
  }
.dark-border {
  border:1px solid white !important;
}

.light-border {
  border:1px solid black !important;
}
.active_group{
    background-color: #2b85d8 !important;
    opacity: 1;
  }
  .unactive_group{
    background-color: #E0E0E0 !important;
    opacity: 1;
  }
</style>
