<template>
  <div>
    <FlyoutMenuHead :selectedItem="selectedItem" route="groups"/>
    <v-divider/>
    <v-row class="mt-4">
      <v-col md="12" class="d-flex align-center justify-space-between pt-4">
        <span class="subtitle text-secondary">Locations</span>
      </v-col>
    </v-row>
    <v-form
    class="overflow-container overflow-y-auto scroll-right-md-y scroll-right-lg-y"
    v-model="isFormValid" 
    @submit.prevent="addLocation()">
      <v-row>
        <v-col md="8" class="d-flex align-center justify-space-between pb-0">
          <v-text-field
            class="pt-0 mb-0"
              v-model="location.name"
              height="40"
              :maxlength="256"
              :counter="256"
              placeholder="Friendly name for your location"
              :rules="[rules.required]"
              dense
              outlined
          ><template #label>
              <span class="pl-1 pr-1">Group Name</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="overflow-y-auto">
        <v-col md="8" class="d-flex align-center justify-space-between">
          <v-autocomplete
              v-model="location.location_uuids"
              :items="locations"
              placeholder="Enter locations for your location group"
              dense
              outlined
              :append-icon="$vuetify.icons.values.chevronDown"
              @update:search-input="onLocationChange"
              item-text="name"
              item-value="uuid"
              @keydown.enter="onAddTag"
              :search-input.sync="tagToAdd"
              @input="tagToAdd = null"
              chips
              persistent-hint
              deletable-chips
              multiple>
            <template #label>
              <span class="pl-1 pr-1">Location list</span>
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title class="d-flex align-start justify-space-between">
                  <span class="font-weight-regular">{{ data.item.name }}</span>
                  <span class="font-weight-light">{{ data.item['total_number'] || 0 }} devices and locations</span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:no-data >
              <v-list-item-content>
                <v-list-item-title class="d-flex align-start justify-space-between px-4 ">
                  <span class="font-weight-regular caption mb-8">{{ tagToAdd }}</span>
                  <span class="font-weight-light caption">New</span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="8" class="d-flex align-center justify-space-between pb-0">
          <span class="ml-2 subtitle text-secondary">Active</span>
          <v-spacer></v-spacer>
          <v-switch v-model="location.active"></v-switch>
        </v-col>
      </v-row>
      <v-row  class="text-right bottom-navigation-edit py-4 px-2"
        v-bind:style= "[this.$vuetify.theme.dark ? {'background-color': '#1E1E1E'} : {'background-color':'#ffffff'}]"
      ><v-col >
          <v-btn
              width="120"
              class="mr-2 font-weight-regular letter-spacing-normal text-capitalize"
              depressed
              text
              @click="closeForm()"
          >{{ $t("common.cancelBtn") }}
          </v-btn>
          <v-btn
              width="120"
              id="btn-blue-disabled"
              class="font-weight-normal letter-spacing-normal text-capitalize"
              color="primary"
              depressed
              type="submit"
              :disabled="!isFormValid"
              @click="submitForm"
          >{{ $t("assets.devices.addRunner.addBtn") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import FlyoutMenuHead from '../FlyoutMenuHead.vue'
import { mapActions, mapGetters } from 'vuex';
import { mdiMenuDown } from '@mdi/js';
export default {
    name: "AddGroup",
    components:{
        FlyoutMenuHead,
    },
    computed: {
    ...mapGetters({
     locations: "locations/getLocationList",
     getDisplayId: "getLocationDisplayId"
    }),
  },
  mounted(){
    this.setLocations();
  },
  methods: {
    ...mapActions({
      setLocations: "locations/getLocationForLists",
      addGroup: "groups/addGroup"
    }),
    submitForm() {
      if (this.isFormValid) {
        this.addGroup(this.location).then(()=>{
          this.$router.go(-1);
        })
      }
    },
  },
    data: () => ({
    icons: {
        mdiMenuDown: mdiMenuDown
    },
    selectedItem: {
      name: "Add Location Group",
      subtitle: "Create a new Location Group for linking desired locations together.",
    },
    location: {
        name: "",
        location_uuids: [],
        active: true,
      },
      timer: null,
      isFormValid: false,
      location_rules: {},
      location_types: [],
      countries: [],
      rules: {
        required: (value) => !!value || "Required.",
      },
  }),
}
</script>

<style lang="scss" scoped>
.overflow-container {
  overflow-x: hidden;
}
.window-title {
  span {
    font-size: 13px;
  }
}

.add-device-btn {
  width: 120px;
}

.bottom-navigation-edit {
  position: absolute !important;
  bottom: 0 !important;
  left: 0;
  right: 0;
  margin: 0;
  box-shadow: 0 -4px 25px 0 rgba(0, 0, 0, 0.13);
}

::v-deep {
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: var(--v-babyBlueish-base) !important;
    color: white !important;
  }
}
</style>