<template>
  <div>
    <v-row>
      <v-col
          cols="8"
          class="overflow-y-auto scroll-right-md-y scroll-right-lg-y"
      ><v-row class="mt-4">
          <v-col md="12" class="d-flex align-center justify-space-between pt-4">
            <span class="subtitle text-secondary">Basic</span>
          </v-col>
        </v-row>
        <v-form v-model="isFormValid">
          <v-row class="overflow-y-auto">
            <v-col md="12" class="d-flex align-center justify-space-between">
              <v-text-field
                  v-model="$props.value.name"
                  height="40"
                  :placeholder="$props.value.name"
                  dense
                  outlined
              >
                <template #label>
                  <span class="pl-1 pr-1">Device Name</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="overflow-y-auto">
            <v-col md="6" class="d-flex align-center justify-space-between">
                <v-text-field
                  v-model="$props.value.device_category_name"
                  height="40"
                  :placeholder="$props.value.device_category_name"
                  dense
                  outlined
                  disabled
              >
                <template #label>
                  <span class="pl-1 pr-1">Device Category</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="6" class="d-flex align-center justify-space-between">
                <v-text-field
                  v-model="$props.value.device_type_name"
                  height="40"
                  :placeholder="$props.value.device_type_name"
                  dense
                  outlined
                  disabled
              >
                <template #label>
                  <span class="pl-1 pr-1">Device Type</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" class="d-flex align-center justify-space-between">
              <span class="subtitle text-secondary">Location</span>
            </v-col>
          </v-row>
          <v-row class="overflow-y-auto">
            <v-col md="6" class="d-flex align-center justify-space-between">
              <v-text-field
                  v-model="$props.value.location_name"
                  height="40"
                  :placeholder="$props.value.location_name"
                  dense
                  outlined
                  disabled
              >
                <template #label>
                  <span class="pl-1 pr-1">Location</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="overflow-y-auto">
            <v-col md="12" class="d-flex align-center justify-space-between">
                <v-textarea
                    outlined
                    v-model="FormData.description"
                    name="input-7-4"
                    label="Description"
                    value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                    ></v-textarea>
            <template #label>
                <span class="pl-1 pr-1">Aditional</span>
            </template>
            </v-col>
          </v-row>
          <v-autocomplete
          v-model="FormData.tags"
          :items="tags.tags" 
          :item-value="tag => tag.uuid" 
          :item-text="tag => tag.name"
          dense
          chips
          outlined
          small-chips
          deletable-chips
          label="Tags"
          multiple
        >
        <template #label>
            <span class="pl-1 pr-1">Tags</span>
          </template>
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title class="d-flex align-start justify-space-between">
                <span class="font-weight-regular">{{ data.item.name }}</span>
                <span class="font-weight-light">{{ data.item['total_number'] || 0 }} devices and locations</span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:no-data>
            <v-list-item-content>
              <v-list-item-title class="d-flex align-start justify-space-between px-4">
                <span class="font-weight-regular caption">{{ tagToAdd }}</span>
                <span class="font-weight-light caption">New</span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
        </v-form>
      </v-col>
      <v-divider class="d-none d-md-flex" vertical/>
      <v-col cols="4" class="ml-n1 pl-5 pt-4 content-height overflow-y-auto">
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: {
    value: {
      type: Object,
      required: true,
    },
    tags: []

},
data(){
    return {
        isFormValid: false,
        FormData: {
            description: "",
            tags: []
        },
        }
},
computed:{
  ...mapGetters({
    detectDescription: "devices/getDescriptionDetect",
    detectTags: "devices/getTagsDetect"
  })
},
watch: {
    // Watch changes in specific form data for efficiency
    'FormData.description'(newValue) {
      this.$store.commit("devices/SET_DETECTION_DESCRIPTION", newValue)
    },
    'FormData.tags'(newValue) {
      this.$store.commit("devices/SET_DETECTION_TAGS", newValue)
    },
    detectDescription(newValue){
      this.FormData.description = newValue;
    },
    detectTags(newValue){
      this.FormData.detectTags = newValue;
    }
  },
methods:{
  removeTag(tag) {
      // Remove tag from selectedTags array
      this.selectedTags.splice(this.selectedTags.indexOf(tag), 1);
    },
    resetFormData() {
      // Clear or update form data as needed:
      this.FormData.description = "";
      this.FormData.tags = [];
      // Any other data to reset
      this.$emit("resetFormData"); // Emit the event
    },
}
}
</script>

<style>

</style>