var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.selectedItem)?_c('v-list',{staticClass:"pt-1 pb-1"},[_c('v-list-item',{staticClass:"px-0"},[(_vm.type === 'location' || _vm.route === 'locations')?_c('v-list-item-avatar',{staticClass:"ma-0 border-radius-4 mr-4",class:this.isDarkTheme ? 'dark-border' : 'light-border',attrs:{"tile":"","size":"64","color":_vm.getColor(_vm.selectedItem['online_status'])}},[(_vm.selectedItem.name)?_c('span',{staticClass:"white--text headline"},[_vm._v(_vm._s(_vm.avatarName(_vm.selectedItem.name).toUpperCase()))]):_vm._e()]):(_vm.type === 'device'  || _vm.type === 'camera')?_c('v-list-item-avatar',{staticClass:"ma-0 border-radius-4 mr-4",attrs:{"tile":"","size":"64"}},[_c('div',{class:[
          _vm.selectedItem.connected_status ==='connected' && _vm.selectedItem.online === true ? 'online_device': 
          _vm.selectedItem.connected_status ==='connected' && _vm.selectedItem.online === false ? 'offline_device': 
          _vm.selectedItem.connected_status ==='disconnected' || 
          _vm.selectedItem.connected_status === 'archived' || 
          _vm.selectedItem.connected_status ==='installation_pending' ? 'offline': 'uninstalled_device',
          'item-icon',
          'device-icon',
          this.isDarkTheme ? 'dark-border' : 'light-border'
          ]},[_c('v-icon',{class:_vm.selectedItem.connected_status === 'connected' ? 'connected_icon' : 
            _vm.selectedItem.connected_status === 'disconnected' || 
            _vm.selectedItem.connected_status === 'archived' ? 'disconnected_icon' :  
            _vm.selectedItem.connected_status === 'non_connected' ||
            _vm.selectedItem.connected_status === 'installation_pending' 
            ? 'non_connected_icon': null,attrs:{"size":"24"}},[_vm._v(_vm._s(_vm.icons[_vm.selectedItem["device_type_name"]])+" ")])],1)]):(_vm.type === 'group' || (_vm.route ==='groups' && _vm.selectedItem.name !== 'Add Location Group'))?_c('v-list-item-avatar',{staticClass:"ma-0 border-radius-4 mr-4",class:[
          _vm.selectedItem.active ? 'active_group' : 'unactive_group',
          'item-icon', this.isDarkTheme ? 'dark-border' : 'light-border'
        ],attrs:{"size":"64","tile":""}},[_c('v-icon',{attrs:{"size":"24","color":_vm.groupIconColor}},[_vm._v(" "+_vm._s(_vm.icons.mdiMapMarkerMultiple)+" ")])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"float-right headline font-weight-bold text-primary"},[(_vm.type === 'edit')?_c('span',[_vm._v("Edit")]):_vm._e(),_vm._v(" "+_vm._s(_vm.selectedItem.name)+" "),_c('span',[(_vm.type === 'device')?_c('v-icon',{staticClass:"text-primary",attrs:{"size":"10","color":_vm.selectedDevice.online ? 'green' : 'red'}},[_vm._v(" "+_vm._s(_vm.$vuetify.icons.values.circle)+" ")]):_vm._e()],1)]),(_vm.selectedItem.subtitle)?_c('v-list-item-subtitle',{staticClass:"text-secondary"},[_vm._v(" "+_vm._s(_vm.selectedItem.subtitle)+" ")]):_vm._e()],1),_c('v-list-item-action',[_c('v-col',{staticClass:"pa-0"},[(_vm.type)?_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.type !== 'edit')?_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","plain":""}},on),[_c('v-icon',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.$vuetify.icons.values.dotsVertical))])],1):_vm._e()]}}],null,false,3648615771)},[_c('v-list',{attrs:{"dense":"","width":"224px"}},_vm._l((_vm.showActionItems),function(item,i){return _c('v-list-item',{key:i,staticClass:"v-list-hover",on:{"click":function($event){return _vm.openModal(item)}}},[_c('v-list-item-icon',{staticClass:"pr-0 mr-4"},[_c('v-icon',{staticClass:"text-secondary"},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',{staticClass:"pr-4 font-weight-regular text-primary"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),1)],1):_vm._e(),_c('v-btn',{attrs:{"plain":"","icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.$vuetify.icons.values.close))])],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }