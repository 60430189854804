import axiosInstance from "@/services/api/instance";
import store from "@/store";

export default {
  getRoles() {
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/role/items/`)
  },
  inviteUsers(data) {
    return axiosInstance.post(`/space/${store.getters["global/getSpace"]}/account/add/`, {
      "user_accounts": data
    });
  },
  getUser(email) {
    return axiosInstance.post(`/account/login/`, {
      email: email
    });
  },
  getAccounts(data) {
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/account/items/?${data}`)
  },
  search(query) {
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/account/items/?search=${query}`)
  },
  logout(account_uuid) {
    return axiosInstance.post(`/space/${store.getters["global/getSpace"]}/account/logout/`, { "account_uuid": account_uuid })
  },
  loginSpaceUser(data) {
    return axiosInstance.post(`/space/${store.getters["global/getSpace"]}/account/login/`, { account_uuid: data })
  },
  exportAccounts(value){
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/account/items/export/?${value}`)
  }
}
