<template>
  <div>
    <speed-dial
        :type="currentTab"
        :items="fab.accounts"
    >
    </speed-dial>
    <v-tabs height="61px" class="tab-accounts">
           <span class="NSoft-Assets-Devices mt-5 ml-6">
            <span class="text-uppercase">
              {{ space_name }}
            </span>
            <span class="capitalize Text-Style-4">
            / Accounts
            </span>
        </span>
      </v-tabs>
    <router-view />
  </div>
</template>
<script>
import SpeedDial from "../components/SpeedDial";
import {mdiSend} from "@mdi/js";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      gatSpaceName: 'global/getSpace',
      "space_name":"accounts/spaceName"
    }),
    currentTab() {
      return this.$route.name;
    },


  },
  name: "Assets",
  components: {
    "speed-dial": SpeedDial,
  },
  data: () => ({
    tab: null,
    fab: {
      accounts: [
        {
          tooltip: "Invite Users",
          icon: mdiSend,
          action: "inviteUser",
        },
      ],
    },
  }),
};
</script>
<style lang="scss" scoped>
.tab-accounts{
  border-bottom: 1px solid var(--v-borderColor-base);
  width: 110%;
  margin-left: -12px;
}
.device-icon {
  padding: 20px;
  background-color: var(--v-lightGrey-base);
  border-radius: 4px;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fab-button {
  margin-right: 64px;
}

.location-card {
  transition: all 0.1s ease !important;
}

.location-card:hover {
  background-color: var(-v--greyAlert-base, 0.3) !important;
}

.location-info {
  margin-left: 16px;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column !important;
}

.v-card__title {
  display: flex;
  flex-direction: row;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 8px;
}

.selected-tab {
  color: var(--v-textColor-base);
}

.NSoft-Assets-Devices {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: var(--v-secondaryText-base);
}

.NSoft-Assets-Devices .Text-Style-4 {
  color: var(--v-textColor-base) !important;
}

::v-deep {
  .v-tab {
    letter-spacing: normal;
  }
}
</style>
