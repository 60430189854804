<template>
  <div class="fab-bottom">
    <v-speed-dial
        bottom
        direction="top"
        v-model="fab"
        open-on-hover
        transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn
            color="primary"
            dark
            fab>
          <v-icon v-if="fab">{{ $vuetify.icons.values.close }}</v-icon>
          <v-icon v-else>{{ $vuetify.icons.values.plus }}</v-icon>
        </v-btn>
      </template>
      <v-tooltip
          left
          color="#232c33"
          v-for="(item, i) in items"
          v-bind:key="i">
        <template v-slot:activator="{ on }">
          <v-btn
              fab
              v-on="on"
              dark
              depressed
              class="mb-0"
              color="primary"
              :to="`${type}/${item.action}`"
          >
            <v-icon>{{ item.icon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ item.tooltip }}</span>
      </v-tooltip>

    </v-speed-dial>
  </div>
</template>

<script>

export default {
  props: ['items', 'type'],
  data: () => ({
    fab: null,
  }),
};
</script>

<style
    lang="scss"
    scoped>
.fab-bottom {
  position: fixed;
  margin-right: calc(64px + 16px);
  bottom: 0;
  right: 0;
  z-index: 5;
}
</style>
