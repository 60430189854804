<template>
  <div>
    <div v-if="type === 'devices'">
      <v-row>
        <v-col class="d-inline">
          <v-icon dense color="blue darken-2">
            {{ icons.mdiAssistant }}
          </v-icon>
          <span class="subtitle-2 ml-2 mr-16">Locations</span>
          <span class="ml-14 grey--text lighten-5--text">{{ this.countNumberLocations }}</span>
          <div class="scrollable-container">
            <v-checkbox 
              :ripple="false"
              v-model="checkLocations" 
              dense hide-details class="mt-2 pb-1"
              v-for="(item, i) in  locationFilledGetter"
              :value="item.uuid"
              :key="i"
              >
              <span slot="label">{{ item.name }}</span>
            </v-checkbox>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mt-2"/>
      <v-row class="pt-4 ml-0">
        <v-icon dense color="blue darken-2">
          {{ icons.mdiAssistant }}
        </v-icon>
        <span class="subtitle-2 pt-1 pl-2">Device Categories & Types</span>
        <span class="pl-3 grey--text lighten-5--text">{{ this.countNumberCategories }}</span>
        <v-col class="pl-0">
          <template v-for="(category, i) in filterDevices.deviceCategoryTypes">
            <v-row class="pa-0 ma-0 justify-space-between align-center" v-if="category.subCategories.length > 0" :key="i"> 
              <v-checkbox
                :ripple="false"
                :on-icon="category.icon" 
                class="custom-checkbox mb-0 mr-4"
                dense
                @click="selectAllCategoryBoxes(category)"
                v-model="category.checked"
                :label="category.name"
              ></v-checkbox>
              <v-btn
                elevation="0"
                :ripple="false" 
                id="no-background-hover"
                icon
                @click="toggleCategoryExpanded(category)"
              ><v-icon>{{ category.expanded ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
              </v-btn>
            </v-row>
            <template v-if="category.expanded">
              <v-checkbox
                :ripple="false"
                class="custom-checkbox pl-2 mb-2"
                dense
                v-for="(subCategory, i) in category.subCategories"
                :key="i+1"
                v-model="subCategory.checked"
                @change="onChildCheckboxChange(category, subCategory)"
                :label="subCategory.name"
              ></v-checkbox>
            </template>
          </template>
        </v-col>
      </v-row>
      <v-divider class="mt-2"/>
      <v-row class="pt-4">
        <v-col class="d-inline">
          <v-icon dense color="blue darken-2">
            {{ icons.mdiAssistant }}
          </v-icon>
          <span class="subtitle-2 ml-2 mr-1">Connected Status</span>
          <span class="ml-16 grey--text lighten-5--text">{{ this.countNumberConected }}</span>
          <v-checkbox 
              :ripple="false"
              :class="isDarkTheme?{'highlight-col-dark': isConnectedChecked(item.value)}:{ 'highlight-col': isConnectedChecked(item.value)}"
              v-model="checkConnectedStatus" 
              dense hide-details class="mt-2"
              v-for="(item, i) in filterDevices.connectionStatus" :value="item.value"
              :key="i">
                <span slot="label">{{ item.text }}</span>
            </v-checkbox>
        </v-col>
      </v-row>
      <v-divider class="mt-2"/>
      <v-row class="pt-4">
        <v-col class="d-inline">
          <v-icon dense color="blue darken-2">
            {{ icons.mdiAssistant }}
          </v-icon>
          <span class="subtitle-2 ml-2 mr-6">Activity status</span>
          <span class="ml-16 grey--text lighten-5--text">{{ this.countNumberOnline }}</span>
          <v-checkbox 
          :ripple="false"
          :class="isDarkTheme?{'highlight-col-dark': isActivityChecked(item.value)}:{'highlight-col': isActivityChecked(item.value)}"
          v-model="checkActivityStatus" 
          dense hide-details class="mt-2"
          v-for="(item, i) in filterDevices.activityStatus" :value="item.value"
          :key="i">
            <span slot="label">{{ item.text }}</span>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-divider class="mt-2"/>
      <v-row class="pt-4">
        <v-col class="d-inline">
          <v-icon dense color="blue darken-2">
            {{ icons.mdiAssistant }}
          </v-icon>
          <span class="subtitle-2 ml-2 mr-7">Device Status</span>
          <span  class="ml-16 grey--text lighten-5--text">{{ this.countNumberEnabled }}</span>
          <v-checkbox 
          :ripple="false"
          :class="isDarkTheme?{'highlight-col-dark': isStatusDeviceChecked(item.value)}:{'highlight-col': isStatusDeviceChecked(item.value)}"
          v-model="checkDeviceStatus" 
          dense hide-details class="mt-2"
          v-for="(item, i) in filterDevices.deviceStatus" :value="item.value"
          :key="i">
            <span slot="label">{{ item.text }}</span>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-divider class="mt-2"/>
      <v-row class="pt-4">
        <v-col class="d-inline pa-2">
          <v-icon dense color="blue darken-2">
            {{ icons.mdiAssistant }}
          </v-icon>
          <span class="subtitle-2 ml-2 mr-16 pr-6">Tags</span>
          <span  class="ml-16 grey--text lighten-5--text">{{ this.countNubnerTags }}</span><br>
          <v-col class="pl-0">
            <label v-for="(item, i) in filterDevices.tags.tags" :key="i" class="checkbox-button" >
              <input type="checkbox" v-model="checkDeviceTags" :value="item.uuid"/>
              <span >{{ item.name }}</span>
            </label>
          </v-col>
        </v-col>
      </v-row>
      <div class="floating-footer" v-bind:style= "[this.$vuetify.theme.dark ? {'background-color': '#1E1E1E'} : {'background-color':'#ffffff'}]">
        <v-row  class="mt-1">
          <v-col cols="8"><span class="alignleft">Total Device Results</span> </v-col>
          <v-col cols="2" class="pa-0"><span class="alignright pt-3 px-0">{{ getNumberOfTotalDevices }} | {{ numberOfDevices }}</span></v-col>
          <v-tooltip text="Tooltip" top >Clear Filters
            <template v-slot:activator="{ on, attrs }">
          <v-col cols="2" class="alignright pb-4">
              <v-btn depressed :ripple="false" class="pb-4 no-active" v-bind="attrs" v-on="on" icon @click="clearAllFilters()"><v-icon>{{ $vuetify.icons.values.dotsVertical  }}</v-icon></v-btn>
          </v-col>
          </template>
          </v-tooltip>
        </v-row>
      </div>
    </div>
    <div v-if="type === 'locations'">
      <v-row>
        <v-col class="d-inline">
          <v-icon dense color="blue darken-2">
            {{ icons.mdiAssistant }}
          </v-icon>
            <span class="subtitle-2 ml-2 mr-16">Location Type</span>
            <span class="pl-3 grey--text lighten-5--text ml-4">{{ getLocationTypeNumber }}</span>
          <v-checkbox 
          :ripple="false"
          :class="isDarkTheme?{'highlight-col-dark': isTypeChecked(item.uuid)}:{'highlight-col': isTypeChecked(item.uuid)}"
          :value="item.uuid" 
          v-model="checkLocationType" 
          dense hide-details class="mt-2"
          v-for="(item, i) in filterLocations.locationType"
          :key="i">
            <span slot="label">{{ item.name }}</span>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-divider class="mt-2"/>
      <v-row class="pt-4">
        <v-col class="d-inline">
          <v-icon dense color="blue darken-2">
            {{ icons.mdiAssistant }}
          </v-icon>
          <span class="subtitle-2 ml-2 mr-16">Country</span>
          <span class="pl-3 ml-14 grey--text lighten-5--text">{{ countNumberCountries }}</span>
          <v-checkbox 
          :ripple="false"
          :class="isDarkTheme?{'highlight-col-dark': isCountryChecked(item.uuid)}:{'highlight-col': isCountryChecked(item.uuid)}"
          :value="item.uuid" 
          v-model="checkCountry" 
          dense hide-details class="mt-2" 
          v-for="(item, i) in filterLocations.country"
          :key="i">
          <span slot="label">{{ item.name }}</span>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-divider class="mt-2"/>
      <v-row class="pt-4">
        <v-col class="d-inline">
          <v-icon dense color="blue darken-2">
            {{ icons.mdiAssistant }}
          </v-icon>
            <span class="subtitle-2 ml-2 mr-16">Tags</span>
            <span  class="ml-16 pl-6 grey--text lighten-5--text">{{ countNumberLocationTags }}</span><br>
          <v-col class="pl-0">
            <label v-for="(item, i) in filterLocations.tags.tags" :key="i" class="checkbox-button" >
              <input type="checkbox" v-model="checkLocationTags" :value="item.uuid"/>
              <span >{{ item.name }}</span>
            </label>
          </v-col>
        </v-col>
      </v-row>
      <div class="floating-footer" v-bind:style= "[this.$vuetify.theme.dark ? {'background-color': '#1E1E1E'} : {'background-color':'#ffffff'}]">
        <v-row class="mt-1" >
          <v-col cols="8"><span class="alignleft">Total Location Results</span> </v-col>
          <v-col cols="2" class="pa-0"><span class="alignright pt-3 px-0">{{ locationGetter.length }} | {{ totalLocations }}</span></v-col>
          <v-tooltip text="Tooltip" top >Clear Filters
            <template v-slot:activator="{ on, attrs }">
          <v-col v-on="on" v-bind="attrs" cols="2" class="alignright pb-4">
              <v-btn plain class="pb-4"  icon @click="clearAllFilterLocation()"><v-icon>{{ $vuetify.icons.values.dotsVertical  }}</v-icon></v-btn>
          </v-col>
          </template>
          </v-tooltip>
        </v-row>
      </div>
    </div>
    <div v-if="type === 'accounts'">
      <v-row>
        <v-col class="d-inline">
          <v-icon dense color="blue darken-2">
            {{ icons.mdiAssistant }}
          </v-icon>
          <span class="subtitle-2 ml-2">Account Status</span>
          <span class="pl-3 grey--text lighten-5--text ml-16">{{ countNumberAccountStatus }}</span>
          <v-checkbox 
          :ripple="false"
          :class="isDarkTheme?{'highlight-col-dark': isStatusChecked(item.value) }:{'highlight-col': isStatusChecked(item.value) }"
          :value="item.value" 
          v-model="checkAccountStatus" 
          dense hide-details class="mt-2" 
          v-for="(item, i) in filterAccounts.accountStatus"
          :key="i">
          <span slot="label">{{ item.text }}</span>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-divider class="mt-2"/>
      <v-row class="pt-4">
        <v-col class="d-inline">
          <v-icon dense color="blue darken-2">
            {{ icons.mdiAssistant }}
          </v-icon>
          <span class="subtitle-2 ml-2 mr-4">Role</span>
          <span class="grey--text lighten-5--text pl-16 ml-16">{{ countNumberRole }}</span>
          <v-checkbox 
          :ripple="false"
          :class="isDarkTheme?{'highlight-col-dark': isRoleChecked(item.value) }:{'highlight-col': isRoleChecked(item.value)}"
          :value="item.value" 
          v-model="checkRole" 
          dense hide-details class="mt-2" 
          v-for="(item, i) in filterAccounts.role"
          :key="i">
          <span slot="label">{{ item.text }}</span>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-divider class="mt-2"/>
      <v-row class="pt-4">
        <v-col class="d-inline">
          <v-icon dense color="blue darken-2">
            {{ icons.mdiAssistant }}
          </v-icon>
            <span class="subtitle-2 ml-2 mr-4">Online</span>
            <span class="pl-3 grey--text lighten-5--text ml-16 pl-12">{{ countNumberOnlineAccount }}</span>
          <v-checkbox 
          :ripple="false"
          :value="item.value" 
          :class="isDarkTheme ? {'highlight-col-dark': isOnlineChecked(item.value)}:{'highlight-col': isOnlineChecked(item.value)}"
          v-model="checkOnline" 
          dense hide-details class="mt-2" 
          v-for="(item, i) in filterAccounts.online"
          :key="i">
          <span slot="label">{{ item.text }}</span>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-divider class="mt-2"/>
      <v-row class="pt-4">
        <v-col class="d-inline">
          <v-icon dense color="blue darken-2">
            {{ icons.mdiAssistant }}
          </v-icon>
            <span class="subtitle-2 ml-2 mr-6">Tags</span>
            <span class="pl-3 grey--text lighten-5--text ml-16 pl-12">{{ countAccountTags }}</span>
          <v-col class="pl-0">
            <label v-for="(item, i) in filterAccounts.tags" :key="i" class="checkbox-button" >
              <input type="checkbox" v-model="checkAccountTags" :value="item.uuid"/>
              <span >{{ item.name }}</span>
            </label>
          </v-col>
        </v-col>
      </v-row>
      <div class="floating-footer" v-bind:style= "[this.$vuetify.theme.dark ? {'background-color': '#1E1E1E'} : {'background-color':'#ffffff'}]">
        <v-row class="mt-1 mr-1">
          <v-col cols="8"><span class="alignleft">Total Account Results</span> </v-col>
          <v-col cols="2" class="pa-0"><span class="alignright pt-3 px-0">{{ currentAccountsGetter }} | {{ totalAccountsGetter }}</span></v-col>
          <v-tooltip text="Tooltip" top >Clear Filters
            <template v-slot:activator="{ on, attrs }">
          <v-col cols="2" class="alignright pb-4 pl-6">
              <v-btn class="pb-4" v-bind="attrs" v-on="on" icon @click="clearAllFilters()"><v-icon>{{ $vuetify.icons.values.dotsVertical  }}</v-icon></v-btn>
          </v-col>
          </template>
          </v-tooltip>
        </v-row>
      </div>
    </div>
    <div v-if="type === 'monitoring'">
      <v-row>
        <v-col class="d-inline">
          <v-icon dense color="blue darken-2">
            {{ icons.mdiAssistant }}
          </v-icon>
          <span  class="subtitle-2 ml-2 mr-14 pr-4">Locations</span>
          <span class="ml-16 grey--text lighten-5--text">{{ this.countNumberLocations }}</span>
          <div class="scrollable-container">
          <v-checkbox 
          :ripple="false"
          :class="isDarkTheme?{'highlight-col-dark': isMonitorChecked(item.location_uuid)}:{'highlight-col': isMonitorChecked(item.location_uuid)}"
          dense hide-details class="mt-2 pb-1"
          v-for="(item, i) in  locationFilledGetter"
           :value="item.uuid"
          v-model="filterMonitoring.checkLocations"
          :key="i">
            <span slot="label">{{ item.name }}</span>
          </v-checkbox>
        </div>
        </v-col>
      </v-row>
      <v-divider class="mt-2"/>
      <v-row class="pt-4 ml-0">
          <v-icon dense color="blue darken-2">
            {{ icons.mdiAssistant }}
          </v-icon>
          <span class="subtitle-2 pt-1 pl-2 mr-4">Device Categories & Types</span>
          <span class="pl-3 grey--text lighten-5--text">{{ this.countNumberCategories }}</span>
          <v-col class="pl-0">
            <template v-for="(category, i) in filterPerformance.deviceCategoryTypes">
              <v-row class="pa-0 ma-0" v-if="category.subCategories.length > 0" :key="i"> 
                <v-checkbox
                :ripple="false"
                :on-icon="icons.mdiMinus"
                class="custom-checkbox mb-0 mr-4"
                :key="i"
                dense
                v-model="category.checked"
                :label="category.name"
            ></v-checkbox>
            <v-btn
            class="ml-14 pl-15"
            elevation="0"
            :ripple="false" 
            id="no-background-hover"
              v-model="category.checked"
              icon
              @click="category.checked = !category.checked"
            ><v-icon>{{ category.checked ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
            </v-btn>
          </v-row>
            <template v-if="category.checked">
              <v-checkbox
              class="custom-checkbox pl-2 mb-2"
                :ripple="false"
                dense
                :class="isDarkTheme?{'highlight-col-dark': isMonitorCategoryChecked(subCategory.value)}:{'highlight-col': isMonitorCategoryChecked(subCategory.value)}"
                v-for="(subCategory, i) in category.subCategories"
                :value="subCategory.value"
                :key="i"
                v-model="filterMonitoring.checkDeviceCategory"
                :label="subCategory.name"
              ></v-checkbox>
            </template>
          </template>
          </v-col>
      </v-row>
      <v-divider class="mt-2"/>
      <v-row class="pt-4">
        <v-col class="d-inline">
          <v-icon dense color="blue darken-2">
            {{ icons.mdiAssistant }}
          </v-icon>
          <span class="subtitle-2 ml-2 pr-5 mr-16">Domain</span>
          <v-radio-group v-model="filterMonitoring.checkDomain">
            <v-radio
            :class="isDarkTheme?{'highlight-col-dark': isDomainChecked(item.value)}:{'highlight-col': isDomainChecked(item.value)}"
            dense
            hide-details
            class="mt-2"
            v-for="(item, i) in filterPerformance.domain"
            :value="item.value"
            @change="updateDomain(item.value)"
            :key="i"
          >
            <span slot="label">{{ item.text }}</span>
          </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-divider class="mt-2"/>
      <v-row class="pt-4">
        <v-col class="d-inline">
          <v-icon dense color="blue darken-2">
            {{ icons.mdiAssistant }}
          </v-icon>
          <span class="subtitle-2 ml-2 pr-2 mr-16">Condition</span>
          <span class="ml-16 grey--text lighten-5--text">{{ this.countNumberOnline }}</span>
          <v-checkbox
          :ripple="false"
          :class="isDarkTheme?{'highlight-col-dark': isConditionChecked(item.value)}:{'highlight-col': isConditionChecked(item.value)}"
          dense hide-details class="mt-2"
          v-for="(item, i) in filterPerformance.condition" :value="item.value"
          v-model="filterMonitoring.checkCondition"
          :key="i">
            <span slot="label">{{ item.text }}</span>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-divider class="mt-2"/>
      <div v-if="footerAppear" class="floating-footer" v-bind:style="[this.$vuetify.theme.dark ? {'background-color': '#1E1E1E'} : {'background-color':'#ffffff'}]">
            <v-row class="mt-2">
              <v-col cols="6" class="pa-0">
                <v-btn class="mx-2" width="90%" small elevation="0" color="gray" @click="cancelFilters()">Cancel</v-btn>
              </v-col>
              <v-col cols="6" class="pa-0">
                <v-btn depressed :ripple="false" class="mx-2" width="90%" small elevation="0" color="primary" @click="applyFilters()">Apply Filters</v-btn>
              </v-col>
            </v-row>
          </div>
      <div v-else class="floating-footer" v-bind:style= "[this.$vuetify.theme.dark ? {'background-color': '#1E1E1E'} : {'background-color':'#ffffff'}]">
        <v-row  class="mt-1">
          <v-col cols="8"><span class="alignleft">Total Device Results</span> </v-col>
          <v-col cols="2" class="pa-0"><span class="alignright pt-3 px-0"> {{ getTotalDevicesMonitoring }} | {{ getUploadedMonitorNumber }}</span></v-col>
          <v-tooltip text="Tooltip" top >Clear Filters
            <template v-slot:activator="{ on, attrs }">
          <v-col cols="2" class="alignright pb-4">
              <v-btn class="pb-4" v-bind="attrs" v-on="on" icon @click="clearAllFilters()"><v-icon>{{ $vuetify.icons.values.dotsVertical  }}</v-icon></v-btn>
          </v-col>
          </template>
          </v-tooltip>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mdiAssistant, mdiDotsVertical, mdiChevronDown, mdiChevronUp, mdiMinusBox  } from '@mdi/js';

export default {
  name: 'FilterPanel.vue',
  props: ['type', 'filterDevices', 'filterLocations','location', 'filterAccounts', 'filterPerformance'],
  data: function () {
    return{
      buttonData: [],
      checked: false,
      icons: {
        'mdiAssistant': mdiAssistant,
        'mdiDotsVertical': mdiDotsVertical,
        'mdiChevronDown': mdiChevronDown,
        'mdiChevronUp': mdiChevronUp,
        'mdiMinus': mdiMinusBox
      },
        firstLoad: true,
        //filterDevices
        checkLocations: [],
        checkDeviceCategory: [],
        checkConnectedStatus: [],
        checkActivityStatus: [],
        checkDeviceStatus: [],         
        checkDeviceTags: [],
        //filterLocations
        checkLocationType: [],
        checkCountry: [],
        checkLocationTags: [],
        //filterAccounts
        checkAccountStatus: [],
        checkRole: [],
        checkOnline: [],
        checkAccountTags: [],
        //Filter for monitoring
        filterMonitoring:{
          checkLocations: [],
          checkDeviceCategory:[],
          checkDomain: "top",
          checkCondition: []
        },
        footerAppear: false
    } 
  },
  watch: {
    checkLocations(newValue) {
      this.$store.dispatch("devices/setCheckLocations", newValue);
      this.$store.commit("devices/SET_PAGE_NUMBER", 1);
    },
    checkDeviceCategory(newValue) {
      this.$store.dispatch("devices/setCheckDeviceCategory", newValue);
      this.$store.commit("devices/SET_PAGE_NUMBER", 1);
    },
    checkConnectedStatus(newValue) {
      this.$store.dispatch("devices/setCheckConnectedStatus", newValue);
      this.$store.commit("devices/SET_PAGE_NUMBER", 1);
    },
    checkActivityStatus(newValue) {
      this.$store.dispatch("devices/setCheckActivityStatus", newValue);
      this.$store.commit("devices/SET_PAGE_NUMBER", 1);
    },
    checkDeviceStatus(newValue) {
      this.$store.dispatch("devices/setCheckDeviceStatus", newValue);
      this.$store.commit("devices/SET_PAGE_NUMBER", 1);
    },
    checkDeviceTags(newValue) {
      this.$store.dispatch("devices/setCheckDeviceTags", newValue);
      this.$store.commit("devices/SET_PAGE_NUMBER", 1);
    },
    //locations watchers
    checkLocationType(newValue) {
      this.$store.dispatch("locations/setCheckLocationType", newValue);
      this.$store.commit("locations/SET_PAGE_NUMBER", 1)
    },
    checkCountry(newValue) {
      this.$store.dispatch("locations/setCheckCountry", newValue);
      this.$store.commit("locations/SET_PAGE_NUMBER", 1)
    },
    checkLocationTags(newValue) {
      this.$store.dispatch("locations/setCheckTags", newValue);
      this.$store.commit("locations/SET_PAGE_NUMBER", 1)
    },
    //watches for domain value 
  filterMonitoring:{
    handler(){
      this.footerAppear = true;
    },
    deep: true
  },  
  //accounts watchers
  checkAccountStatus(newValue){
    this.$store.dispatch("accounts/setCheckAccountStatus", newValue);
      this.$store.commit("accounts/SET_PAGE", 1)
  },
  checkRole(newValue){
    this.$store.dispatch("accounts/setCheckRole", newValue);
      this.$store.commit("accounts/SET_PAGE", 1)
  },
  checkOnline(newValue){
    this.$store.dispatch("accounts/setCheckOnline", newValue);
    this.$store.commit("accounts/SET_PAGE", 1)
  },
  checkAccountTags(newValue){
    this.$store.dispatch("accounts/setCheckAccountTags", newValue);
      this.$store.commit("accounts/SET_PAGE", 1)
  }
  },

computed: {
...mapGetters({
    locationGetter: 'locations/getLocations',
    devicesGetter: 'devices/getDevices',
    sortGetter: 'devices/getSort',
    getCheckLocations: 'devices/getCheckLocations',
    getCheckDeviceCategory: 'devices/getCheckDeviceCategory',
    getCheckConnectedStatus: 'devices/getCheckConnectedStatus',
    getCheckActivityStatus: 'devices/getCheckActivityStatus',
    getCheckDeviceStatus: 'devices/getCheckDeviceStatus',
    getCheckDeviceTags: 'devices/getCheckDeviceTags',
    getTotalDevices: 'devices/getTotaldevices',
    totalLocations: "locations/getTotalLocations",
    locationFilledGetter: "devices/getFilledLocations",
    totalAccountsGetter: "accounts/getTotalAccountsNumber",
    currentAccountsGetter: "accounts/getNumberOfAccountsPerPage",
    getTotalDevicesMonitoring:"monitoring/getTotalMonitorDevicesGetter",
    getTotalDevicesMonitor:"monitoring/getMonitorDevicesGetter"
  }),
getUploadedMonitorNumber(){
  return this.getTotalDevicesMonitor.length;
},
//computed for changing the checkbox row background color
//devices
isChecked() {
      const checkedSet = new Set(this.checkLocations);
      return (value) => checkedSet.has(value);
 },
isCategoryChecked() {
      const checkedSet = new Set(this.checkDeviceCategory);
      return (value) => checkedSet.has(value);
 },
isActivityChecked () {
      const checkedSet = new Set(this.checkActivityStatus);
      return (value) => checkedSet.has(value);
 },
isStatusDeviceChecked() {
      const checkedSet = new Set(this.checDeviceStatus);
      return (value) => checkedSet.has(value);
 },
isConnectedChecked() {
      const checkedSet = new Set(this.checkConnectedStatus);
      return (value) => checkedSet.has(value);
 },
//locations
isTypeChecked() {
      const checkedSet = new Set(this.checkLocationType);
      return (value) => checkedSet.has(value);
 },
isCountryChecked () {
      const checkedSet = new Set(this.checkCountry);
      return (value) => checkedSet.has(value);
 },
//accounts
isStatusChecked() {
      const checkedSet = new Set(this.checkAccountStatus);
      return (value) => checkedSet.has(value);
 },
isRoleChecked() {
      const checkedSet = new Set(this.checkRole);
      return (value) => checkedSet.has(value);
 },
isOnlineChecked() {
      const checkedSet = new Set(this.checkOnline);
      return (value) => checkedSet.has(value);
 },
 //monitoring
isMonitorChecked () {
      const checkedSet = new Set(this.filterMonitoring.checkLocations);
      return (value) => checkedSet.has(value);
 },
isMonitorCategoryChecked() {
      const checkedSet = new Set(this.filterMonitoring.checkDeviceCategory);
      return (value) => checkedSet.has(value);
 },
isDomainChecked() {
      const checkedSet = new Set(this.filterMonitoring.checkDomain);
      return (value) => checkedSet.has(value);
 },
isConditionChecked() {
      const checkedSet = new Set(this.filterMonitoring.checkCondition);
      return (value) => checkedSet.has(value);
 },
 
 //device filter panel data count 
 countNumberLocations(){
  return this.checkLocations.length;
  
 },
  countNumberCategories(){
    return this.checkDeviceCategory.length;
  },
  countNumberConected(){
    return this.checkConnectedStatus.length;
  },
  countNumberOnline(){
    return this.checkActivityStatus.length;
  },
  countNumberEnabled(){
    return this.checkDeviceStatus.length;
  },
  countNubnerTags(){
    return this.checkDeviceTags.length;
  },
  getNumberOfTotalDevices(){
    return this.devicesGetter.length;
 },
 numberOfDevices(){
   return this.getTotalDevices;
   },
   //account filter panel data count
getLocationTypeNumber(){
  return this.checkLocationType.length;
  },
countNumberCountries(){
  return this.checkCountry.length;
    },
countNumberLocationTags(){
  return this.checkLocationTags.length;
    },
  //Account filter panel data count
countNumberAccountStatus(){
    return this.checkAccountStatus.length;
  },
countNumberRole(){
    return this.checkRole.length;
  },
countNumberOnlineAccount(){
    return this.checkOnline.length;
  },
countAccountTags(){
    return this.checkAccountTags.length;
  },
isDarkTheme() {
      return this.$vuetify.theme.dark || false;
  },
},
methods:{
  clearAllFilters() {
    const filterProperties = [
      'checkLocations',
      'checkDeviceCategory',
      'checkConnectedStatus',
      'checkActivityStatus',
      'checkDeviceStatus',
      'checkDeviceTags'
    ];

    filterProperties.forEach(property => {
      if (this[property].length > 0) {
        this[property] = [];
      }
    });
  },
  
  clearAllFilterLocation() {
    const locationFilterProperties = [
      'checkLocationType',
      'checkCountry',
      'checkTags'
    ];

    locationFilterProperties.forEach(property => {
      if (this[property].length > 0) {
        this[property] = [];
      }
    });
  },
  cancelFilters() {
    // Reset the filterMonitoring data
    this.filterMonitoring = {
      checkLocations: [],
      checkDeviceCategory: [],
      checkDomain: "top",
      checkCondition: []
    };

    // Dispatch the action to update the filter
    this.$store.dispatch("monitoring/updateFilterMonitor", this.filterMonitoring);
  },
  //apply filters for monitoring
  applyFilters() {
    if(this.filterMonitoring.checkDomain === "bottom"){
      this.$store.dispatch("monitoring/setDomain", "bottom")
      this.footerAppear = false;
    }
    else{
      this.$store.dispatch("monitoring/setDomain", "top")
      this.footerAppear = false;
    }
    let data = this.filterMonitoring
    this.$store.dispatch("monitoring/updateFilterMonitor", data);
    this.footerAppear = false;
  },
  initialFilters(){
    this.checkConnectedStatus = ["connected", "disconnected", "non_connected"];
    this.checkLocations = JSON.parse(localStorage.getItem("checkLocations")) || [];
    //this.checkDeviceCategory = JSON.parse(localStorage.getItem("checkDeviceCategory")) || [];
    this.checkConnectedStatus = JSON.parse(localStorage.getItem("checkConnectedStatus")) || [];
    this.checkActivityStatus = JSON.parse(localStorage.getItem("checkActivityStatus")) || [];
    this.checkDeviceStatus = JSON.parse(localStorage.getItem("checkDeviceStatus")) || [];
    this.checkDeviceTags = JSON.parse(localStorage.getItem("checkDeviceTags")) || [];
    this.checkLocationType = JSON.parse(localStorage.getItem("checkLocationType")) || [];
    this.checkCountry = JSON.parse(localStorage.getItem("checkCountry")) || [];
    this.checkLocationTags = JSON.parse(localStorage.getItem("checkLocationTags")) || [];
    this.checkAccountStatus = JSON.parse(localStorage.getItem("checkAccountStatus")) || [];
    this.checkRole = JSON.parse(localStorage.getItem("checkRole")) || [];
    this.checkOnline = JSON.parse(localStorage.getItem("checkOnline")) || [];
    this.checkAccountTags = JSON.parse(localStorage.getItem("checkAccountTags")) || [];
  },
  selectAllCategoryBoxes(category) {
    category.expanded = true;
    category.subCategories.forEach(subCategory => {
      subCategory.checked = category.checked;
    });

    if (category.checked) {
      category.subCategories.forEach(subCategory => {
        if (!this.checkDeviceCategory.includes(subCategory.value)) {
          this.checkDeviceCategory.push(subCategory.value);
        }
      });
    } else {
      category.subCategories.forEach(subCategory => {
        category.expanded = false;
        this.checkDeviceCategory = this.checkDeviceCategory.filter(value => value !== subCategory.value);
      });
    }
    this.updateParentCheckboxState(category);
  },
  
  toggleCategoryExpanded(category) {
    category.expanded = !category.expanded;
  },

  updateParentCheckboxState(category) {
    const allChecked = category.subCategories.every(sub => sub.checked);
    const someChecked = category.subCategories.some(sub => sub.checked);
    if (allChecked) {
      category.checked = true;
      category.icon = this.icons.mdiCheckboxMarked;
    } else if (someChecked) {
      category.checked = true;
      category.icon = this.icons.mdiMinus;
    } else {
      category.checked = false;
      category.icon = this.icons.mdiCheckboxBlankOutline;
    }
  },

  onChildCheckboxChange(category, subCategory) {
    // Update the parent checkbox state
    this.updateParentCheckboxState(category);
    if (subCategory.checked) {
    if (!this.checkDeviceCategory.includes(subCategory.value)) {
      this.checkDeviceCategory.push(subCategory.value);
    }
  } else {
    this.checkDeviceCategory = this.checkDeviceCategory.filter(value => value !== subCategory.value);
  }
  },
  

},

mounted(){
  this.initialFilters();

}
};
</script>

<style
    lang="scss"
    scoped>

/* select the input element with aria-checked attribute */
.v-input input[type="checkbox"]:checked ~ .v-input__control {
  background-color: red;
}
.scrollable-container {
  max-height: 300px; /* or whatever maximum height you want */
  overflow-y: auto;  /* make it scrollable when content overflows */
}

.checkbox-button {
  display: inline-block;
  margin: 5px 5px;
  border-radius: 3px;
  border: none;
  color:var(--v-secondaryText-base);
  font-size: 12px;
  background-color: var(--v-borderColor-base);
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-in-out;
}
.checkbox-button span {
  padding: 3px 7px;
  display: inline-block;
  transition: all 0.2s ease-in-out;
}

.checkbox-button input[type="checkbox"] {
  display: none;
}
.custom-checkbox{
  height: 25px;
}

.checkbox-button input[type="checkbox"]:checked + span {
  border-radius: 3px;
  margin: 0;
  padding: 3px 7px;
  background-color:var(--v-primary-base);
  color: white;
  border: none;
}


.checkbox-button:active span {
  transform: scale(0.9);
  
}
#no-background-hover::before {
   background-color: transparent !important;
}

.v-btn {
    text-transform: unset !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
    }
.floating-footer{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 0 16px 0 16px;
    width: 274px;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    color: var(--v-secondaryText-base);
    margin-left: 100px;
    height: 50px;
    border-left: 1px solid var(--v-borderColor-base);
    border-right: 1px solid var(--v-borderColor-base);
    border-top: 1px solid var(--v-borderColor-base);
    
}
.alignright{
    float: right;
  
}

.alignleft{
    float: left;
}
::v-deep {
  .v-skeleton-loader__list-item-avatar .v-skeleton-loader__avatar {
    height: 18px;
    width: 18px;
    border-radius: 4px;
  }

  .v-skeleton-loader__list-item-avatar {
    padding: 0 32px 0 0;
  }

  .v-skeleton-loader__list-item {
    height: 16px;
    padding: 0 56px 0 0;
  }

  label.v-label.theme--light{
    font-size: 14px;
  }
  label.v-label.theme--dark {
    font-size: 14px;
  }
.highlight-col {
  background-color:#E1F5FE; /* Set the desired color for the highlighted column */
  border-radius: 5px;
}
.highlight-col-dark {
  background-color:#273039; /* Set the desired color for the highlighted column */
  border-radius: 5px;
}
}
</style>