<template>
  <div>
    <left-sidebar v-if="noSidebarRoutes.indexOf($route.name) === -1"/>
    <v-main class="container-padding  overflow-y-lg-hidden">
      <account-modal v-if="accountModal"></account-modal>
      <v-container class="pt-0" fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <right-sidebar v-if="noSidebarRoutes.indexOf($route.name) === -1"/>
  </div>
</template>

<script>
import RightSidebar from '../components/RightSidebar';
import LeftSidebar from '../components/LeftSidebar';
import AccountModal from '../components/Accounts/AccountModal';
import {mapGetters} from "vuex";

export default {
  name: 'TemplateDefault',
  data: () => ({
    noSidebarRoutes: ['Register', 'Invite', 'Login', 'ForgotPassword', 'NewPassword', 'UpdatePassword', 'Success', 'UpdateForgotPassword']
  }),
  computed: {
    ...mapGetters({"accountModal": "accounts/accountModal"})
  },
  components: {
    LeftSidebar,
    RightSidebar,
    AccountModal
  },
};
</script>

<style>
.container-padding {
  padding: 0 64px 0 100px;
}

@media only screen and (min-width: 1264px) {
  .overflow-y-lg-hidden {
    max-height: 100vh;
    overflow-y: hidden;
  }
}

@media only screen and (max-width: 600px) {
  .container-padding {
    padding: 0 !important;
  }
}
</style>
