var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{staticClass:"nav-drawer",attrs:{"app":"","color":"blackish","width":"100","dark":"","mobile-breakpoint":"600"}},[_c('div',{staticClass:"d-none d-sm-flex justify-center ma-4"},[_c('router-link',{staticStyle:{"height":"48px"},attrs:{"to":"/devices"}},[_c('img',{attrs:{"height":"48","src":require("../assets/logo.png"),"alt":"logo"}})])],1),_c('v-list',{staticClass:"pa-0"},_vm._l((_vm.isAdmin),function(item){return _c('v-list-item',{key:item.title,staticClass:"nav-list",class:{
                'bg-color': _vm.isLocationTab && item.index === 1,
                'group-class': _vm.isGroupTab && item.index === 1,
                'performance-class': _vm.isPerformanceTab && item.index === 2,
                'runners-class': _vm.isRunnersTab && item.index === 3,
               
              },attrs:{"link":"","router":"","to":item.route,"active-class":"bg-color"}},[_c('v-list-item-content',{staticClass:"py-4"},[_c('v-icon',{staticClass:"mb-2 text-center"},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('v-list-item-title',{staticClass:"caption font-weight-medium text-center text-wrap"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }