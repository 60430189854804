<template>
  <div v-if="currentDevice">
    <v-expansion-panels class="elevation-0" accordion v-model="panel">
      <v-expansion-panel v-bind:key="peripheral.uuid" v-for="peripheral in currentDevice.peripherals">
        <v-expansion-panel-header class="px-2 pb-0">
          <div class="d-flex align-start">
            <v-icon class="mr-2 text--secondary" size="18">{{ icons[peripheral['device_type_name']] }}</v-icon>
            <div class="d-flex flex-column justify-center">
              <h3 class="text-primary subtitle-2 mb-4">{{ peripheral.name }}</h3>
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row v-if="peripheral.device_type_name === 'Bill Acceptor' || peripheral.device_type_name === 'Coin Acceptor'" class="mt-0 pt-0 d-flex justify-space-between align-center text--secondary">
            <span>Enabled</span>
            <v-switch :input-value="peripheral.enabled" color="primary" @change="togglePeripheralDevice(peripheral)">
            </v-switch>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {mdiCurrencyUsd, mdiHandCoin, mdiNfc, mdiPowerPlug, mdiPrinter } from "@mdi/js";

export default {
  props: ['device'],
  name: "PeripheralsMisc",
  methods: {
    ...mapActions({
      togglePeripheralStatus: 'devices/togglePeripheralStatus',
      setActivePeripheral: 'devices/setActivePeripheral',
    }),
    togglePeripheralDevice(peripheral) {
      let type = Object.keys(this.categories).find(key => this.categories[key].indexOf(peripheral.device_type_name) !== -1);
      this.togglePeripheralStatus({
        device_uuid: this.device.uuid,
        type: type,
        peripheral_uuid: peripheral.uuid,
        status: !peripheral.enabled
      });
    },
  

  },
  data: () => ({
    panel: null,
    currentDevice: {},
    icons: {
      'Coin Acceptor':  mdiHandCoin,
      'Bill Acceptor': mdiCurrencyUsd,
      'Door Switch': mdiPowerPlug,
      'POS Printer': mdiPrinter,
      'Card Reader': mdiNfc
    },
    categories: {
      'money-acceptor': ['Coin Acceptor', 'Bill Acceptor'],
      'card-reader':['Card Reader']
    }
  }),
  watch: {
    panel: {
      handler(val) {
        this.setActivePeripheral({
          index: val,
          type: 'misc',
          device: this.device.uuid
        });
      }
    },
    device: {
      immediate: true,
      handler(val) {
        this.currentDevice = val;
      },
      deep: true
    }
  },
}
</script>

<style scoped>

</style>