<template>
  <div class="fill-height" v-if="getSelectedDevice">
    <v-row v-if="!getSelectedDevice.uuid"
           class="text-center fill-height"
           align="center">
      <v-col>
        <v-progress-circular
            color="primary"
            indeterminate
            size="48">
        </v-progress-circular>
      </v-col>
    </v-row>
    <template v-else-if="getSelectedDevice.uuid">
      <v-row>
        <v-col>
          <FlyoutMenuHead
              :selectedItem="getSelectedDevice"
              :type="type"
              route="devices"
              :channel="channel"
              />
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-tabs
            v-if="getSelectedDevice"
            class="px-3 d-block transparent"
            :value="tabs.indexOf($route.params.tab)"
            active-class="selected-tab"
            >
          <v-tab
              class="tab px-6 transparent"
              @click="selectTab('general')">
            {{ $t('assets.devices.deviceInfo.tabs.general') }}
          </v-tab>
          <v-tab
              class="tab px-6"
              :disabled="getSelectedDevice.connected_status !== 'connected'"
              @click="selectTab('peripherals')">
            {{ $t('assets.devices.deviceInfo.tabs.peripherals') }}
          </v-tab>
          <v-tab
              class="tab px-6"
              :disabled="getSelectedDevice.connected_status !== 'connected'"
              @click="selectTab('assetInfo')">
            {{ $t('assets.devices.deviceInfo.tabs.assetInfo') }}
          </v-tab>
          <v-tab
              class="tab px-6"
              :disabled="getSelectedDevice.connected_status !== 'connected'"
              @switchTab="(tab) => { selectTab(tab) }"
              @click="selectTab('settings')">
            {{ $t('assets.devices.deviceInfo.tabs.settings') }}
          </v-tab>
        </v-tabs>
      </v-row>
      <v-divider></v-divider>
      <DeviceInfoGeneral
          @switchTab="(tab) => { selectTab(tab) }"
          v-if="$route.params.tab === 'general'"
          :device="getSelectedDevice"/>
      <DeviceInfoPeripherals
          @switchTab="(tab) => { selectTab(tab) }"
          v-if="$route.params.tab === 'peripherals'"
          :device="getSelectedDevice"/>
      <DeviceInfoAssetInfo
          @switchTab="(tab) => { selectTab(tab) }"
          v-if="$route.params.tab === 'assetInfo'"
          :device="getSelectedDevice"/>
      <DeviceInfoSettings
          @switchTab="(tab) => { selectTab(tab) }"
          v-if="$route.params.tab === 'settings'"
          :device="getSelectedDevice"
          :channel="channel"
          />
    </template>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import FlyoutMenuHead from '@/components//FlyoutMenuHead';
import DeviceInfoGeneral from './DeviceInfoGeneral';
import DeviceInfoPeripherals from './DeviceInfoPeripherals';
import DeviceInfoAssetInfo from './DeviceInfoAssetInfo';
import DeviceInfoSettings from './DeviceInfoSettings';


export default {
  props: ['device', 'type', 'channel'],
  name: 'DeviceInfo',
  components: {
    FlyoutMenuHead,
    DeviceInfoGeneral,
    DeviceInfoPeripherals,
    DeviceInfoAssetInfo,
    DeviceInfoSettings,
  },
  data: () => ({
    tabs: ['general', 'peripherals', 'assetInfo', 'settings']
  }),
  computed: {
    ...mapGetters({'devices': 'devices/getDevices', 'getSelectedDevice': 'devices/getSelectedDevice'}),
  },
  metaInfo() {
    return {
      title: `General - ${this.getSelectedDevice?.name}` || "",
    }
  },
  methods: {
    selectTab(tab) {
      this.$router.replace({params: {tab: tab, device: this.getSelectedDevice}})
    },
  },
};
</script>
<style
    lang="scss"
    scoped>
.device-icon {
  padding: 20px;
  background-color: var(--v-lightGrey-base);
  border-radius: 5px;
  width: 64px !important;
  height: 64px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.installed {
  background-color: var(--v-blackish-base) !important;
}

::v-deep {
  .theme--light.v-tabs > .v-tabs-bar {
    background-color: transparent !important;
  }

  .v-tab {
    letter-spacing: normal;
  }

  div.v-slide-group__prev.v-slide-group__prev--disabled {
    display: none !important;
  }
}

.selected-tab {
  color: var(--v-textColor-base);
}
</style>
