<template>
  <div>
    <v-card elevation="0" outlined class="rounded-lg pb-3 pt-0 mt-0">
      <v-row class="align-center mb-0 pb-0">
        <v-col cols="12" md="4" class="d-flex align-center pb-0 justify-space-between">
          <span class="pr-2 pl-2 ml-4">{{ formattedDay }}</span>
          <v-switch
            :disabled="this.$route.params.tab === 'global'"
            class="ml-auto"
            v-model="isDisabled"
            @change="toggleDayOff"
          ></v-switch>
        </v-col>
        <!-- Day Off Label -->
        <v-col cols="12" class="pb-0 mb-0" md="6" v-if="!isDisabled && entries.length === 0">
          <v-text-field
            height="40"
            class="pb-0 mb-0"
            outlined
            value="Day Off"
            disabled
            readonly
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <!-- Time Fields -->
        <template v-else>
          <v-col cols="12" md="3" class="pb-0">
            <v-text-field
              outlined
              v-model="entries[0].start"
              label="Start"
              :rules="timeRules"
              :error-messages="getErrorMessage(0)"
              hide-details="auto"
              dense
               :disabled="isGloballyDisabled"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="pb-0">
            <v-text-field
              outlined
              v-model="entries[0].end"
              label="End"
              :rules="timeRules"
              :error-messages="getErrorMessage(0)"
              hide-details="auto"
              dense
              :disabled="isGloballyDisabled"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="1" class="mt-3">
          <v-btn 
            v-show="this.entries.length > 1"
            outlined
            :class="{'btn-light-trash': !isDarkTheme, 'btn-dark-trash': isDarkTheme}"
            height="40"
            width="40"
            tile
            icon
            @click="deleteEntry(index)"
            :disabled="isGloballyDisabled"
            style="border-radius: 4px;"
          >
            <v-icon>{{ icon.mdiTrash }}</v-icon>
          </v-btn>
          </v-col>
          <v-col cols="12" md="1" class="pb-0 pl-0 ml-0">
            <v-btn
              tile
              icon
              :class="{'btn-light': !isDarkTheme, 'btn-dark': isDarkTheme}"
              width="40"
              height="40"
              @click="addEntry"
              :disabled="isGloballyDisabled"
              style="border-radius: 4px;"
            >
              <v-icon>{{ icon.mdiPlus }}</v-icon>
            </v-btn>
          </v-col>
        </template>
      </v-row>

      <!-- Additional Time Intervals -->
      <v-row v-for="(entry, index) in entries.slice(1)" :key="index" class="align-center">
        <v-col cols="12" md="3" offset-md="4">
          <v-text-field
            outlined
            v-model="entry.start"
            label="hh:mm:ss"
            :rules="timeRules"
            :error-messages="getErrorMessage(index + 1)"
            hide-details="auto"
            dense
             :disabled="isGloballyDisabled"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            v-model="entry.end"
            label="hh:mm:ss"
            :rules="timeRules"
            :error-messages="getErrorMessage(index + 1)"
            hide-details="auto"
            dense
             :disabled="isGloballyDisabled"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="1">
          <v-btn 
          outlined  
          height="40" 
          width="40"  
          tile 
          icon 
          @click="deleteEntry(index)" 
          v-show="isDisabled"
          :class="{'btn-light-trash': !isDarkTheme, 'btn-dark-trash': isDarkTheme}"  
          :disabled="isGloballyDisabled">
            <v-icon>{{ icon.mdiTrash }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mdiPlus, mdiTrashCanOutline } from '@mdi/js';

export default {
  props: ['data', 'day', 'messageError'],
  data() {
    return {
      isDisabled: this.data.length > 0,
      entries: this.data.length ? this.data.map(e => ({ ...e, error: '' })) : [],
      icon: {
        mdiTrash: mdiTrashCanOutline,
        mdiPlus: mdiPlus
      },
    };
  },
  computed: {
    formattedDay() {
      return this.day.replace('working_time_', '').charAt(0).toUpperCase() + this.day.replace('working_time_', '').slice(1);
    },
    timeRules() {
      return [
        value => /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/.test(value) || 'Time must be in hh:mm:ss format',
        () => !this.messageError || this.messageError
      ];
    },
    isGloballyDisabled() {
      return !this.isDisabled || this.$route.params.tab === 'global';
    },
    isDarkTheme() {
      return this.$vuetify.theme.dark || false;
    },
  },
  methods: {
    toggleDayOff() {
      if (this.isDisabled && this.entries.length === 0) {
        this.entries.push({ start: '', end: '', error: '' });
      } else if (!this.isDisabled) {
        this.entries = [];
      }
      this.emitUpdate();
    },
    addEntry() {
      this.entries.push({ start: '', end: '', error: '' });
      this.emitUpdate();
    },
    deleteEntry(index) {
      this.entries.splice(index, 1);
      this.emitUpdate();
    },
    emitUpdate() {
      this.$emit('update-entries', { day: this.day, entries: this.entries });
    },
    // In Child's <script>
  getErrorMessage(index) {
    if (this.messageError && this.messageError[this.day] && this.messageError[this.day][index]) {
      return [this.messageError[this.day][index]];
    }
    return '';
  },
  },
  watch: {
    entries: {
      deep: true,
      handler() {
        this.emitUpdate();
      }
    },
    data: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.entries = newVal.length ? newVal.map(e => ({ ...e, error: '' })) : [];
        this.isDisabled = newVal.length > 0;
      }
    }
  }
};
</script>
<style scoped>
.btn-light-trash {
  border-color: var(--v-lineColor-base);
  border-radius: 4px;
}
.btn-dark-trash {
  border-color: var(--v-borderColor-base);
  border-radius: 4px;
}

.btn-light {
  background-color: #F5F5F5; /* Light background for light theme */ /* Suitable border color for light theme */

}

/* Dark theme styles */
.btn-dark {
  background-color: #424242; /* Darker background for dark theme */
}
</style>