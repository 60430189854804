<template>
  <div class="fill-height">
    <v-dialog
        v-model="dialogs['instant-power-off']"
        max-width="500">
      <v-card class="pa-5">
        <v-row class="pl-5 pr-3">
          <v-card-title class="headline text-primary pb-5" style="align-self: flex-start;">Shutdown</v-card-title>
          <v-card-actions class="ml-auto"><v-btn icon @click="dialogs['instant-power-off'] = false"><v-icon>{{ icons.mdiClose }}</v-icon></v-btn></v-card-actions>
        </v-row>
        <v-card-text class="text-secondary">
          Are you sure you want to shutdown this {{ getSelectedDevice.name }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="cnl-btn px-8"
              text
              @click="dialogs['instant-power-off'] = false"
          >Cancel
          </v-btn>
          <v-btn
              class="red-btn px-8 ml-6"
              text
              @click="powerAction(device.uuid, 'instant-power-off')"
          >Shut Down
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogs['instant-reboot']"
        max-width="500"
    >
      <v-card class="pa-5">
        <v-row class="pl-5 pr-3">
          <v-card-title class="headline text-primary pb-5" style="align-self: flex-start;">Reboot</v-card-title>
          <v-card-actions class="ml-auto"><v-btn @click="dialogs['instant-reboot'] = false" icon><v-icon>{{ icons.mdiClose }}</v-icon></v-btn></v-card-actions>
        </v-row>

        <v-card-text class="text-secondary">
          Are you sure you want to reboot {{ getSelectedDevice.name }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="cnl-btn px-8"
              text
              @click="dialogs['instant-reboot'] = false"
          >
            Cancel
          </v-btn>
          <v-btn
             class="red-btn px-8 ml-6"
              text
              @click="powerAction(device.uuid, 'instant-reboot')"
          >Reboot
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogs['disable']"
        persistent
        max-width="500">
      <v-card class="pa-5">
        <v-row class="pl-5 pr-3">
          <v-card-title class="headline text-primary pb-5" style="align-self: flex-start;">Disable</v-card-title>
          <v-card-actions class="ml-auto"><v-btn @click="() => { disableDeviceSwitch = false; dialogs['disable'] = false }" icon><v-icon>{{ icons.mdiClose }}</v-icon></v-btn></v-card-actions>
        </v-row>
        <v-card-text class="text-secondary">
          Are you sure you want to disable this {{ getSelectedDevice.name }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="cnl-btn px-8"
              text
              @click="() => { disableDeviceSwitch = false; dialogs['disable'] = false }"
          >Cancel
          </v-btn>
          <v-btn
              class="red-btn px-8 ml-6"
              text
              @click="changeEnabledStatus(false)">
            Disable
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="fill-height">
      <v-col md="8"
             class="content-height overflow-y-auto">
        <v-row class="d-flex align-center justify-space-between">
          <v-col
              md="8"
              class="d-flex flex-column">
            <span class="subtitle-1 text-primary">Reboot</span>
            <span class="body-2 text-secondary">Force device to restart. It is recommended this you reboot your device from time to time in order to keep it running smoothly.</span>
          </v-col>
          <v-col
              md="4"
              class="d-flex justify-end">
            <v-btn
                md="4"
                depressed
                class="text-capitalize font-weight-regular subtitle-2"
                v-bind:style= "[this.$vuetify.theme.dark ? {'background-color': '#2C3039'} : {'background-color':'#E4E5E6'}]"
                width="100%"
                :loading="this.loaders['instant-reboot']"
                :disabled="!getSelectedDevice.online"
                @click="dialogs['instant-reboot'] = true">
              Reboot
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-space-between">
          <v-col
              md="8"
              class="d-flex flex-column">
            <span class="subtitle-1 text-primary">Shut Down</span>
            <span class="body-2 text-secondary">Shut down device completely. Be careful, as you won’t be able to remotely turn it on again.</span>
          </v-col>
          <v-col
              md="4"
              class="d-flex justify-end">
            <v-btn
                md="4"
                depressed
                v-bind:style= "[this.$vuetify.theme.dark ? {'background-color': '#2C3039'} : {'background-color':'#E4E5E6'}]"
                class="text-capitalize font-weight-regular subtitle-2"
                width="100%"
                :disabled="!getSelectedDevice.online"
                :loading="this.loaders['instant-reboot']"
                @click="dialogs['instant-power-off'] = true">
              Shut Down
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="d-flex align-center justify-space-between">
          <v-col
              md="8"
              class="d-flex flex-column">
            <span class="subtitle-1 text-primary">Disable</span>
            <span class="body-2 text-secondary">Disable device and prevent user interaction with it. Comes handy when you don't want anyone to interfere with the device and its apps.</span>
          </v-col>
          <v-col
              md="4"
              class="d-flex justify-end">
            <v-switch
                md="4"
                color="error"
                ref="disabledSwitch"
                v-model="disableDeviceSwitch"
                @change="() => { disableDevice() }">
            </v-switch>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="d-flex align-center justify-space-between">
          <v-col
              md="8"
              class="d-flex flex-column">
            <span class="subtitle-1 text-primary">Remote Desktop (RD)</span>
            <span class="body-2 text-secondary">Access and interact with the device just like you are in front of it, even if you are physically miles away.</span>
          </v-col>
          <v-col
              md="4"
              class="d-flex justify-end">
            <v-btn
                md="4"
                depressed
                class="text-capitalize font-weight-regular subtitle-2"
                color="primary"
                width="100%"
                :loading="this.getLoaderRd"
                @click="startRD(device.uuid)"
                :disabled="!getSelectedDevice.online"
            >Request RD
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-space-between">
          <v-col
              md="8"
              class="d-flex flex-column">
            <span class="subtitle-1 text-primary">Request Screenshot</span>
            <span class="body-2 text-secondary">Take screenshot and immediately see what is running on all displays. If you need them for later viewing, make sure to download them locally.</span>
          </v-col>
          <v-col
              md="4"
              class="d-flex justify-end">
            <v-btn
                md="4"
                depressed
                class="text-capitalize font-weight-regular subtitle-2"
                color="primary"
                width="100%"
                :loading="this.getLoaderScreenshot"
                @click="takeScreenshot(device.space_uuid, device.uuid)"
                :disabled="!getSelectedDevice.online">Take Screenshot
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-space-between">
          <v-col
              md="8"
              class="d-flex flex-column">
            <span class="subtitle-1 text-primary">Request RSSH Port</span>
            <span class="body-2 text-secondary">Request RSSH port for inspecting and configuring device via CLI tool of your choice. This option is only visible to Super Admins.</span>
          </v-col>
          <v-col
              md="4"
              class="d-flex justify-end">
            <v-btn
                md="4"
                depressed
                class="text-capitalize font-weight-regular subtitle-2"
                color="primary"
                width="100%"
                :loading="this.getLoadersRssh"
                @click="startRSSH(device.uuid)"
                :disabled="!getSelectedDevice.online">Request RSSH
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-dialog
            v-model="dialogs['replacement']"
            max-width="500"
        >
          <v-card class="pa-5">
            <v-row class="pl-5 pr-3">
          <v-card-title class="headline text-primary pb-5" style="align-self: flex-start;">Generate replacement code</v-card-title>
          <v-card-actions class="ml-auto"><v-btn @click="dialogs['replacement'] = false" icon><v-icon>{{ icons.mdiClose }}</v-icon></v-btn></v-card-actions>
        </v-row>
            <v-card-text class="text-secondary">
              Are you sure you want to replace {{ getSelectedDevice.name }}?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  class="cnl-btn px-8"
                  text
                  @click="dialogs['replacement'] = false"
              >
                Cancel
              </v-btn>
              <v-btn
                  class="red-btn px-8 ml-6"
                  text
                  @click="generateReplacementCode(device.space_uuid, device.uuid)"     
              >
                Generate
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-row class="d-flex align-center justify-space-between">
          <v-col
              md="8"
              class="d-flex flex-column">
            <span class="subtitle-1 text-primary">Generate Replacement Code</span>
            <span class="body-2 text-secondary">Generate replacement code for reconnecting this device, one just like you used for connecting it in the first place.</span>
          </v-col>
          <v-col
              md="4"
              class="d-flex justify-end">
            <v-btn
                md="4"
                depressed
                class="text-capitalize font-weight-regular subtitle-2"
                v-bind:style= "[this.$vuetify.theme.dark ? {'background-color': '#2C3039'} : {'background-color':'#E4E5E6'}]"
                width="100%"
                @click="dialogs['replacement'] = true">Generate
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-space-between">
          <v-col
              md="8"
              class="d-flex flex-column">
            <span class="subtitle-1 text-primary">Enable Debug Mode</span>
            <span class="body-2 text-secondary">Debug mode enables device user to open devtools and troubleshoot possible issues locally on the device.</span>
          </v-col>
          <v-col
              md="4"
              class="d-flex justify-end">
              <v-switch
                md="4"
                color="info"
                v-model="deviceDebugMode"
                @change="enableDebug"
            >
            </v-switch>
          </v-col>
        </v-row>
      </v-col>
      <v-divider
          class="d-none d-md-flex"
          vertical/>
      <v-col
          cols="12"
          sm="12"
          md="4"
          class="ml-n3 py-0 pr-0 content-height overflow-y-auto">
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DevicesApi from '@/services/api/Devices';
import { mdiClose } from '@mdi/js';
import { mapGetters, mapActions } from 'vuex';

export default {
  props: ['device', 'selectedTab', 'channel'],
  data: () => ({
    icons: {
      mdiClose: mdiClose
    },
    localDevice: {},
    selectedDebugInfo: {},
    dialog: false,
    loaders: {
      'instant-reboot': false,
      'instant-power-off': false,
      'generate-replacement': false,
    },
    dialogs: {
      'instant-power-off': false,
      'disable': false,
      'replacement': false,
      'instant-reboot': false
    },
    disableDeviceSwitch: false,
    deviceDebugMode: false,
    timeouts: {
      screenshot: null,
      rd: null,
      rssh: null,
    }
  }),
  mounted() {
    this.localDevice = { ...this.device };
    if (!this.localDevice.enabled) {
      this.disableDeviceSwitch = true;
    }
    if (this.localDevice.debug_mode) {
      this.deviceDebugMode = true;
    }
  },
  metaInfo() {
    return {
      title: `Settings - ${this.device.name}` || "",
    }
  },
  computed: {
    ...mapGetters({
      getSelectedDevice: 'devices/getSelectedDevice',
      getOnlineStatus: 'devices/getOnlineStatus',
      getLoadersRssh: 'devices/loaderRssh',
      getLoaderRd: 'devices/loaderRd',
      getLoaderScreenshot: 'devices/loaderScreenshot'
    }),
  },
  watch: {
    getSelectedDevice: {
      handler() {
        this.selectedDebugInfo = Object.assign({}, this.getSelectedDevice);
      },
      deep: true
    },
    getLoaderRd(newVal) {
      if (!newVal) {
        this.clearRequestTimeout('rd');
      }
    },
    getLoadersRssh(newVal) {
      if (!newVal) {
        this.clearRequestTimeout('rssh');
      }
    },
    getLoaderScreenshot(newVal) {
      if (!newVal) {
        this.clearRequestTimeout('screenshot');
      }
    },
  },
  methods: {
    ...mapActions({
      addAlert: "global/addAlert",
    }),
    startRD(uuid) {
      this.$store.commit("devices/SET_LOADER_RD", true);
      DevicesApi.startRD(uuid)
        .then(() => {
          this.setRequestTimeout('rd');
        });
    },
    startRSSH(uuid) {
      this.$store.commit("devices/SET_LOADER_RSSH", true);
      DevicesApi.startRSSH(uuid)
        .then(() => {
          this.setRequestTimeout('rssh');
        });
    },
    takeScreenshot(space_uuid, uuid) {
      this.$store.commit("devices/SET_LOADER_SCREENSHOT", true);
      DevicesApi.takeScreenshot({ space_uuid, uuid }, {
        action: 'screenshot',
      }).then(() => {
        this.setRequestTimeout('screenshot');
      });
    },
    setRequestTimeout(action) {
      this.clearRequestTimeout(action);
      this.timeouts[action] = setTimeout(() => {
        this.handleTimeout(action);
      }, 30000);
    },
    clearRequestTimeout(action) {
      console.log('clearRequestTimeout called with action:', action);
      console.log('Current timeouts:', this.timeouts);

      if (this.timeouts && this.timeouts[action]) {
        clearTimeout(this.timeouts[action]);
        this.timeouts[action] = null;
        console.log(`Timeout for ${action} cleared`);
      } else {
        console.log(`No timeout found for ${action}`);
      }
    },
    handleTimeout(action) {
      this.$store.commit(`devices/SET_LOADER_${action.toUpperCase()}`, false);
      this.addAlert({
        type: "warning",
        message: `Service temporarily unavailable, try again later.`,
      });
    },
    changeEnabledStatus(bool) {
      DevicesApi.toggleEnabled(this.localDevice.space_uuid, this.localDevice.uuid, {
        enabled: bool,
      }).then(() => {
        this.disableDeviceSwitch = !bool;
        this.dialogs['disable'] = false;
        this.localDevice.enabled = bool;
        this.$store.commit('updateDevice', this.localDevice);
        this.addAlert({
          type: 'success',
          message: `Device ${bool ? 'enabled' : 'disabled'} successfully.`,
        });
      }).catch(err => {
        this.disableDeviceSwitch = false;
        this.addAlert({
          type: 'error',
          message: `Failed to toggle status. Check console.`,
        });
        console.log(err);
      });
    },
    disableDevice() {
      if (this.localDevice.enabled) {
        this.dialogs['disable'] = true;
      } else {
        this.disableDeviceSwitch = false;
        this.changeEnabledStatus(true);
      }
    },
    toggleDebug(space_uuid, uuid, payload) {
      DevicesApi.toggleDebug(space_uuid, uuid, payload).then(() => {
        this.addAlert({
          class: "pa-6",
          type: "success",
          message: "Debug status toggled successfully.",
        });
      })
      .catch(err => {
        this.addAlert({
          class: "pa-6",
          type: "error",
          message: `Failed to toggle debug status. Check console.`,
        });
        console.error(err);
      });
    },
    enableDebug() {
      let payload = { debug_mode: this.deviceDebugMode };
      this.toggleDebug(this.localDevice.space_uuid, this.localDevice.uuid, payload);
    },
    generateReplacementCode(space_uuid, uuid) {
      this.dialogs['replacement'] = false;
      this.loaders['generate-replacement'] = true;
      DevicesApi.generateReplacementCode(space_uuid, uuid, {}).then((response) => {
        this.addAlert({
          type: 'success',
          message: `Replacement code generated successfully.`,
        });
        this.loaders['generate-replacement'] = false;
        let payload = {
          uuid: this.getSelectedDevice.uuid,
          data: response.data
        };
        this.$store.commit("devices/UPDATE_DEVICE_UUID", payload);
        this.$emit("switchTab", "general");
      }).catch(err => {
        this.addAlert({
          type: 'error',
          message: `An error occurred. Check console.`,
        });
        console.log(err);
      });
    },
    powerAction(uuid, action) {
      this.loaders[action] = true;
      this.dialogs[action] = false;
      DevicesApi.powerAction(uuid, { action }).then(response => {
        this.addAlert({
          type: 'success',
          message: `Action successful.`,
        });
        if (response.status === 204) {
          this.loaders[action] = false;
          this.localDevice.online = false;
        } else {
          this.loaders[action] = false;
          this.dialogs[action] = false;
        }
      }).catch(err => {
        this.addAlert({
          type: 'error',
          message: `An error occurred. Check console.`,
        });
        console.log(err);
        this.loaders[action] = false;
        this.dialogs[action] = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.headline {
  font-size: large;
  font-weight: 600;
}
.red-btn {
  background-color: red;
  color: white;
  text-transform: none;
  font-family: Roboto;
}
.cnl-btn {
  text-transform: none;
  font-weight: 300;
  font-family: Roboto;
}
</style>