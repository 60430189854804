<template>
  <div>
    <v-row>
      <v-col class="py-2">
        <FlyoutMenuHead
            :selectedItem="getSelectedDevice"
            type="edit"
            route="devices"
        />
        <v-divider></v-divider>
        <CustomForm
            :formData="formData"
            type="edit"
            @onAddTag="addNewTag"
            :currentData="getSelectedDevice"
            @onSubmit="editDevice"
            :loading="loader"
            @onClose="
            () => {
              this.$router.go(-1);
            }
          "
        ></CustomForm>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DevicesApi from "@/services/api/Devices";
import CustomForm from "@/components/CustomForm";
import FlyoutMenuHead from "@/components/FlyoutMenuHead";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "DeviceInfoEdit",
  components: {
    FlyoutMenuHead,
    CustomForm,
  },
  mounted() {
    if (!this.getSelectedDevice.uuid) {
      this.getDevice({route: this.$route.name, uuid: this.$route.params.uuid})
    }
  },
  methods: {
    ...mapActions({"getDevice": "devices/getSpecificDevice", "getTags": "tags/getTags", "addTag": "tags/addTag"}),
    addNewTag(tag) {
      this.addTag(tag).then(response => {
        console.log(response);
      }).catch(error => {
        console.log(error);
      });
    },
    editDevice(data) {
      this.loader = true;
      console.log(data);
      DevicesApi.editDevice(
          this.getSelectedDevice.space_uuid,
          this.getSelectedDevice.uuid,
          data,
      )
          .then((response) => {
            if (response.status === 200) {
              let payload = {
                "uuid": this.getSelectedDevice.uuid,
                "data": response.data
              };
              this.$store.commit("devices/UPDATE_DEVICE_UUID", payload);
              this.$store.commit("updateAlert", {
                type: "success",
                shown: true,
                message: `Device ${this.getSelectedDevice.name} updated successfully.`,
              });
              this.$router.go(-1);
            }
          })
          .catch((err) => {
            this.$store.commit("updateAlert", {
              type: "error",
              shown: true,
              message: `Failed to edit device. Check console.`,
            });
            console.log(err);
          });
          this.loader = false;
    },
  },
  data: () => ({
    loader: false,
    formData: {
      sections: [
        {
          title: "General",
          inputs: {
            name: {
              value: "name",
              gridSize: 8,
              type: "text",
              placeholder: "Friendly name for your device",
              label: "Device Name",
            },
            description: {
              value: "description",
              gridSize: 8,
              type: "textarea",
              placeholder: "Description",
              options: {
                counter: 1000,
                hint: "You can always edit predefined description",
              },
            },
          },
        },
        {
          title: "Tags",
          inputs: {
            tag_uuids: {
              value: "tag_uuids",
              gridSize: 8,
              type: "tags",
              placeholder: "Edit tags for your device",
              label: "Tags",
              options: {
                items: [],
                action: "tags/getTags",
                counter: 20,
                text: "name",
                value: "uuid"
              }
            }
          }
        }
      ],
    },
  }),
  computed: {
    ...mapGetters("devices", ["getSelectedDevice"]),
  },
};
</script>

<style scoped lang="scss"></style>
