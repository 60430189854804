<template>
  <div>
    <v-row>
      <v-tabs height="61px" style="border-bottom: 1px solid var(--v-borderColor-base);">
           <span class="NSoft-Assets-Devices mt-5 ml-6"> 
          Admin Panel  
        </span>
      </v-tabs>
    </v-row>
    <v-row>
        <v-tabs style="border-bottom: 1px solid var(--v-borderColor-base);" v-model="tab">
          <v-tab active-class="selected-tab" class="px-6" to="/applications">
            {{ $t("application.tabs.appliactionTab") }}
          </v-tab>
          <v-tab active-class="selected-tab" class="px-6" to="/runners">
            {{ $t("application.tabs.runnersTab") }}
          </v-tab>
        </v-tabs> 
    </v-row>
    <v-divider class="mx-1" style="margin-top: -1px"/>
    <router-view/>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  mounted() {},
  computed: {
    ...mapGetters({"space_name":"accounts/spaceName"}),
    currentTab() {
      return this.$route.name;
    },
  },
   methods: {
 
  },
  name: "Config",
  components: {
    
  },
  data: () => ({
    tab: null,
   
   
  }),
};
</script>
<style lang="scss" scoped>
.device-icon {
  padding: 20px;
  background-color: var(--v-lightGrey-base);
  border-radius: 4px;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fab-button {
  margin-right: 64px;
}

.location-card {
  transition: all 0.1s ease !important;
}

.location-card:hover {
  background-color: var(--v-general_bckgr-base, 0.3) !important;
}

.location-info {
  margin-left: 16px;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column !important;
}
.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 8px;
}

.selected-tab {
  color: var(--v-textColor-base) !important;
}
#no-background-hover::before {
   background-color: transparent !important;
}
.capitalize {
  text-transform: capitalize;

}

.test {
  width: 100%;
}

.NSoft-Assets-Devices {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: var(--v-secondaryText-base);
}

.NSoft-Assets-Devices .Text-Style-4 {
  color: var(--v-textColor-base) !important;
}


::v-deep {
  .v-tab {
    letter-spacing: normal;
  }
}
</style>
