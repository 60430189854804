import IAM from "@/services/api/IAM"
import {TokenStorage} from "@/services/TokenStorage";
import router from "@/router";
import Accounts from "../../services/api/Accounts";

const IAMData = JSON.parse(localStorage.getItem("iam_data"));
const state = {
  keycloak: {
    access_token: IAMData?.access_token || "",
    expires_in: IAMData?.expires_in || "",
    refresh_token: IAMData?.refresh_token || "",
  },

}

const getters = {
  isAuthenticated(state) {
    return !!state.keycloak.access_token;
  },
  getExpiresIn(state) {
    return state.keycloak.expires_in;
  },
  getRefreshToken(state) {
    return state.keycloak.refresh_token;
  }

}

const actions = {
  //eslint-disable-next-line
  loginUser({}, data) {
    return new Promise((resolve, reject) => {
      const {email_address, password} = data
      console.log(data)
      IAM.loginUser(email_address, password).then(response => {
        console.log(response)
        TokenStorage.storeIAMData(response.data);
        resolve(response.data);
      }).catch(err => {
        reject(err.response.data);
      });
    });
  },
  logoutUser({dispatch}, data) {
    Accounts.logout(data).then(() => {
      localStorage.removeItem("iam_data");
      dispatch('accounts/toggleAccountModal', null, {root: true});
      return router.replace('/login');
    }).catch(err => {
      console.log(err);
    });
  },
  //eslint-disable-next-line
  fetchSpaceName({commit}, data) {
    return new Promise((resolve, reject) => {
      const {uuid, token} = data
      IAM.fetchSpaceName(uuid, token).then(response => {
        resolve(response.data);
      }).catch(err => {
        reject(err.response.data);
      });
    })
  },
  //eslint-disable-next-line
  fetchDisplayName({commit}, data) {
    return new Promise((resolve, reject) => {
      const {uuid, token} = data
      IAM.fetchSpaceName(uuid, token).then(response => {
        resolve(response.data);
      }).catch(err => {
        console.log(err);
        reject(err.response);
      });
    })
  },
  //eslint-disable-next-line
  handleSpaceJoin({commit}, data) {
    return new Promise((resolve, reject) => {
      const {uuid, token, action} = data
      IAM.spaceJoin(uuid, {token, action}).then(response => {
        resolve(response.data);
      }).catch(err => {
        reject(err.response.data);
      });
    });
  },
  //eslint-disable-next-line
  register({commit}, payload) {
    return new Promise((resolve, reject) => {
      const {uuid, token, password} = payload;
      IAM.register(uuid, {token, password}).then(response => {
        resolve(response.data);
      }).catch(err => {
        reject(err.response.data);
      });
    });
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
