<template>
  <v-row>
    <v-col class="py-2">
      <FlyoutMenuHead :selectedItem="flyoutHead" route="accounts"/>
      <v-divider></v-divider>
      <v-row class="mt-8">
        <v-col md="12">
          <span class="font-weight-medium subtitle text-secondary">Invite Users</span>
        </v-col>
      </v-row>
      <v-row v-for="(set, i) in user_sets" :key="i">
        <v-col md="5">
          <v-combobox
              chips
              v-model="set.users"
              multiple
              label="Email"
              outlined
              dense
              disable-lookup
              append-icon=""
              >
            <template v-slot:selection="data">
              <v-chip v-if="chip" :color="isDarkTheme ? '#757575':'#E3F2FD'" close @click:close="remove(i, data.index)" class="chip-border">
                {{ data.item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <v-col md="4" class="d-flex">
          <v-select outlined dense :items="roles" item-text="name" item-value="uuid" v-model="set.role"></v-select>
          <v-btn icon class="ml-2" @click="removeSet(i)">
            <v-icon>{{ icons.mdiDelete }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-btn color="primary" class="letter-spacing-normal text-capitalize" @click="addSet">
            <v-icon>{{ $vuetify.icons.values.plus }}</v-icon>
            Add another
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="text-right bottom-navigation-edit py-1 px-2">
        <v-col>
          <v-btn
              width="120"
              class="mr-2 font-weight-regular letter-spacing-normal text-capitalize"
              depressed
              @click="closeForm()"
              text>{{ $t('common.cancelBtn') }}
          </v-btn>
          <v-btn
              width="120"
              class="primary font-weight-normal letter-spacing-normal text-capitalize"
              depressed
              color="primary"
              @click="submitClicked = true; submitForm()">Invite users
          </v-btn>
        </v-col>
      </v-row>
      <input type="text" @keypress="handleKeyPress"> <!-- New line added -->
    </v-col>
  </v-row>
</template>

<script>
import FlyoutMenuHead from "../FlyoutMenuHead";
import { mapActions, mapGetters } from "vuex";
import { mdiDelete } from "@mdi/js";

export default {
  name: "InviteUser.vue",
  components: {
    FlyoutMenuHead
  },
  mounted() {
    this.getRoles().then(() => {
      this.user_sets[0].role = this.roles[0].uuid;
    })
  },
  computed: {
    ...mapGetters({ "roles": "accounts/getRoles" }),
    isDarkTheme() {
      return this.$vuetify.theme.dark || false;
    },
  },
  watch: {
    user_sets: {
      handler(newVal) {
        if (this.submitClicked && !this.enterPressed && newVal.some(set => set.users.length > 0)) {
          // Automatically submit the data to the backend
          this.submitForm();
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({ "getRoles": "accounts/getRoles", "inviteUsers": "accounts/inviteUsers", "addAlert": "global/addAlert" }),
    closeForm() {
      this.$router.go(-1);
    },
    submitForm() {
      let data = [];
      this.user_sets.forEach((set) => {
        if (set.users.length > 0) {
          set.users.forEach((user) => {
            const emails = user.split(/[,\s]+/);
            emails.forEach((email) => {
            if (email) {
              data.push({
                email: email.trim(), // Remove leading/trailing spaces from email
                role_uuid: set.role
              });
            }
          });
          });
        }
      });

      if (data.length !== 0) {
        this.inviteUsers(data)
          .then(() => {
            this.addAlert({
              class: "pa-6",
              type: "success",
              message: "User has been invited successfully",
            });
            this.$router.go(-1);
          })
          .catch(error => {
            this.addAlert({
              type: "error",
              message: "Failed to invite user",
            });
            console.error(error);
          });
      } else if (data.users === "") {
        this.addAlert({
          type: "error",
          message: "No users to invite",
        });
      }
    },
    remove(setIndex, userIndex) {
      if (setIndex > -1 && userIndex > -1) {
        this.user_sets[setIndex].users.splice(userIndex, 1);
      }
    },

    addSet() {
      this.user_sets.push({
        role: this.roles[0].uuid,
        users: []
      });
    },
    removeSet(index) {
      this.user_sets.splice(index, 1);
      
    },
    handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default behavior of the enter key
      this.enterPressed = true; // Set the enterPressed flag
      this.submitClicked = true; // Set the submitClicked flag
      this.submitForm();
    }
  },
  },
  data: () => ({
    submitClicked: false,
    enterPressed: false,
    chip: true,
    flyoutHead: {
      name: "Invite users to space"
    },
    icons: {
      mdiDelete: mdiDelete
    },
    user_sets: [{
      role: "",
      users: []
    }]
  })
}
</script>

<style scoped lang="scss">
.chip-border {
  border-radius: 4px;
}

.bottom-navigation-edit {
  position: absolute;
  bottom: 0 !important;
  left: 0;
  right: 0;
  z-index: 10000 !important;
  margin: 0;
  box-shadow: 0 -4px 25px 0 rgba(0, 0, 0, 0.13);
}
</style>
