import axiosInstance from "@/services/api/instance";
import store from "@/store";

export default {
    get() {
        return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/tag/items/`);
    },
    add(tag) {
        return axiosInstance.post(`/space/${store.getters["global/getSpace"]}/tag/add/`, {
            name: tag
        });
    }
}