import axiosInstance from "./instance";
import store from "@/store";

export default {
  get(value) {
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/asset/items/?ordering=${value}`)
  },
  getLocationContainDevices(){
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/device/location/items/`);
  },
  startRSSH(uuid) {
    return axiosInstance.post(`/space/${store.getters["global/getSpace"]}/device/${uuid}/remote-sharing/`, {
      "action": "reverse-ssh"
    })
  },
  startRD(uuid) {
    return axiosInstance.post(`/space/${store.getters["global/getSpace"]}/device/${uuid}/remote-sharing/`, {
      "action": "remote-desktop"
    })
  },
  togglePeripheralStatus(device_uuid, type, peripheral_uuid, status) {
    return axiosInstance.patch(`/space/${store.getters["global/getSpace"]}/device/${device_uuid}/peripherals/${type}/${peripheral_uuid}/enabled-status/`, {
      enabled: status
    })
  },
  getTypes() {
    return axiosInstance.get(`/device-type/items/`)
  },
  getDevicesTypes() {
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/device/category-type/items/`)
  },
  getCategories() {
    return axiosInstance.get(`/device-category/items/`)
  },
  cameras() {
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/camera/items/`)
  },
  getDevice(type, uuid) {
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/${type}/${uuid}/`)
  },
  search(query) {
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/asset/items/?name=${query}`)
  },
  add(payload) {
    return axiosInstance.post(`/space/${store.getters["global/getSpace"]}/device/add/`, payload)
  },
  editDevice(space_uuid, uuid, payload) {
    return axiosInstance.patch(`/space/${space_uuid}/device/${uuid}/`, payload)
  },
  installFromQRCode(space_uuid, uuid, payload) {
    return axiosInstance.post(`/space/${space_uuid}/device/${uuid}/installation/`, payload)
  },
  powerAction(uuid, payload) {
    return axiosInstance.post(`/device/${uuid}/power-control/`, payload)
  },
  resetCode(space_uuid, uuid, payload) {
    return axiosInstance.post(`/space/${space_uuid}/device/${uuid}/installation-code/reset/`, payload)
  },
  sendEmail(space_uuid, uuid, payload) {
    return axiosInstance.post(`/space/${space_uuid}/device/${uuid}/installation-code/email/`, payload)
  },
  getDisplays(uuid) {
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/device/${uuid}/peripherals/display/configuration/`)
  },
  saveDisplays(uuid, payload) {
    return axiosInstance.post(`/space/${store.getters["global/getSpace"]}/device/${uuid}/peripherals/display/configuration/`, payload)
  },
  detectDisplays(uuid) {
    return axiosInstance.post(`/space/${store.getters["global/getSpace"]}/device/${uuid}/peripherals/display/detection/`)
  },
  detectPeripherals(uuid) {
    return axiosInstance.post(`/space/${store.getters["global/getSpace"]}/device/${uuid}/peripherals/detection/`)
  },
  getAvailableApps(uuid) {
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/device/${uuid}/application/items/`)
  },
  toggleEnabled(space_uuid, uuid, payload) {
    return axiosInstance.patch(`/space/${space_uuid}/device/${uuid}/enabled-status/`, payload)
  },
  toggleDebug(space_uuid, uuid, payload) {
    return axiosInstance.patch(`/space/${space_uuid}/device/${uuid}/debug-status/`, payload)
  },
  generateReplacementCode(space_uuid, uuid, payload) {
    return axiosInstance.post(`/space/${space_uuid}/device/${uuid}/installation-code/replacement/`, payload)
  },
  takeScreenshot(device, payload) {
    return axiosInstance.post(`/space/${device.space_uuid}/device/${device.uuid}/screen-capture/`, payload)
  },
  testCamera(payload) {
    return axiosInstance.post(`/space/${payload.space_uuid}/location/${payload.location_uuid}/camera/${payload.uuid}/connection-test/`, {
      http_port: payload.http_port,
      main_rtsp_port: payload.main_rtsp_port,
      main_rtsp_url: payload.main_rtsp_url,
      username: payload.username,
      password: payload.password
    })
  },
  addCamera(payload) {
    return axiosInstance.patch(`/space/${store.getters["global/getSpace"]}/location/${payload.location_uuid}/camera/${payload.uuid}/`, payload.json)
  },
  installDevice(location_uuid, payload){
    return axiosInstance.post(`/space/${store.getters["global/getSpace"]}/location/${location_uuid}/detected-device-installation/`, payload)
  },
  deleteDevice(space_uuid, uuid) {
    return axiosInstance.delete(`/space/${space_uuid}/device/${uuid}/`)
  },
  archiveDevice(space_uuid, device_uuid) {
    return axiosInstance.patch(`/space/${space_uuid}/device/${device_uuid}/archived-status/`, {
      "archived": true
    })
  },
  unarchiveDevice(space_uuid, device_uuid) {
    return axiosInstance.patch(`/space/${space_uuid}/device/${device_uuid}/archived-status/`, {
      "archived": false
    })
    
  },
  terminateConnection(space_uuid, device_uuid){
    return axiosInstance.patch(`/space/${space_uuid}/device/${device_uuid}/terminate-connection/`, {})
  },
  exportDevices(value){
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/asset/items/export/?${value}`)
  }
}


