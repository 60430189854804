<template>
  <v-row class="fill-height">
    <v-col
        cols="12"
        md="4"
        class="pa-4 content-height overflow-y-auto">
      <v-list dense>
        <v-list-item-group
            mandatory
            v-model="item"
        >
          <v-list-item
              v-for="(item, i) in items"
              :key="i"
              active-class="text-secondary"
          >
            <v-list-item-content>
              <v-list-item-title
                  class="text-secondary"
                  v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-divider
        class="d-none d-md-flex"
        vertical></v-divider>
    <v-col
        cols="12"
        md="8"
        class="ml-n1 pl-4 pt-2 content-height overflow-y-auto"
        v-if="item === 0">
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Device Name</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.name }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Device Category</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.device_category_name }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Device Connected</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span
              class="subtitle-2 text-primary font-weight-regular">{{ device.connected_status === 'connected' ? 'Yes' : 'No' }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Device Connected</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <v-chip
              small
              :color="device.online ? 'success' : 'error'">{{ device.online ? 'Online' : 'Offline' }}
          </v-chip>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Device Status</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.enabled ? 'Enabled' : 'Disabled' }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Online Streak</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">TBA</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Last Online</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.online ? 'Online Now' : 'TBA' }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Space</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.space_name }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Location</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.location_name }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Added On</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ beautifyDate(device.created_at) }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Connected On</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ beautifyDate(device.installed_at) }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">UUID</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.uuid }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-col
        cols="12"
        sm="12"
        md="8"
        class="ml-n1 pl-4 pt-2"
        v-if="item === 1">
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Operating System</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span
              class="subtitle-2 text-primary font-weight-regular">{{ device.operating_system_name || 'Unknown' }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">OS Version</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span
              class="subtitle-2 text-primary font-weight-regular">{{ device.operating_system_version || 'Unknown' }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">OS Architecture</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span
              class="subtitle-2 text-primary font-weight-regular">{{ device.operating_system_architecture || 'Unknown' }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Motherboard</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span
              class="subtitle-2 text-primary font-weight-regular">{{ device.mainboard_product_name || 'Unknown' }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">CPU</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.cpu_version || 'Unknown' }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">CPU Manufacturer</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.cpu_manufacturer || 'Unknown' }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">RAM</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span
              class="subtitle-2 text-primary font-weight-regular">{{ device.ram_size ? Math.round((parseInt(device.ram_size, 10) / 1048576), 2) + ' GB' : 'Unknown' }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Local IP Address</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.local_ip_addresses.join(', ') }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Location</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.location_name }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">MAC Address</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span
              class="subtitle-2 text-primary font-weight-regular">{{ device.mac_addresses.join(', ').toUpperCase() }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-col
        cols="12"
        sm="12"
        md="8"
        class="ml-n1 pl-4 pt-2"
        v-if="item === 2">
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Installation Date</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ beautifyDate(device.installation_date) }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Installation UUID</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.installation_image_uuid }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Installation Name</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.installation_image_name }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Salt Minion ID</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.salt_minion_id }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Last Updated</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ beautifyDate(device.updated_at) }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-col
        cols="12"
        sm="12"
        md="8"
        class="ml-n1 pl-4 pt-2"
        v-if="item === 3">
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="6"
            class="py-1">
          <span class="text-primary font-weight-regular subtitle-2">Device Port Configurator</span>
        </v-col>
        <v-col
            cols="4"
            sm="6"
            class="d-flex justify-end align-center py-1">
          <span class="subtitle-2 text-secondary font-weight-regular">Healthy</span>
          <v-icon color="#00a86b" class="ml-2">{{ icons.mdiCheckboxMarkedCircle }}</v-icon>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="6"
            class="py-1">
          <span class="text-primary font-weight-regular subtitle-2">Paylink Control</span>
        </v-col>
        <v-col
            cols="4"
            sm="6"
            class="d-flex justify-end align-center py-1">
          <span class="subtitle-2 text-secondary font-weight-regular">Healthy</span>
          <v-icon color="#00a86b" class="ml-2">{{ icons.mdiCheckboxMarkedCircle }}</v-icon>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="6"
            class="py-1">
          <span class="text-primary font-weight-regular subtitle-2">Local Pub/Sub v2</span>
        </v-col>
        <v-col
            cols="4"
            sm="6"
            class="d-flex justify-end align-center py-1">
          <span class="subtitle-2 text-secondary font-weight-regular">Healthy</span>
          <v-icon color="#00a86b" class="ml-2">{{ icons.mdiCheckboxMarkedCircle }}</v-icon>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="6"
            class="py-1">
          <span class="text-primary font-weight-regular subtitle-2">Seven Money Service</span>
        </v-col>
        <v-col
            cols="4"
            sm="6"
            class="d-flex justify-end align-center py-1">
          <span class="subtitle-2 text-secondary font-weight-regular">Disabled</span>
          <v-icon color="#ec4234" class="ml-2">{{ icons.mdiCancel }}</v-icon>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="6"
            class="py-1">
          <span class="text-primary font-weight-regular subtitle-2">Local Stream Producer</span>
        </v-col>
        <v-col
            cols="4"
            sm="6"
            class="d-flex justify-end align-center py-1">
          <span class="subtitle-2 text-secondary font-weight-regular">Healthy</span>
          <v-icon color="#00a86b" class="ml-2">{{ icons.mdiCheckboxMarkedCircle }}</v-icon>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="6"
            class="py-1">
          <span class="text-primary font-weight-regular subtitle-2">PoS Printing Service</span>
        </v-col>
        <v-col
            cols="4"
            sm="6"
            class="d-flex justify-end align-center py-1">
          <span class="subtitle-2 text-secondary font-weight-regular">Low paper level</span>
          <v-icon color="#ffba13" class="ml-2">{{ $vuetify.icons.values.alertCircle }}</v-icon>
          <v-icon class="ml-2">{{ $vuetify.icons.values.dotsVertical }}</v-icon>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="6"
            class="py-1">
          <span class="text-primary font-weight-regular subtitle-2">Linux Log Collector</span>
        </v-col>
        <v-col
            cols="4"
            sm="6"
            class="d-flex justify-end align-center py-1">
          <span class="subtitle-2 text-secondary font-weight-regular">Not Responding</span>
          <v-icon color="#ec4234" class="ml-2">{{ $vuetify.icons.values.minusCircle }}</v-icon>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="6"
            class="py-1">
          <span class="text-primary font-weight-regular subtitle-2">Device Service Discovery</span>
        </v-col>
        <v-col
            cols="4"
            sm="6"
            class="d-flex justify-end align-center py-1">
          <span class="subtitle-2 text-secondary font-weight-regular">Healthy</span>
          <v-icon color="#00a86b" class="ml-2">{{ icons.mdiCheckboxMarkedCircle }}</v-icon>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-3">
        <v-col
            cols="12"
            sm="6"
            class="py-1">
          <span class="text-primary font-weight-regular subtitle-2">Device Display Manager</span>
        </v-col>
        <v-col
            cols="4"
            sm="6"
            class="d-flex justify-end align-center py-1">
          <span class="subtitle-2 text-secondary font-weight-regular">Healthy</span>
          <v-icon color="#00a86b" class="ml-2">{{ icons.mdiCheckboxMarkedCircle }}</v-icon>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// TODO: Dodati GPU, Storage, Online Streak, Last Online i External IP Address
import { format } from 'date-fns';
import { mdiCheckboxMarkedCircle, mdiCancel } from '@mdi/js';
export default {
  props: ['device'],
  methods: {
    beautifyDate(date) {
      return date ? format(date, 'DD MMM. YYYY HH:mm:ss') : "";
    },
  },
  data: () => ({
    item: 0,
    icons: {
      'mdiCheckboxMarkedCircle': mdiCheckboxMarkedCircle,
      'mdiCancel': mdiCancel,
    },
    items: [
      {text: 'Basic'},
      {text: 'System Specs'},
      {text: 'Media'},
    ],
  }),
};
</script>

<style scoped>

</style>
