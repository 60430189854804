import axiosInstance from "./instance";
import store from "@/store";
export default {
    get(value) {
        return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/location-group/items/?${value}`)
      },
    getGroup(uuid) {
        return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/location-group/${uuid}/`)
    },
    addGroup(object){
      return axiosInstance.post(`/space/${store.getters["global/getSpace"]}/location-group/add/`, object);
    },
    editGroup(uuid, object){
      return axiosInstance.patch(`/space/${store.getters["global/getSpace"]}/location-group/${uuid}/`, object);
    },
    deleteGroup(uuid){
        return axiosInstance.delete(`/space/${store.getters["global/getSpace"]}/location-group/${uuid}/`)
    },
    exportGroups(value){
      return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/location-group/items/export/?${value}`)
    }
  }