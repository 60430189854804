<template>
  <v-row class="fill-height">
    <v-col cols="12" md="4" class="pa-4 content-height overflow-y-auto">
      <v-list dense>
        <v-list-item-group mandatory v-model="item">
          <v-list-item
              v-for="(item, i) in items"
              :key="i"
              active-class="text-secondary"
          >
            <v-list-item-content>
              <v-list-item-title
                  class="text-secondary"
                  v-text="item.text"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-divider class="d-none d-md-flex" vertical></v-divider>
    <v-col
        cols="12"
        md="8"
        class="ml-n1 pl-4 pt-4 content-height overflow-y-auto"
        v-if="item === 0"
    >
      <v-row class="my-3 ml-1 rounded-lg" style="border: 1px solid #dedede">
        <v-col cols="12" sm="6" class="pa-0">
          <v-img
              class="rounded-l-lg"
              src="https://images.unsplash.com/photo-1426901523280-39daa6101bce?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1080&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=1920"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-row>
            <v-col cols="12" sm="6">
              <span class="subtitle-2 text-secondary font-weight-regular"
              >Device Connected</span
              >
            </v-col>
            <v-col cols="12" sm="6">
              <span class="subtitle-2 text-primary font-weight-regular"
              >Yes</span
              >
            </v-col>
          </v-row>
          <v-divider/>
          <v-row>
            <v-col cols="12" sm="6">
              <span class="subtitle-2 text-secondary font-weight-regular"
              >Connection Status</span
              >
            </v-col>
            <v-col cols="12" sm="6">
              <v-chip x-small :color="device.online ? 'success' : 'error'"
              >{{ device.online ? "Online" : "Offline" }}
              </v-chip>
            </v-col>
          </v-row>
          <v-divider/>
          <v-btn
              depressed
              color="#dedede"
              class="mt-4 text-capitalize font-weight-regular letter-spacing-normal"
              width="100%"
          >Refresh Snapshot
          </v-btn
          >
        </v-col>
      </v-row>
      <v-row class="mt-4 px-1">
        <v-col md="12" class="d-flex align-center justify-space-between pt-4">
          <span class="subtitle text-secondary ">General</span>
        </v-col>
      </v-row>
      <v-form v-model="isFormValid">
        <v-row class="overflow-y-auto">
          <v-col md="6" class="d-flex align-center justify-space-between">
            <v-text-field
                v-model="camera.ip_address"
                height="40"
                placeholder="IP Address"
                :rules="[rules.required]"
                dense
                outlined
            >
              <template #label>
                <span class="pl-1 pr-1">IP Address</span>
              </template>
            </v-text-field>
          </v-col>
          <v-col md="6" class="d-flex align-center justify-space-between">
            <v-text-field
                v-model="camera.http_port"
                height="40"
                placeholder="HTTP Port"
                :rules="[rules.required]"
                dense
                outlined
            >
              <template #label>
                <span class="pl-1 pr-1">HTTP Port</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="overflow-y-auto">
          <v-col md="6" class="d-flex align-center justify-space-between">
            <v-text-field
                v-model="camera.main_rtsp_port"
                height="40"
                placeholder="Main RTSP Port"
                :rules="[rules.required]"
                dense
                outlined
            >
              <template #label>
                <span class="pl-1 pr-1">Main RTSP Port</span>
              </template>
            </v-text-field>
          </v-col>
          <v-col md="6" class="d-flex align-center justify-space-between">
            <v-text-field
                v-model="camera.sub_rtsp_port"
                height="40"
                placeholder="Sub RTSP Port"
                dense
                outlined
            >
              <template #label>
                <span class="pl-1 pr-1">Sub RTSP Port</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="overflow-y-auto">
          <v-col md="12" class="d-flex align-center justify-space-between">
            <v-text-field
                v-model="camera.main_stream"
                height="40"
                placeholder="Main Stream"
                :rules="[rules.required]"
                dense
                outlined
            >
              <template #label>
                <span class="pl-1 pr-1">Main Stream</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="overflow-y-auto">
          <v-col md="12" class="d-flex align-center justify-space-between">
            <v-text-field
                v-model="camera.sub_stream"
                height="40"
                placeholder="Sub Stream"
                dense
                outlined
            >
              <template #label>
                <span class="pl-1 pr-1">Sub Stream</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="px-1">
          <v-col md="12" class="d-flex align-center justify-space-between pt-4">
            <span class="subtitle text-secondary ">Authorization</span>
          </v-col>
        </v-row>
        <v-row class="overflow-y-auto">
          <v-col md="6" class="d-flex align-center justify-space-between">
            <v-text-field
                v-model="camera.username"
                height="40"
                placeholder="Username"
                :rules="[rules.required]"
                dense
                outlined
            >
              <template #label>
                <span class="pl-1 pr-1">Username</span>
              </template>
            </v-text-field>
          </v-col>
          <v-col md="6" class="d-flex align-center justify-space-between">
            <v-text-field
                v-model="camera.password"
                height="40"
                type="password"
                placeholder="Password"
                :rules="[rules.required]"
                dense
                outlined
            >
              <template #label>
                <span class="pl-1 pr-1">Password</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-row class="text-right">
        <v-col>

          <v-btn
              width="120"
              class="font-weight letter-spacing-normal text-capitalize"
              color="primary"
              depressed
              :disabled="!isFormValid"
          >Connect
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["device"],
  methods: {},
  data: () => ({
    isFormValid: true,
    camera: {
      ip_address: "",
    },
    item: 0,
    rules: {
      required: (value) => !!value || "Required.",
    },
    items: [{text: "Settings"}, {text: "Maintenance"}],
  }),
};
</script>
