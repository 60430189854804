<template>
  <div>
    <speed-dial
        :type="currentTab"
        :items="currentTab === 'devices' ? fab.devices : currentTab === 'locations' ? fab.locations: fab.groups"
    >
    </speed-dial>
    <v-row>
      <v-tabs height="61px" style="border-bottom: 1px solid var(--v-borderColor-base); ">
           <span class="NSoft-Assets-Devices mt-5 ml-6">
            <span class="text-uppercase">
              {{ space_name }}
            </span>
            <span>
            / Assets /
            </span>
            <span class="capitalize Text-Style-4">
            {{ this.$route.name === "devices" || this.$route.name === "device_action" || this.$route.name === "device" ? "devices": this.$route.name === "groups" || this.$route.name === 'group_action' || this.$route.name === 'group' ? "Location groups": "locations"}}
            </span>
        </span>
        <v-col class="pt-4  text-right" v-if="this.$route.name !== 'groups'">
        <v-btn-toggle :max="0" multiple :value="[]">
            <v-btn 
              v-bind:style= "[this.$vuetify.theme.dark ? {'background-color': '#232323'} : {'background-color':'#ffffff'}]"
              id="no-background-hover"
              v-ripple="false"
              small 
              depressed
              @click="updateGrid('grid')" 
              >
            <v-icon size="18" :color="this.color === 'grid' ? 'primary':'secondaryText'" >{{ this.icons.mdiGrid }}</v-icon>
            </v-btn>
            <v-btn
              v-bind:style= "[this.$vuetify.theme.dark ? {'background-color': '#232323'} : {'background-color':'#ffffff'}]"
              id="no-background-hover"
              v-ripple="false"
              small 
              depressed
              @click="updateFormat('format')" 
              >
            <v-icon size="18" :color="this.color === 'format' ? 'blue':'secondaryText'">{{ this.icons.mdiFormat}}</v-icon>
            </v-btn>
      </v-btn-toggle>
     </v-col>
      </v-tabs>
    </v-row>
    <v-row >
      <v-col class="px-0 py-0" style="border-bottom: 1px solid var(--v-borderColor-base); width:110%;">
        <v-tabs v-model="tab">
          <v-tab active-class="selected-tab" class="px-6" to="/locations">
            {{ $t("assets.tabs.locationsTab") }}
          </v-tab>
          <v-tab active-class="selected-tab" class="px-6" to="/devices">
            {{ $t("assets.tabs.devicesTab") }}
          </v-tab>
          <v-tab active-class="selected-tab" class="px-6" to="/groups">
            {{ $t("assets.tabs.groupsTab") }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-divider class="mx-1" style="margin-top: -1px"/>
    <router-view/>
  </div>
</template>
<script>
//import Search from '../components/Search';
//import Locations from '../components/Locations';
import { mapGetters } from "vuex";
import SpeedDial from "../components/SpeedDial";
import {mdiDevices, mdiMapMarkerMultiple, mdiImageFilterCenterFocusWeak, mdiMapMarkerPlus,  mdiDotsGrid, mdiEarth, mdiFormatAlignJustify } from "@mdi/js";

export default {
  mounted() {
    // Check if there is a view setting in local storage, if not set it to 'grid'
    const storedView = localStorage.getItem('view');
    if (!storedView) {
      this.updateGrid('grid');  // Default to grid if nothing is stored
    } else {
      this.color = storedView;
      this.$store.commit('setView', storedView);
    }
  },
  computed: {
    ...mapGetters({"user": "accounts/getUser", "currentSpace": "global/getSpace", "space_name": "accounts/spaceName"}),
    currentTab() {
      return this.$route.name;
    },
  },
  methods: {
    updateGrid(value) {
      localStorage.setItem('view', value);
      this.$store.commit('setView', value);
      this.$store.commit('devices/CLEAR_DEVICE_STATE');
      this.color = value;
    },
    updateFormat(value) {
      localStorage.setItem('view', value);
      this.$store.commit('setView', value);
      this.$store.commit('devices/CLEAR_DEVICE_STATE');
      this.color = value;
    },
    closeModalEdit() {
      this.$store.commit("locations/SET_LOCATION_DEVICE_EDIT", false);
    },
  },
  name: "Assets",
  components: {
    "speed-dial": SpeedDial,
  },
  data: () => ({
     view: null,
     color: '',
     icons: {
      "mdiEarth": mdiEarth,
      "mdiFormat": mdiFormatAlignJustify,
      "mdiGrid": mdiDotsGrid,
    },
    tab: null,
    fab: {
      devices: [
        {
          tooltip: "Add Runner Device",
          icon: mdiDevices,
          action: "addDeviceRunner",
        },
        {
          tooltip: "Detect Devices",
          icon: mdiImageFilterCenterFocusWeak,
          action: "detectDevices",
        },
      ],
      locations: [
        {
          tooltip: "Add Location",
          icon: mdiMapMarkerPlus,
          action: "addLocation",
        },
      ],
      groups: [
        {
          tooltip: "Add Location Group",
          icon: mdiMapMarkerMultiple,
          action: "addGroup",
        },
      ],
    },
  }),
};
</script>
<style lang="scss" scoped>
.device-icon {
  padding: 20px;
  background-color: var(--v-lightGrey-base);
  border-radius: 4px;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fab-button {
  margin-right: 64px;
}

.location-card {
  transition: all 0.1s ease !important;
}

.location-card:hover {
  background-color: var(--v-general_bckgr-base, 0.3) !important;
}

.location-info {
  margin-left: 16px;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column !important;
}

.v-card__title {
  display: flex;
  flex-direction: row;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 8px;
}

.selected-tab {
  color: var(--v-textColor-base) !important;
}
#no-background-hover::before {
   background-color: transparent !important;
}
.capitalize {
  text-transform: capitalize;

}

.test {
  width: 100%;
}

.NSoft-Assets-Devices {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: var(--v-secondaryText-base);
}

.NSoft-Assets-Devices .Text-Style-4 {
  color: var(--v-textColor-base) !important;
}


::v-deep {
  .v-tab {
    letter-spacing: normal;
  }
}
</style>
