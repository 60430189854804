<template>
  <div>
    <Alert />
    <TemplateFeed>
      <v-card slot="left" height="100vh" class="pa-4 account-card" outlined>
        <Search class="pb-6 px-2 pt-2" @input="val => { this.searchQuery = val; }" />
        <FilterPanel class="scroll-left-lg-y scroll-left-md-y px-2" type="accounts" :filterAccounts="filterPanel" />
      </v-card>

      <div class="px-2 mx-0 pt-4 mb-4" slot="top" :style="[$vuetify.theme.dark ? { 'background-color': '#1E1E1E' } : { 'background-color': '#ffffff' }]">
        <v-row no-gutters class="mb-4">
          <v-col v-for="n in icons" :key="n" cols="12" sm="3">
            <v-card outlined height="48px" class="justify-center px-1 mx-1 d-flex flex-column">
              <v-row>
                <v-col cols="1" class="mr-2 pb-3">
                  <v-avatar color="primary" class="white--text my-3 mx-1" rounded size="32">
                    <v-icon small color="white">{{ n.name }}</v-icon>
                  </v-avatar>
                </v-col>
                <v-col class="ml-2">
                  <h5 class="su-2 mt-2">{{ n.text }} - {{ accounts.length }}</h5>
                  <span class="caption grey--text mb-3">Latest account created on Feb 17, 2020 11:39:22</span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div slot="right" class="mx-3 mb-16 account_table">
        <v-data-table
          class="general_bckgr"
          :headers="accountHeaders"
          :search="searchQuery"
          :items="accounts"
          :items-per-page="accountRowsNumber"
          item-key="account_uuid"
          :item-class="itemTextColor"
          show-select
          :page.sync="currentPage"
          v-model="selected"
          hide-default-footer
          :footer-props="footerProps"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div class="inline pl-3 ml-0" style="width: 10px;">
              {{ accounts.indexOf(item) + 1 }}
            </div>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <div class="inline">
              <span class="green circle pr-2 mr-2" v-if="item.online"></span>
              <span class="red circle pr-2 mr-2" v-else></span>
              <span :class="{ 'ml-1': item.online }">{{ item.email }}</span>
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span>{{ item.status[0].toUpperCase() + item.status.slice(1) }}</span>
          </template>
          <template v-slot:[`item.invited_on`]="{ item }">
            <span>{{ beautifyDate(item.invited_on, "MMM dd, yyyy") }}</span>
          </template>
          <template v-slot:[`item.role`]="{ item }">
            <v-chip
              small
              :class="{
                adminChip: role.name === 'Admin',
                contentChip: role.name === 'Content Manager',
                viewerChip: role.name === 'Viewer',
              }"
              v-for="role in item.roles"
              label
              :color="role.name === 'Admin' ? '#09368E' : role.name === 'Content Manager' ? '#8ED5FD' : '#B2D1FF'"
              :key="role.uuid"
            >
              {{ role.name }}
            </v-chip>
          </template>
          <template v-slot:[`item.avatar`]="{ item }">
            <v-avatar color="primary" class="white--text" rounded size="32">
              {{
                item.display_name && item.display_name.includes('.')
                  ? item.display_name.split('.')[1][0].toUpperCase() + item.display_name.split('.')[0][0].toUpperCase()
                  : 'NA'
              }}
            </v-avatar>
          </template>
          <template v-slot:[`item.inactivity`]="{ item }">
            <span>{{ item.online ? 'Online now' : timeAgo(item.last_seen) }}</span>
          </template>

          <!-- Custom footer slot -->
          <template v-slot:footer>
          <div class="footer-container py-5">
            <div class="left-items ml-4">
              <v-select
                v-model="accountRowsNumber"
                :items="itemsPerPage"
                hide-details
                dense
                height="32px"
                outlined
                class="custom-select"
              ></v-select>
              <span class="spanItemCount">1-{{ accountRowsNumber }} of {{ totalAccountsNumber }}</span>
            </div>
            <v-pagination
              elevation="0"
              v-model="currentPage"
              :length="numberOfPages"
              :total-visible="10"
              class="pagination"
              :prev-icon="icon.arrowLeft"
              :next-icon="icon.arrowRight"
            ></v-pagination>
          </div>
        </template>

        </v-data-table>
      </div>
      <Search v-model="searchQuery" slot="top-search-accounts" class="pl-2" @input="val => { this.searchQuery = val; }" />
      <ExportBtn @btn-click="changeDialogCsv" slot="top-left-btn" />
      <v-progress-circular></v-progress-circular>
    </TemplateFeed>
    <FlyoutMenu />
    <v-dialog v-model="dialogCsv" max-width="500px">
      <v-card class="pa-4">
        <v-card-title class="d-flex justify-space-between">
          <span>Export {{ this.$route.name }}</span>
          <v-btn icon @click="dialogCsv = false">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-card-subtitle class="font-weight-bold pl-0">Format</v-card-subtitle>
          <div class="div_bdr">
            <v-radio-group v-model="exportFormat" column class="pl-4">
              <v-radio label="CSV" value="csv"></v-radio>
              <v-radio label="HTML" value="html"></v-radio>
            </v-radio-group>
          </div>
          <v-card-subtitle class="font-weight-bold pl-0">Pages</v-card-subtitle>
          <div class="div_bdr">
            <v-radio-group v-model="exportScope" column class="pl-4">
              <v-radio :label="currentRadioLabel" value="current"></v-radio>
              <v-radio label="All Pages" value="all"></v-radio>
            </v-radio-group>
          </div>
          <div class="d-flex justify-end pt-10">
            <v-btn class="text-none mr-5" elevation="0" color="#F5F5F5" @click="dialogCsv = false">
              <span class="px-4">Cancel</span>
            </v-btn>
            <v-btn class="text-none" elevation="0" color="primary" @click="submitExport">
              <span class="px-4">Export</span>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <Screenshots />
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import Search from "../components/Search";
import Tags from "@/services/api/Tags";
import FlyoutMenu from "../components/FlyoutMenu";
import Alert from "../components/Alert";
import Screenshots from "../components/Screenshots";
import TemplateFeed from "@/layouts/TemplateFeed";
import FilterPanel from "@/components/FilterPanel";
import { format, formatDistanceToNow } from 'date-fns';
import { mdiAccountMultiple, mdiSecurity, mdiEarth, mdiEyeOffOutline, mdiArrowRight, mdiArrowLeft } from '@mdi/js';
import ExportBtn from '../components/ExportBtn.vue';
import Accounts from '../services/api/Accounts';
export default {
  metaInfo: {
    title: "Accounts",
    meta: [
      {
        name: "description",
        content: "Accounts"
      }
    ]
  },
  name: "Devices",
  components: {
    Search,
    FlyoutMenu,
    Alert,
    Screenshots,
    TemplateFeed,
    FilterPanel,
    ExportBtn
  },
  computed: {
    ...mapGetters({
      accountsGetter: "accounts/getAccounts",
      totalAccountsNumber: "accounts/getTotalAccountsNumber",
      filterCheckGetter: "accounts/getFilterCheck",
      getPagesNumber: "accounts/getPagesNumber",
      accountsPerPage: "accounts/getNumberOfAccountsPerPage",
      currentPage: "accounts/currentPage"
    }),
    currentRadioLabel() {
      return `Current Page (Page-${this.currentPage})`;
      },
    /**
      // latestAccount: function() {
    //   let sorted = this//.accounts.sort((a, b) => {
    //     return new Date(b['invited_on']) - new Date(a['invited_on'])
    //   })
    //   return moment(sorted[0]).format("MMM.DD, YYYY HH:mm:ss")
    // },
    //check this later  {{ accounts[0].account_uuid}}
     */
    accounts() {
      return this.accountsGetter;
    },
    adminCount() {
      return this.accounts.filter(account => account.roles[0].name === 'Admin').length;
    },
    contentManagerCount() {
      return this.accounts.filter(account => account.roles[0].name === 'Content Manager').length;
    },
    numberOfPages(){
      return this.getPagesNumber;
    },
    viewerCount() {
      return this.accounts.filter(account => account.roles[0].name === 'Viewer').length;
    },
    filterChecker(){
      return this.filterCheckGetter;
   },
  },
  data() {
    return {
      accountRowsNumber: 10,
      currentPage: 1,
      dialogCsv: false,
      itemsPerPage: [
        { text: '5 Rows', value: 5 },
        { text: '10 Rows', value: 10 },
        { text: '15 Rows', value: 15 },
        { text: '20 Rows', value: 20 },
      
      ],
      exportScope: 'current', // Default selection for scope
      exportFormat: 'csv', // Default selection for format
      timer: null,
      loadingAccounts: false,
      searchQuery: "",
      loader: false,
      selected: [],
      accountHeaders: [
          {
            class: "pl-3 ml-3 textColor--text",
            text: '#',
            sortable: false,
            value: 'id',
          },
          { text: '', 
            value: 'data-table-select',
            class: "color--primary",
          },
          {
            value: "avatar",
            class: "textColor--text"
          },
          {
            class: "textColor--text",
            text: 'Email',
            value: 'email'
          },
          {
            class: "textColor--text",
            text: 'Display Name',
            value: 'display_name'
          },
          {
            class: "textColor--text",
            text: 'Added on',
            value: 'invited_on'
          },
          {
            class: "textColor--text",
            text: 'Status',
            value: 'status'
          },
          {
            class: "textColor--text",
            text: 'Role',
            value: 'role',
          },
          {
            class: "textColor--text",
            text: 'Inactivity',
            value: 'inactivity'
          }
        ],
      filterPanel: {
        accountStatus: [
          {
            text: "Invited",
            value: "invited"
          },
          {
            text: "Active",
            value: "active"
          },
          {
            text: "Disabled",
            value: "disabled"
          }
        ],
        role : [
          {
            text: "Admin",
            value: "bf09318e-039a-495c-83fb-cbd85fd41ace"
          },
          {
            text: "Content Manager",
            value: "c5b3f753-919d-477f-a397-4cb7a5c0358"
          },
          {
            text: "Viewer",
            value: "dbf971df-fcb6-4f4f-be8a-38321d903b18"
          }
        ],
        online : [
          {
            text: "Yes",
            value: "True"
          },
          {
            text: "No",
            value: "False"
          }
        ],
        "Tags": [],

      },
      icons: [
        { 
          text: "Total Accounts",
          subtext: "Latest account created on",
          name: mdiAccountMultiple },
        { 
          text: "Admins",
          subtext: "of total accounts",
          name: mdiSecurity 
          
        },
        { 
          text:"Online Accounts",

          name: mdiEarth 
        },
        { 
          text:"Disabled Accounts",
          name: mdiEyeOffOutline 
        },

      ],
      icon:{
        arrowRight: mdiArrowRight,
        arrowLeft: mdiArrowLeft,
      }
    };
  },
  watch: {
    filterChecker: {
      handler(val) {
        clearTimeout(this.filterTimer);
        this.filterTimer = setTimeout(() => {
          this.submitFilterData(val);
        }, 500);
      },
      deep: true
    },
    searchQuery() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.runSearch();
      }, 400);
    },
    currentPage:{
    handler(val) {
      this.$store.commit("accounts/SET_PAGE", val)
      },
      deep: true
    },
    accountRowsNumber:{
      handler(val) {
        this.$store.commit("accounts/SET_ACCOUNTS_NUMBER", val)
        this.currentPage = 1;
        },
        deep: true
    },

  },
  mounted() {
    if (this.accountsGetter.length === 0) {
      this.getAllAccounts();
    }

  },
  methods: {
    ...mapActions({
      getAccounts: "accounts/getAccounts",
      }),
    submitExport() {
      // Call the method to prepare the data and trigger the export
      this.prepareExportData();

      // Close the dialog after triggering the export
      this.dialogCsv = false;
    },
    exportAccounts(queryString) {
    // Calling the API to export accounts based on the queryString
    // Assuming Devices.exportDevices is already setup to handle Accounts export
    Accounts.exportAccounts(queryString).then(response => {
      // Extract the desired filename from the response or set a default
      const filename = 'accounts_export.' + (this.exportFormat === 'csv' ? 'csv' : 'html');

      // Create a Blob with the response data
      const blob = new Blob([response.data], { type: this.exportFormat === 'csv' ? 'text/csv' : 'text/html' });

      // Create a link and trigger the download
      if (window.navigator.msSaveOrOpenBlob) {
        // For IE and Edge
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // For other browsers
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link); // Required for Firefox
        link.click();
        document.body.removeChild(link); // Clean up
      }
    }).catch(error => {
      console.error('Export failed:', error);
      // Handle errors here, perhaps show a notification to the user
    });
  },

  // Method to open the dialog and set initial values
  changeDialogCsv() {
    // Resetting values to defaults or based on current settings
    this.dialogCsv = !this.dialogCsv;
    // No need to adjust exportScope based on view, as there's only one view
    this.exportFormat = 'csv'; // Defaulting to CSV, adjust as needed
  },

  // This method prepares and triggers the data export
  prepareExportData() {
        // Clone the filter state to avoid directly mutating the original state
        const exportData = { ...this.filterChecker };
        
        // If 'All Pages' is selected, remove 'page' and 'page_size' from the export data
        if (this.exportScope === 'all') {
          delete exportData.page;
          delete exportData.page_size;
        }
        
      exportData.export_format = this.exportFormat;
      
      // Convert the adjusted filter state to a query string
      const queryString = this.buildQueryString(exportData);
      
      // Perform the export with the prepared query string
      this.exportAccounts(queryString);
    },


  // Utility method to build query string from filter state
    buildQueryString(filterState) {
    const params = new URLSearchParams();
    Object.entries(filterState).forEach(([key, value]) => {
      // Handle null values explicitly
      if (value === null) return;
      
      // For arrays, add each value under the same key
      if (Array.isArray(value) && value.length > 0) {
        value.forEach(item => params.append(key, item));
      } else if (value !== '' && !Array.isArray(value)) {
        // For non-empty strings and non-array values
        params.append(key, value);
      }
      // Skip empty strings and empty arrays
    });
    return params.toString();
  },

    getTags() {
      Tags.get().then(response => {
        this.filterAccounts.tags = response;
      });
    },
    itemTextColor(item) {
      return item.status === 'Disabled' ? 'grey--text' : ''
    },
    beautifyDate(date, dateFormat) {
      return date ? format(new Date(date), dateFormat) : "Invalid date"
    },
    timeAgo(date) {
      return date ? formatDistanceToNow(new Date(date), {
        addSuffix: true
      }) : ""
    },
    runSearch() {
      this.$store.commit("accounts/SET_PAGE", 1);
      this.$store.dispatch('accounts/setSearch', this.searchQuery);
      
    },
    getAllAccounts() {
      this.loadingAccounts = true;
      this.getAccounts().then(() => {
        this.loadingAccounts = false;
      });
    },

    submitFilterData(obj) {
      const params = [];

      for (const [key, value] of Object.entries(obj)) {
        if (value === null || typeof value === 'undefined' || (Array.isArray(value) && value.length === 0)) {
          continue; // Skip if value is invalid
        }

        if (key === 'online') {
          if (Array.isArray(value)) {
            if (value.length !== 1) {
              console.error(`Invalid value for "online" key: Multiple selections`);
              continue; // Skip invalid values
            }
            if (value[0] === 'True') {
              params.push(`online=True`);
            }else if(value[0] === 'False'){
              params.push(`online=False`);
            }
          } else {
            params.push(`online=${value === 'True' ? 'True' : 'False'}`);
          }
        } else {
          if (Array.isArray(value)) {
            params.push(`${encodeURIComponent(key)}=${value.map(val => encodeURIComponent(val)).join(",")}`);
          } else {
            params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
          }
        }
      }

  let query = params.join("&");
  this.$store.dispatch('accounts/setQuery', query).then(() => {
    this.getAllAccounts();
  });
}





  },
};
</script>

<style scoped lang="scss">
.custom-select,
.spanItemCount {
  margin: 0 8px 0 0;
}

.pagination {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateX(-120px);
}
.custom-select.v-select .v-select__slot {
  height: 32px;
  padding: 0px 8px;
}
.custom-select.v-input--dense .v-input__control .v-input__slot {
  padding-top: 0;
  padding-bottom: 0;
}
.circle {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
}
.v-select{
 background-color: var(--v-general_bckgr-base);
 
}
.green {
  background-color: #00a86b;
}

.spanItemCount{
  width: 61px;
  height: 14px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #A7A7A7;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}
.red {
  background-color: red;
}
.custom-select {
  width: 150px;
  font-size: 12px;
  min-height: 1em;
}

.adminChip{
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 21px;
color: var(--v-adminChipText-base);
}

.contentChip{
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 21px;
color: var(--v-contentChipText-base);
}

.viewerChip{
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 21px;
color: var(--v-viewerChipText-base);
}

.inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}


.short {
  height: 24px;
  flex-grow: 0;
  padding: 3px 8px;
  border-radius: 4px;
}

.account-card {
  border-radius: 0px;
  border-top: none;
}

.account_table {
  border: 1px solid #dedede !important;
  border-radius: 6px;
}
.div_bdr {
  border-radius: 4px;
  border:1px solid #E0E0E0;
}


.footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top: 1px solid #E0E0E0;
}

.left-items {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.custom-select,
.spanItemCount {
  margin-right: 8px;
}

.pagination {
  position: absolute;
  left: 60%;
  transform: translateX(-50%) translateX(-120px);
}

.custom-select.v-select .v-select__slot {
  height: 32px;
  padding: 0px 8px;
}

.custom-select.v-input--dense .v-input__control .v-input__slot {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
