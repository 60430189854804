import Group from "@/services/api/Groups";
import Vue from "vue";
const state = {
  groups: [],
  filterGroups: {
    page: 1,
    page_size: 36,
    ordering: '-created_at',
    search: null
  },
  sortAscendingGroup: [
    {
      text:'Time Added',
      value: '-created_at'
    },    
    {
      text: 'Active Status',
      value: '-active',
    }, 
    {
      text:'Time Modified',
      value:'-updated_at'
    },
  ],

  sortDescendingGroup: 
  [
    {
      text:'Time Added',
      value: 'created_at'
    },    
    {
      text: 'Active Status',
      value: 'active',
    }, 
    {
      text:'Time Modified',
      value:'updated_at'
    },
    ],
  group: {},
  links: {},
  loading: false,
  error: null,
};

const getters = {
  getGroups: (state) => state.groups,
  getGroup: (state) => state.group,
  getLinksGroup: (state) => state.links,
  getQueryParams: (state) => state.queryParams,
  isLoading: (state) => state.loading,
  getError: (state) => state.error,
  getFilterGroup: (state) => state.filterGroups,

};

const actions = {
  async fetchGroups({ commit, state }, append = false) {
    commit("setLoading", true);
    commit("setError", null);
    let obj = state.filterGroups;
    let queryParams = Object.keys(obj)
      .filter((k) => obj[k] !== null && obj[k] !== '')
      .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
    try {
      const query = new URLSearchParams(queryParams).toString();
      const response = await Group.get(query);
      if (append) {
        commit("appendGroups", response.data.location_groups);
      } else {
        commit("setGroups", response.data.location_groups);
      }
      commit("setLinks", response.data.links);
    } catch (error) {
      commit("setError", error);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchGroup({ commit }, uuid) {
    commit("setLoading", true);
    commit("setError", null);
    try {
      const response = await Group.getGroup(uuid);
      commit("setGroup", response.data);
    } catch (error) {
      commit("setError", error);
    } finally {
      commit("setLoading", false);
    }
  },
  async addGroup({ commit, dispatch }, group) {
    commit("setLoading", true);
    commit("setError", null);
    try {
      await Group.addGroup(group);
      dispatch("fetchGroups");
    } catch (error) {
      commit("setError", error);
    } finally {
      commit("setLoading", false);
    }
  },
  async editGroup({ commit }, { uuid, group }) {
    commit("setLoading", true);
    commit("setError", null);
    try {
      const response = await Group.editGroup(uuid, group);
      const updatedGroup = response.data;
      commit("UPDATE_GROUP_UUID", { uuid, data: updatedGroup });
      commit("setGroup", updatedGroup);
    } catch (error) {
      commit("setError", error);
    } finally {
      commit("setLoading", false);
    }
  },
  async deleteGroup({ commit, dispatch }, uuid) {
    commit("setLoading", true);
    commit("setError", null);
    try {
      await Group.deleteGroup(uuid);
      dispatch("fetchGroups");
    } catch (error) {
      commit("setError", error);
    } finally {
      commit("setLoading", false);
    }
  },
  setFilterOrdering({ commit, dispatch }, params) {
    commit("setFilterOrdering", params);
    commit("setPageNumber", 1);
    dispatch("fetchGroups");
  },
  setFilterSearch({ commit, dispatch }, params) {
    commit("setFilterSearch", params);
    commit("setPageNumber", 1);
    dispatch("fetchGroups");
  },
  setFilterPage({ commit, dispatch }, params) {
    commit("setPageNumber", params);
    dispatch("fetchGroups");
  },
};
const mutations = {
  setGroups(state, groups) {
    state.groups = groups;
  },
  setGroup(state, group) {
    state.group = group;
  },
  setLinks(state, links) {
    state.links = links;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
  setFilterOrdering(state, ordering){
    state.filterGroups.ordering = ordering;
  },
  setPageNumber(state, page){
    state.filterGroups.page = page;
  },
  setFilterSearch(state, search){
    state.filterGroups.search = search;
  },
  appendGroups(state, groups) {
    const uniqueGroups = groups.filter(group => 
      !state.groups.some(existingGroup => existingGroup.uuid === group.uuid)
    );
    state.groups = [...state.groups, ...uniqueGroups];
  },
  UPDATE_GROUP_UUID(state, { uuid, data }) {
    const index = state.groups.findIndex(group => group.uuid === uuid);
    if (index !== -1) {
      for (const [key, value] of Object.entries(data)) {
        state.groups[index][key] = value;
      }
      Vue.set(state.groups, index, state.groups[index]); // Ensure reactivity
    } else {
      console.warn(`Group with uuid ${uuid} not found in the state.`);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
