<template>
  <v-row
      v-if="device"
      class="fill-height">
    <v-col
        v-if="device.connected_status === 'connected'"
        md="8"
        class="pt-10 pr-4 content-height overflow-y-auto">
          <span class="body-text text-primary letter-spacing-normal">
            Any sort of description users desires to enter to describe this nice camera. If there is no description, this is empty.
			</span>
    </v-col>
    <v-divider
        class="d-none d-md-flex"
        vertical/>
    <v-col
        cols="12"
        sm="12"
        md="4"
        class="ml-n3 py-0 pr-0 content-height overflow-y-auto">
      <v-card
          height="72"
          class="px-4 pb-5 pt-3"
          flat>
        <v-card-text class="pa-0">
          <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
              $t('assets.devices.deviceInfo.info.deviceCategoryLabel')
            }}</span>
          <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
            {{ device.device_category_name }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card
          height="72"
          class="px-4 pb-5 pt-3"
          flat>
        <v-card-text class="pa-0">
          <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
              $t('assets.devices.deviceInfo.info.deviceTypeLabel')
            }}</span>
          <v-list-item-title class="subtitle-2 text-primary font-weight-regular">{{ device.device_type_name }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card
          height="72"
          class="px-4 pb-5 pt-3"
          flat>
        <v-card-text class="pa-0">
          <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
              $t('assets.devices.deviceInfo.info.connectionStatusLabel')
            }}</span>
          <v-chip
              small
              style="border-radius: 4px !important; height: 16px;"
              text-color="white"
              :color="device.online ? 'success' : 'error'">{{
              device.online ?
                  $t('assets.devices.deviceInfo.info.connectionStatusOnline') :
                  $t('assets.devices.deviceInfo.info.connectionStatusOffline')
            }}
          </v-chip>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card
          height="72"
          class="px-4 pb-5 pt-3"
          flat>
        <v-card-text class="pa-0">
          <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
              $t('assets.devices.deviceInfo.info.deviceStatusLabel')
            }}</span>
          <v-list-item-title class="subtitle-2 text-primary font-weight-regular">{{
              device.enabled ?
                  $t('assets.devices.deviceInfo.info.deviceStatusEnabled') :
                  $t('assets.devices.deviceInfo.info.deviceStatusDisabled')
            }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card
          height="72"
          class="px-4 pb-5 pt-3"
          flat>
        <v-card-text class="pa-0">
          <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
              $t('assets.devices.deviceInfo.info.onlineStreakLabel')
            }}</span>
          <v-list-item-title class="subtitle-2 text-primary font-weight-regular">TBA</v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card
          height="72"
          class="px-4 pb-5 pt-3"
          flat>
        <v-card-text class="pa-0">
          <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
              $t('assets.devices.deviceInfo.info.locationLabel')
            }}</span>
          <v-list-item-title class="subtitle-2 text-primary font-weight-regular">{{ device.location_name }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card
          height="72"
          class="px-4 pb-5 pt-3"
          flat>
        <v-card-text class="pa-0">
          <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
              $t('assets.devices.deviceInfo.info.addedOnLabel')
            }}</span>
          <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
            {{ beautifyDate(device.created_at) }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card
          height="72"
          class="pl-4 pr-0 pb-5 pt-3"
          flat>
        <v-card-text class="pa-0">
          <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
              $t('assets.devices.deviceInfo.info.uuidLabel')
            }}</span>
          <v-list-item-title class="subtitle-2 text-primary font-weight-regular">{{ device.uuid }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
    </v-col>
  </v-row>
</template>
<script>
import { format } from 'date-fns'
export default {
  props: ["device"],
  methods: {
    beautifyInstallationCode(value) {
      return value.match(/.{1,4}/g).join('-');
    },
    beautifyDate(date) {
      return date ? format(new Date(date), 'dd MMM yyyy HH:mm:ss') : "";
    },
  }
}
</script>