import axiosInstance from "./instance";

export default {
  get(page = 1, pageSize = 35, search = '') {
    return axiosInstance.get(`/application/items/?page=${page}&page_size=${pageSize}&name=${search}`);
  },
    getApplication(data) {
      return axiosInstance.get(`/application/${data}/`);
    },
    updateApplication(data, object){
      return axiosInstance.patch(`/application/${data}/`, object);
    },
    addApplication(object){
      return axiosInstance.post(`/application/add/`, object);
    },
    getRunners(page = 1, pageSize = 35, search=""){
      return axiosInstance.get(`/runner/items/?page=${page}&page_size=${pageSize}&search=${search}`);
    },
    addRunner(object){
      return axiosInstance.post(`/runner/add/`, object);
    },
    getRunner(data){
      return axiosInstance.get(`/runner/${data}`)
    }
  }