<template>
    <v-btn outlined class="btn_style" @click="emitClick">
      <v-icon size="16">{{ icons.mdiTrayArrowDown }}</v-icon>
      <span class="pl-2">Export</span>
    </v-btn>
  </template>
  
  <script>
  import { mdiArrowCollapseDown } from '@mdi/js'
  export default {
      data(){
          return{
              icons:{
                  mdiTrayArrowDown: mdiArrowCollapseDown
              }
          }
      },
      methods: {
          emitClick() {
              this.$emit('btn-click');
          }
      }
  }
  </script>
  

<style>
.btn_style{
    border-color:var(--v-lineColor-base);
    background-color: var(--v-lineColor-base);
    border-radius: 5px;
    font-weight: 450;

}
</style>