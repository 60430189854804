import axiosInstance from './instance';
import * as querystring from "querystring";


export default {
  register(uuid, payload) {
    return axiosInstance.patch(`/space-account/${uuid}/account-registration/`, payload)
  },
  fetchSpaceName(uuid, token) {
    return axiosInstance.get(`/space-account/${uuid}/token/${token}`)
  },
  spaceJoin(uuid, data) {
    return axiosInstance.patch(`/space-account/${uuid}/space-join/`, data);
  },
  passwordUpdate(email){
    return axiosInstance.post(`/account/password-update/ `,  {'email': email});
  },
  passwordReset(email){
    return axiosInstance.post(`/account/password-reset/ `,  {'email': email});
  },
  passwordKeyloakUpdate(token, password, account_uuid){
    return axiosInstance.patch(`/account/${account_uuid}/keycloak-password-update/`, { 'token': token, 'password': password})
  },
  passwordKeyloakReset(token, password, account_uuid){
    return axiosInstance.patch(`/account/${account_uuid}/keycloak-password-reset/`, { 'token': token, 'password': password})
  },
  loginUser(email_address, password) {
    let payload = {
      "client_id": process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
      "grant_type": "password",
      "scope": "openid",
      "username": email_address,
      "password": password
    }
    let encodedPayload = querystring.stringify(payload)
    return axiosInstance.post(`${process.env["VUE_APP_KEYCLOAK_URL"]}/auth/realms/device-management/protocol/openid-connect/token`, encodedPayload, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    })
  },
  refreshToken(refresh_token) {
    let payload = {
      "client_id": process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
      "grant_type": "refresh_token",
      "refresh_token": refresh_token
    }
    let encodedPayload = querystring.stringify(payload)
    return axiosInstance.post(`${process.env["VUE_APP_KEYCLOAK_URL"]}/auth/realms/device-management/protocol/openid-connect/token`, encodedPayload, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    })
  }
  
}
