<template>
  <div>
    <v-card elevation="0" outlined class="mt-4 py-4 justify-space-between d-flex flex-row rounded-lg">
      <v-card-subtitle>{{ $t('assets.working_time.local') }}</v-card-subtitle>
      <div class="mx-0 pr-6 mt-2 align-center">
        <v-btn 
          elevation="0" 
          :color="buttonColor('global')" 
          class="btn_global text-capitalize px-10" 
          large 
          @click="setActive('global')"
        >{{ $t('assets.working_time.button.global') }}</v-btn>
        <v-btn 
          elevation="0"  
          :color="buttonColor('local')" 
          class="btn_local text-capitalize px-10" 
          large 
          @click="setActive('local')"
        >{{ $t('assets.working_time.button.local') }}</v-btn>
      </div>
    </v-card>
    <div class="scrollable-container">
      <v-col v-for="(times, day) in workingTimeValue" :key="day" cols="12" class="mb-0 px-0 pb-0 pt-1">
        <WorkingTimeItem :messageError="message" :data="times" :day="day" @update-entries="handleUpdate"/>
      </v-col>
    </div>
    <v-row class="text-right bottom-navigation-edit py-4 px-2"
           v-bind:style="[this.$vuetify.theme.dark ? {'background-color': '#1E1E1E'} : {'background-color':'#ffffff'}]">
      <v-col>
        <v-btn
          width="120"
          class="mr-2 font-weight-regular letter-spacing-normal text-capitalize"
          depressed
          text
          @click="closeForm()"
        >{{ $t("common.cancelBtn") }}</v-btn>
        <v-btn
          width="120"
          id="btn-blue-disabled"
          class="font-weight-normal letter-spacing-normal text-capitalize"
          color="primary"
          depressed
          @click="dispatchChanges()"
        >Save</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WorkingTimeItem from '../WorkingTime/WorkingTimeItem.vue';
import Locations from '../../services/api/Locations.js'
export default {
  components: {
    WorkingTimeItem,
  },
  props: ['viewMode'],
  data() {
    return {
      isDataChanged: false,
      allEntries: {},
      message: null
    };
  },
  mounted() {
    this.setActive(this.$route.params.tab);
  },
  methods: {
    ...mapActions({
      setGlobalWorkingTime: 'locations/setGlobalWorkingTime',
      setLocalWorkingTime: 'locations/setLocalWorkingTime',
      editLocalWorkingTime: 'locations/editWorkingTimeLocal',
      changeGlobalWorkingTime: 'locations/editWorkingTimeGlobal',
      addAlert: "global/addAlert",
    }),
    handleUpdate(payload) {
      this.allEntries[payload.day] = payload.entries; // Updates the specific day's entries
    },
    checkOverlap(timeIntervals) {
      let overlaps = [];
      if (!Array.isArray(timeIntervals) || timeIntervals.length < 2) return overlaps;

      // Ensure timeIntervals is an array and sort it
      timeIntervals = Array.isArray(timeIntervals) ? timeIntervals : Array.from(timeIntervals);
      timeIntervals.sort((a, b) => this.timeToMinutes(a.start) - this.timeToMinutes(b.start));

      for (let i = 0; i < timeIntervals.length - 1; i++) {
        if (this.timeToMinutes(timeIntervals[i].end) > this.timeToMinutes(timeIntervals[i + 1].start)) {
          overlaps.push(i, i + 1);
        }
      }
      return [...new Set(overlaps)]; // Remove duplicates
    },
    validateWorkingTimes(workingTimes) {
      const errors = {};
      for (const day in workingTimes) {
        const overlapIndices = this.checkOverlap(workingTimes[day]);
        if (overlapIndices.length > 0) {
          errors[day] = overlapIndices.reduce((acc, index) => {
            acc[index] = 'Overlapping intervals detected';
            return acc;
          }, {});
        }
      }
      return errors;
    },
    closeForm(){
      this.$router.push('/locations');
    },
    //handles changes
    handleDataChanged(changed) {
      this.isDataChanged = changed;
    },
    //dispatches the changes
    dispatchChanges() {
      if (this.$route.params.tab === 'local') {
        const fullPayload = {
          ...this.getLocalWorkingTime,
             global_working_time_enabled: false,  // Set the parameter to false
          ...this.allEntries
        };
        const validationErrors = this.validateWorkingTimes(fullPayload);
              if (Object.keys(validationErrors).length > 0) {
                this.message = validationErrors; // Set the message to be passed to child components
                console.error('Validation errors:', validationErrors);
                this.addAlert({
                        type: "error",
                        message: "Overlap detected in schedules",
                      });
              } else {
                Locations.editWorkingTimeLocal(this.$route.params.uuid, fullPayload).then(() => {
                  this.addAlert({
                        type: "success",
                        message: "Working time successfully updated.",
                      });
                  });
          }
      } else {
        const fullPayload = {
      ...this.getGlobalWorkingTime,
      ...this.allEntries
    };
    const validationErrors = this.validateWorkingTimes(fullPayload);
    if (Object.keys(validationErrors).length > 0) {
      this.message = validationErrors; // Set the message to be passed to child components
      console.error('Validation errors:', validationErrors);
      this.addAlert({
              type: "error",
              message: "Overlap detected in schedules",
            });
      } else {
        this.changeGlobalWorkingTime(fullPayload).then(response => {
          this.$store.commit("SET_GLOBAL_WORKING_TIME", response);
          this.addAlert({
                type: "success",
                message: "Working time successfully updated.",
              });
        });
      }
      }
    },
    //controls the routes
    setActive(tab) {
      this.fetchWorkingTimeData(tab);
      this.updateRoute(tab);
    },
    //checks if route is correct
    fetchWorkingTimeData(tab) {
      if (tab === 'global') {
        this.setGlobalWorkingTime();
      } else {
        let uuid = this.$route.params.uuid;
        this.setLocalWorkingTime(uuid);
      }
    },
    updateRoute(tab) {
      if (tab !== this.$route.params.tab) {
        this.$router.push({ name: 'location', params: { ...this.$route.params, tab: tab } }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    },
    buttonColor(button) {
        return this.$route.params.tab === button ? 'primary' : 'secondary';
      },
      timeToMinutes(time) {
      const [hours, minutes, seconds] = time.split(':').map(Number);
      return hours * 60 + minutes + seconds / 60;
    }
  },
  computed: {
    ...mapGetters({
      getGlobalWorkingTime: 'locations/globalWorkingTime',
      getLocalWorkingTime: 'locations/localWorkingTime'
    }),
    workingTimeValue() {
      return this.$route.params.tab === 'global' ? this.getGlobalWorkingTime : this.localWorkingTime;
    },
    localWorkingTime() {
      let data = { ...this.getLocalWorkingTime };
      delete data.global_working_time_enabled;
      return data;
    }
  },
 
}
</script>

  
  <style scoped>
  .btn_global {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .btn_local {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .bottom-navigation-edit {
    position: absolute;
    bottom: 0 !important;
    left: 0;
    right: 0;
    margin: 0;
    box-shadow: 0 -4px 25px 0 rgba(0, 0, 0, 0.13);
  }
  .scrollable-container {
    max-height: calc(82vh - 140px); /* or whatever maximum height you want */
    overflow-y: auto;  /* make it scrollable when content overflows */
    padding-bottom: 50px;
    }
  .bottom-alert {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
  }
 
  </style>
  