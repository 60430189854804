<template>
  <div>
    <v-row>
        <v-col md="8">
            <br>
              <span class="Associated-Devices"> Locations </span>
            <br>
      <v-row class="px-2 mt-5">
        <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
            class="px-2 pb-4 pt-0"
            v-bind:key="item.uuid"
            v-for="item in group.locations">
            <GroupLocations :data="item"/>
        </v-col>
      </v-row>
        </v-col>
        <v-divider
          class="d-none d-md-flex"
          vertical style="height: 100vh"/>
        <v-col class="pl-0" md="4">
            <v-card
            height="72"
            class="px-4 pb-5 pt-3 "
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
                $t('assets.groups.info.created_at_label')
              }}</span>
            <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
              {{ group.created_at }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider/>
        <v-card
            height="72"
            class="px-4 pb-5 pt-3 "
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
                $t('assets.groups.info.uuid_label')
              }}</span>
            <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
              {{ group.uuid }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider/>
        <v-card
            height="72"
            class="px-4 pb-5 pt-3 "
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">{{
                $t('assets.groups.info.number_of_group_locations')
              }}</span>
            <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
              {{ group.locations_number }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider/>
        </v-col>
    </v-row>
  </div>
</template>
<script>
import GroupLocations from '../GroupLocations.vue'
export default {
components: {
  GroupLocations
},
props:["group"],

}
</script>

<style>
.Associated-Devices {
  width: 608px;
  height: 19px;
  margin: 0 0 24px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--v-secondaryText-base);
}
</style>