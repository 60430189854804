var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"no-click-animation":"","scrollable":"","overlay-color":_vm.isDarkTheme ? '#424242': 'overlaycolor',"overlay-opacity":"0.3","transition":"slide-x-reverse-transition","max-width":"1080","persistent":""},on:{"click:outside":function($event){return _vm.closeDialog()}},model:{value:(_vm.isShown),callback:function ($$v) {_vm.isShown=$$v},expression:"isShown"}},[_c('v-card',{staticClass:"mr-sm-15 overflow-md-y-hidden overflow-x-hidden px-4 px-md-12 pt-8 pb-0",attrs:{"flat":"","tile":""}},[(_vm.$route.params.action === 'addDeviceRunner')?_c('AddDeviceRunner'):_vm._e(),(_vm.$route.params.action === 'detectDevices')?_c('DetectDevices'):(_vm.$route.params.action === 'addLocation')?_c('AddLocation'):_vm._e(),(_vm.$route.params.action === 'inviteUser')?_c('InviteUser'):(
        _vm.$route.params.location_type === 'location' &&
        _vm.$route.params.uuid &&
        _vm.$route.params.action === 'edit'
      )?_c('LocationInfoEdit'):(_vm.$route.params.location_type === 'location' && _vm.$route.params.uuid)?_c('LocationInfo',{attrs:{"type":"location"}}):(
        (_vm.$route.params.device_type === 'device' &&
        _vm.$route.params.action === 'edit' &&
        _vm.$route.params.uuid) || (_vm.$route.params.monitoring_type === 'device' && _vm.$route.params.action === 'edit' && _vm.$route.params.uuid)
      )?_c('DeviceInfoEdit'):(_vm.$route.params.device_type === 'device' && 
        _vm.$route.params.uuid || _vm.$route.name === 'device_location' && 
        _vm.type === 'device')?_c('DeviceInfo',{attrs:{"type":"device","channel":_vm.channel}}):(_vm.$route.params.device_type === 'camera' && 
        _vm.$route.params.uuid)?_c('CameraInfo',{attrs:{"type":"camera"}}):(_vm.$route.params.monitoring_type === 'device' && _vm.$route.params.uuid)?_c('MonitoringInfo',{attrs:{"type":"device","channel":_vm.channel}}):(_vm.$route.params.action === 'edit' && this.$route.params.action === 'edit')?_c('edit-group'):(_vm.$route.params.action === 'addGroup')?_c('AddGroup'):(this.route === 'group')?_c('GroupInfo',{attrs:{"type":"group"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }