import {v4 as uuidv4} from 'uuid';


const state = {
  lang: localStorage.getItem('lang'),
  space: localStorage.getItem('current_space'),
  alerts: [],
  alert: {
    type: "",
    message: "",
    shown: false,
    constant: false,
    buttonLink: null,
  },
  loading: false,
};

const getters = {
  getLang(state) {
    return state.lang
  },
  getSpace(state) {
    return state.space
  },
  getAlerts(state) {
    return state.alerts
  },
  getLoading(state){
    return state.loading;
  }
};

const actions = {
  updateLanguage({commit}, value) {
    commit('SET_LANG', value);
  },
  addAlert({commit}, data) {
    data.uuid = uuidv4();
    commit('ADD_ALERT', data);
  },
  removeAlert({commit}, uuid) {
    let alertIndex = state.alerts.findIndex((alert) => alert.uuid === uuid)
    commit('REMOVE_ALERT', alertIndex)
  },
  setSpace({commit}, uuid) {
    localStorage.setItem('current_space', uuid);
    commit('SET_SPACE', uuid);
  }
};

const mutations = {
  SET_LANG(state, lang) {
    state.lang = lang;
  },
  SET_SPACE(state, uuid) {
    state.space = uuid;
  },
  ADD_ALERT(state, payload) {
    state.alerts.unshift(payload);
  },
  REMOVE_ALERT(state, i) {
    state.alerts.splice(i, 1)
  },
  SET_LOADING(state, value) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
