import Accounts from "@/services/api/Accounts";

const state = {
  roles: [],
  user: {},
  accounts: [],
  totalAccountsNumber: null,
  accountModal: false,
  space: null,
  filterAccounts: {
    search: "",
    status: [],
    online: null,
    role_uuid: [],
    tags: [],
    page: 1,
    page_size: 10
  },
  totalPages: null,
  query:"page_size=10&page=1"
};

const getters = {
  currentPage: state => state.filterAccounts.page,
  getRoles(state) {
    return state.roles;
  },
  accountModal(state) {
    return state.accountModal
  },
  getFilterCheck(state){
    return state.filterAccounts;
  },
  getAccounts(state) {
    return state.accounts
  },
  getUser(state) {
    return state.user
  },
  getNumberOfAccountsPerPage(state){
    return state.filterAccounts.page_size;
  },
  getTotalAccountsNumber(state){
    return state.totalAccountsNumber;
  },
  spaceName(state) {
    if (state.space) {
      return state.space;
    } else {
      const currentSpaceUUID = localStorage.getItem("current_space");
      const space = state.user.spaces?.find(
        (space) => space.space_uuid === currentSpaceUUID
      );
      return space ? space.space_name : "Unknown Space";
    }
  },
  getPagesNumber(state){
    return state.totalPages;
  }
}

const actions = {
  toggleAccountModal({commit}) {
    commit('TOGGLE_ACCOUNT_MODAL');
  },
  inviteUsers({commit}, data) {
    return new Promise((resolve, reject) => {
      
      Accounts.inviteUsers(data).then(response => {
        commit('STATE');
        commit('SET_LOADING', false);
        
        resolve(response);
      }).catch((error) => {
        
        reject(error);
      });
    });
  },
  getAccountByUuid(uuid) {  
    const account = state.accounts.find((account) => account.uuid === uuid)
    const index = state.accounts.findIndex((account) => account.uuid === uuid)
    return {account, index}
  },
  getUser({commit, dispatch}, email) {
  return new Promise((resolve, reject) => {
    Accounts.getUser(email).then(response => {
      commit('SET_USER', response.data);
      const currentSpace = localStorage.getItem("current_space");
      if (!currentSpace || !response.data.spaces.some((space => space.space_uuid === currentSpace))) {
        console.log("no space detected, setting space");
        const defaultSpaceUuid = response.data.spaces[0].space_uuid;
        dispatch('global/setSpace', defaultSpaceUuid, {root: true})
        Accounts.loginSpaceUser(response.data.spaces[0].account_uuid).then(() => {
          resolve(response);
        }).catch(error => {
          console.log(error)
          reject(error);
        })
      } else {
        Accounts.loginSpaceUser(response.data.spaces[0].account_uuid).then(() => {
          resolve(response);
        }).catch(error => {
          console.log(error)
          reject(error);
        })
      }
    }).catch((error) => {
      reject(error);
    })
  })
},
  getAccounts({commit}) {
    return new Promise((resolve, reject) => {
      Accounts.getAccounts(state.query).then(response => {
        commit('SET_ACCOUNTS', response.data.accounts);
        commit('SET_TOTAL_ACCOUNTS_NUMBER', response.data.objects_count);
        console.log(response)
        commit('SET_PAGES_NUMBER', response.data.pages_count)
        resolve(response);
      }).catch((error) => {
        
        reject(error);
      })
    })
  },
  setUser({commit}, data) {
    commit('SET_USER', data);
  },
  setQuery({commit}, data){
    commit('SET_QUERY', data);
  },
  getRoles({commit}) {
    return new Promise((resolve, reject) => {
      
      Accounts.getRoles().then(response => {
        
        commit('SET_ROLES', response.data.roles)
        resolve(response);
      }).catch(error => {
        reject(error);
        
      });
    });
  },
  setSearch({commit}, data){
    commit('SET_SEARCH', data);
  },
  setCheckAccountStatus({commit}, data){
    commit("SET_CHECK_ACCOUNT_STATUS", data)
  },
  setCheckRole({commit}, data){
    commit("SET_CHECK_ROLE", data)
  },
  setCheckOnline({commit}, data){
    commit("SET_CHECK_ONLINE", data)
  },
  setCheckAccountTags({commit}, data){
    commit("SET_CHECK_ACCOUNT_TAGS", data)
  },
  setPageSize({commit}, data){
    commit("SET_PAGE_SIZE", data)
  },
  setPage({commit}, data){
    commit("SET_PAGE", data)
  }
 
};
const mutations = {
  SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  TOGGLE_ACCOUNT_MODAL(state) {
    state.accountModal = !state.accountModal
  },
  SET_ACCOUNTS(state, accounts) {
    state.accounts = accounts;
  },
  SET_CHECK_ACCOUNT_STATUS(state, payload){
    localStorage.setItem("checkAccountStatus", JSON.stringify(payload));
    state.filterAccounts.status = payload;
  },
  SET_CHECK_ROLE(state, payload){
    localStorage.setItem("checkRole", JSON.stringify(payload));
    state.filterAccounts.role_uuid = payload;
  },
  SET_CHECK_ONLINE(state, payload){
    localStorage.setItem("checkOnline", JSON.stringify(payload));
    state.filterAccounts.online = payload;
  },
  SET_PAGE_SIZE(state, payload){
    state.filterAccounts.page_size = payload;
  },
  SET_CHECK_ACCOUNT_TAGS(state, payload){
    localStorage.setItem("checkAccountTags", JSON.stringify(payload));
    state.filterAccounts.tags = payload;
  },
  SET_PAGE(state, payload){
    state.filterAccounts.page = payload;
  },
  SET_QUERY(state, payload){
    state.query = payload;
  },
  SET_PAGES_NUMBER(state, payload){
    state.totalPages = payload;
  },
  SET_ACCOUNTS_NUMBER(state, payload){
    state.filterAccounts.page_size = payload;
  },
  SET_TOTAL_ACCOUNTS_NUMBER(state, payload){
    state.totalAccountsNumber = payload;
  },
  SET_SEARCH(state, data){
    state.filterAccounts.search = data;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
