import axiosInstance from './instance';
import store from "@/store";

export default {
  get(uuid, value) {
    return axiosInstance.get(`/space/${uuid || store.getters["global/getSpace"]}/location/items/?${value}`)
  },
  getList() {
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/location/items/`)
  },
  getTypes() {
    return axiosInstance.get(`/location-type/items/`)
  },
  getLocationTypes(uuid){
    return axiosInstance.get(`/space/${uuid || store.getters["global/getSpace"]}/location-type/items/`)
  },
  getCountries() {
    return axiosInstance.get(`/country/items/`)
  },
  getCountryBySpace(uuid){
    return axiosInstance.get(`/space/${uuid || store.getters["global/getSpace"]}/location/country/items/`)
  },
  add(uuid, payload) {
    return axiosInstance.post(`/space/${uuid || store.getters["global/getSpace"]}/location/add/`, payload)
  },
  search(query) {
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/location/items/?name=${query}`)
  },
  getLocation(uuid) {
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/location/${uuid}`)
  },
  getDeviceByLocation(space_uuid, location_uuid) {
    return axiosInstance.get(`/space/${space_uuid}/location/${location_uuid}/asset/items/?ordering=-created_at`)
  },
  editLocation(space_uuid, uuid, payload) {
    return axiosInstance.patch(`/space/${space_uuid}/location/${uuid}/`, payload)
  },
  findPlace(string) {
    return axiosInstance.get(`https://maps.googleapis.com/maps/api/place/textsearch/json?query=${string}&key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`)
  },
  detectDevices(location_uuid) {
    return axiosInstance.post(`/space/${store.getters["global/getSpace"]}/location/${location_uuid}/devices-detection/`)
  },
  getDetectDevices(location_uuid) {
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/location/${location_uuid}/devices-detection/items/`)
  },
  getCameraItems(uuid) {
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/location/${uuid}/camera/items`)
  },
  deleteLocation(space_uuid, uuid) {
    return axiosInstance.delete(`/space/${space_uuid}/location/${uuid}/`)
  },
  getLocationTags(uuid, tag_uuid){
    return axiosInstance.get(`/space/${uuid | store.getters["global/getSpace"]}/location/items/tag_uuid=${tag_uuid}`)
  },
  exportLocations(value){
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/location/items/export/?${value}`)
  },
  getWorkingTimeGlobal(){
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/working-time/`)
  },
  editWorkingTimeGlobal(value){
    return axiosInstance.patch(`/space/${store.getters["global/getSpace"]}/working-time/`, value)
  },
  getWorkingTimeLocal(location_uuid){
    return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/location/${location_uuid}/working-time/`)
  },
  editWorkingTimeLocal(location_uuid, value){
    return axiosInstance.patch(`/space/${store.getters["global/getSpace"]}/location/${location_uuid}/working-time/`, value)
  },
  
}
