<template>
  <div v-if="activePeripheral">
    <v-card
        v-if="activePeripheral.manufacturer !== ''"
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Manufacturer</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ activePeripheral.manufacturer }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider v-if="activePeripheral.manufacturer !== ''"/>
    <v-card
        v-if="activePeripheral.model !== ''"
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Model</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ activePeripheral.model }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider v-if="activePeripheral.model !== ''"/>
    <v-card
        v-if="activePeripheral.communication_protocol !== ''"
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Communication Protocol</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ activePeripheral.communication_protocol.toUpperCase() }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider  v-if="activePeripheral.communication_protocol !== ''"/>
    <v-card
        v-if="activePeripheral.currency !== ''"
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Currency</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ activePeripheral.currency }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider v-if="activePeripheral.currency !== ''"/>
    <v-card
        v-if="activePeripheral.serial_number !== ''"
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Serial Number</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ activePeripheral.serial_number }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider v-if="activePeripheral.serial_number !== ''"/>
    <v-card
        v-if="activePeripheral.firmware !== ''"
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Firmware</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ activePeripheral.firmware }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
  </div>
  <div v-else-if="device.displays || activePeripheral==='undefined'">
    <v-card
        height="72"
        class="px-4 pb-5 pt-3 "

        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Peripherals</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ device.displays.length }} displays, {{ device.peripherals.length }} misc
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider/>
    <v-card
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Primary Display</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ device.displays.find(display => display.primary === true).name }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider/>
    <v-card
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Configured Apps</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ totalApplications }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider/>
    <v-card
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Disabled Displays</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ getDisabledDisplays(device) }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider/>
    <v-card
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Disabled Misc</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ getDisabledMisc(device) }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "PeripheralInfo",
  props: ['device'],
  data: function () {
    return {
       peripheral: this.activePeripheral
    }
  },
  computed: {
    ...mapGetters({activePeripheral: "devices/activePeripheral"}),
    cardReader(){
      return this.activePeripheral.device_type_name;
    },
    totalApplications() {
      // Check if there are displays and calculate the total number of applications
      if (this.device.displays?.length > 1) {
        return this.device.displays.reduce((total, display) => {
          return total + (display.applications ? display.applications.length : 0);
        }, 0);
      } else if (this.device.displays?.length === 1) {
        return this.device.displays[0].applications.length;
      }
      return 0; // Default return if no displays are found
    }
  },
  mounted(){
    this.getDisabledDisplays(); 
    this.getDisabledMisc(); 
  },
  methods: {
    getDisabledDisplays(device) {
      console.log(device);
      return device.displays.filter(display => display.disabled === true).map(display => display.name).join(", ") || "None";
    },
    getDisabledMisc(device) {
      return device.peripherals.filter(peripheral => peripheral.enabled === false).map(peripheral => peripheral.name).join(", ") || "None";
    }
  }
}
</script>

<style scoped>

</style>