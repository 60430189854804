<template>
    <div class="json-editor-container">
      <v-card class="pa-4" height="90vh" elevation="0" outlined>
        <div class="d-flex justify-space-between">
          <v-card-title class="flex-grow-1">{{ editorData.name }}</v-card-title>
        </div>
        <vue-json-editor
          v-model="editorData"
          mode="code"
          @change="handleDataChange"
          :expanded-on-start="true"
        ></vue-json-editor>
        <v-card-actions class="ml-0 pl-0 mt-0 pt-6">
          <v-spacer></v-spacer>
            <v-btn width="120" elevation="0" color="text-capitalize gray" @click="cancelModal">Cancel</v-btn>
            <v-btn width="120" disabled elevation="0" color="text-capitalize primary" @click="updateApplication">Save</v-btn>
          </v-card-actions>
      </v-card>
    </div>
  </template>
  
  <script>
  import VueJsonEditor from 'vue-json-editor';
  import { mapActions, mapGetters } from 'vuex';

  
  export default {
    name: "RunnerPreview",
    components: { VueJsonEditor },
    props: ['runnerId'],
    data() {
      return {
        editorData: {}, // This will be bound to the JSON editor
        initialApplicationData: {},
        changesOnApp: {},
        currentPage: 1,
        pageSize: 35,
      };
    },
    computed: {
      ...mapGetters('runners', ['getCurrentRunner']),
    },
    watch: {
      runnerId: {
        immediate: true,
        handler(newVal) {
          this.fetchRunnersData(newVal);
        }
      },
      getCurrentRunner: {
        immediate: true,
        handler(newVal) {
          if (newVal) {
            this.editorData = JSON.parse(JSON.stringify(newVal));
            this.initialApplicationData = JSON.parse(JSON.stringify(newVal));
          }
        }
      }
    },
    methods: {
      ...mapActions('runners', ['getRunner', 'setRunners']),
      fetchRunnersData(runnerId) {
        this.getRunner(runnerId);
      },
      cancelModal(){
        this.$router.push('/runners');
      },
      handleDataChange() {
        // This method should be fired every time the JSON editor's content changes
        this.changesOnApp = this.calculateChanges(this.initialApplicationData, this.editorData);
        console.log("Detected Changes:", this.changesOnApp); // Debug: Check detected changes
      },
      calculateChanges(original, updated) {
        const changes = {};
        Object.keys(updated).forEach(key => {
          if (JSON.stringify(original[key]) !== JSON.stringify(updated[key])) {
            changes[key] = updated[key];
          }
        });
        return changes;
      },
    }
  }
  </script>
  
  <style>
  .json-editor-container {
    width: 100%;
    height: 69vh;
  }
  .jsoneditor-vue {
    height: 75vh;
  }
  
  .v-sheet.v-card {
      border-radius: 0px;
  }
  </style>
  