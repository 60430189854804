<template>
    <v-card 
    :to="routeId"
    flat class="feed-item px-2 py-0" outlined >
      <v-card-title class="pa-0">
        <!-- Dynamic Icon for Locations groups -->
        <v-avatar
          :class="this.isDarkTheme ? 'slight-radius_dark' : 'slight-radius_light'"
          color="primary"
          size="32">
        <span class="white--text subtitle-1">{{ avatarName(data.name).toUpperCase() }}</span>
      </v-avatar>
        <!-- Info container nsoft mostar-->
        <div class="item-info">
          <v-card-text class="px-0 py-1">
            <v-list-item-subtitle
                class="caption letter-spacing-normal font-weight-bold"
                v-if="data"
            >{{ data["name"] }}
            </v-list-item-subtitle>
            <v-list-item-subtitle
                class="caption text-secondary letter-spacing-normal font-weight-normal"
                v-if="data"
            >{{ data["address"] }}
            </v-list-item-subtitle>
          </v-card-text>
        </div>
      </v-card-title>
    </v-card>
  </template>
  <script>
  import {mdiCctv, mdiDevices, mdiLaptop, mdiRaspberryPi} from "@mdi/js";

  export default {
    props: ["data"],
    data: () => ({
      open: false,
      routeId: {
        name: "",
        params: {},
      },
      icons: {
        "mdiDevices": mdiDevices,
        "Linux Computer": mdiLaptop,
        "IP Camera": mdiCctv,
        "RaspberryPi": mdiRaspberryPi,
      },
    }),
     watch: {
      data: {
        handler() {
          this.$forceUpdate();
        },
        deep: true
      }
    },
    methods: {
      avatarName(string) {
        var matches = string.match(/\b(\w)/g);
        matches.splice(3);
        return matches.join("");
      },
      getColor(type) {
      var colors = {
        "online": "#2B85D8",
        "partially_online": "#3AB0C0",
        "offline": "#7A7A7A",
      };
      this.dataColor = type;
      return colors[type];
    },
    
    },
    computed:{
      isDarkTheme() {
        return this.$vuetify.theme.dark || false;
      },
    },
    mounted(){
        this.routeId = {
        name: "location",
        params: {
          location_type: "location",
          action: "view",
          uuid: this.$props.data.uuid,
          tab: "general"
        },
      };
    }
       
  };
  </script>
  <style scoped lang="scss">
  ::v-deep {
    .short{
    width:100px;
  }
  .short span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
    .v-chip {
      height: 16px;
      background-color: #e4e5e6 !important;
      border-radius: 4px !important;
      padding-left: 8px;
      padding-right: 8px;
      margin-top: -1px;
    }
    
  
    .v-chip__content {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      color: var(--v-secondaryText-base);
    }
  }
  .icon_item{
    margin-left:0;
  }
  .feed-item {
    transition: all 0.1s ease;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  
    &:hover {
      border: solid 1px var(--v-secondaryText-base);
    }
  
    .slight-radius_light {
      border-radius: 4px !important;
      border: 1px solid black !important;
      
    }
    .slight-radius_dark {
      border-radius: 4px !important;
      border: 1px solid white !important;
      
    }
  
    .item-icon {
      padding: 20px;
      border-radius: 4px;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      
    }
  
    .installed {
      background-color: var(--v-blueishGray-base) !important; //background color for icon
      border: solid 1px var(--v-borderIcon-base);
    }
  
    .offline {
      background-color: #222222 !important;
      border: solid 1px var(--v-borderIcon-base);
    }
    .non_connected{
      background-color: var(--v-lightGrey-base) !important;
    }
  
    .connected_icon {
      color: var(--v-general_bckgr-base) !important;
    }
  
    .disconnected_icon {
      color: var(--v-secondaryText-base) !important;
    }
  
    .non_connected_icon {
      color: var(--v-secondaryText-base) !important;
    }
    .online_device{
      background-color: #2b85d8 !important;
      opacity:1;
    }
    .offline_device{
      background-color: #95C2EC !important;
      opacity: 0.8;
    }
    .uninstalled_device{
      border: 1px solid black;
    }
  
  
  }
  
  .item-info {
    margin-left: 16px;
    margin-top: -1px;
    text-overflow: ellipsis;
    max-width: 15ch !important;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    
  }
  .dark-border {
    border:1px solid white;
  }
  
  .light-border {
    border:1px solid black;
  }
  
  </style>
  