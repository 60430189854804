<template>
  <v-navigation-drawer
      app
      color="blackish"
      width="100"
      dark
      class="nav-drawer"
      mobile-breakpoint="600"
  >
    <div class="d-none d-sm-flex justify-center ma-4">
      <router-link
          style="height: 48px;"
          to="/devices">
        <img
            height="48"
            src="../assets/logo.png"
            alt="logo"/>
      </router-link>
    </div>
    <v-list class="pa-0">
      <v-list-item
          v-for="item in isAdmin"
          :key="item.title"
          link
          router
          class="nav-list"
          :to="item.route"
          :class="{
                  'bg-color': isLocationTab && item.index === 1,
                  'group-class': isGroupTab && item.index === 1,
                  'performance-class': isPerformanceTab && item.index === 2,
                  'runners-class': isRunnersTab && item.index === 3,
                 
                }"
          active-class="bg-color"
      >
      <v-list-item-content class="py-4">
          <v-icon class="mb-2 text-center">
            {{ item.icon }}
          </v-icon>
          <v-list-item-title class="caption font-weight-medium text-center text-wrap">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mdiAccountMultiple, mdiBriefcaseVariant, mdiGauge, mdiWrench } from "@mdi/js";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      items: [
        {
          index: 0,
          title: this.$t('sidebar.accountsView'),
          icon: mdiAccountMultiple,
          route: '/accounts'
        },
        {
          index: 1,
          title: this.$t('sidebar.assetsView'),
          icon: mdiBriefcaseVariant,
          route: '/devices',
        },
        {
          index: 2,
          title: this.$t('sidebar.monitorView'),
          icon: mdiGauge,
          route: '/activity',
        },
        {
          index: 3,
          title: this.$t('sidebar.applicationsView'),
          icon: mdiWrench,
          route: '/applications',
        },
      ],
    };
  },
  watch: {},
  computed: {
    ...mapGetters({"user": "accounts/getUser"}),
    isAdmin(){
      if (!this.user.super_admin) {
      return this.items.filter(item => item.index !== 3);
    }
    // Return all items if the user is a super admin
    return this.items;
    },
    isLocationTab() {
      return ['locations', 'location', 'location_action'].includes(this.routeName);
    },
    isPerformanceTab(){
      return ['logs', 'events', 'performance', 'monitoring'].includes(this.routeName)
    },
    isGroupTab(){
      return ['groups', 'group', 'group_action'].includes(this.routeName)
    },
    isRunnersTab(){
      return ['Runners', 'RunnerDetails'].includes(this.routeName)
    },
   routeName() {
     return this.$route.name;
   },
  },
};
</script>

<style
    lang="scss"
    scoped>
.nav-list {
  height: 64px;
}

.bg-color, .performance-class, .group-class, .runners-class {
  background-color: rgba(255, 255, 255, 0.08);
}

.bgrd_color {
  background-color:var(--v-blackish-base);
}

.v-navigation-drawer {
  z-index: 999;
}

::v-deep {
  .v-list-item--active::before {
    display: none;
  }
}

.nav-drawer {
  z-index: 900;
}
.text-wrap {
  white-space: normal;
  line-height: 1.2;
}
</style>