<template>
  <div>
    <v-row>
      <v-col class="detect-devices-container">
        <!--if selected devices-->
        <FlyoutMenuHead
            :selectedItem="stepperActive ? currentDevice : detectDevicesFlyoutMenu"
            :type="stepperActive && 'camera'"
        />
        <v-divider class="mt-2"/>
        <AddDevice @formDataChanged="handleFormDataChange" v-if="currentDevice.uuid" v-model="currentDevice" :tags="tags"/>
        <!---->
        <div class="scrollable-container mt-10" v-if="!stepperActive">
          <span class="caption text-secondary"
          >Select desired Location and trigger device detection on Location.
            You will be able to add and connect detected devices.</span
          >
          <v-row class="mt-4">
            <v-col cols="12" md="6" sm="6" xs="6">
              <v-select
                  :append-icon="$vuetify.icons.values.chevronDown"
                  v-model="form.location_uuid"
                  height="40px"
                  dense
                  outlined
                  label="Location"
                  item-text="name"
                  item-value="uuid"
                  :items="locationsGetter"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-btn
                  color="primary"
                  height="40px"
                  width="140px"
                  class="text-capitalize"
                  depressed
                  @click="detectDevices"
              >
                <v-progress-circular
                    indeterminate
                    :size="24"
                    :width="2"
                    color="white"
                    v-if="this.detectingCameras"
                ></v-progress-circular>
                <span
                    class="text--white font-weight-bold letter-spacing-normal"
                    v-else
                >{{ this.firstDetect ? "Detect Devices" : "Detect again" }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" v-if="detectionTime">
              <div class="outlined d-flex flex-column pa-2">
                <span class="subtitle-2 font-weight-medium">Last Scan</span>
                <span class="caption grey--text">{{ detectionTimeFormatted }}</span>
              </div>
            </v-col>
            <v-col class="pr-0" cols="4" v-if="detectionTime">
              <div class="outlined d-flex flex-column pa-2">
                <span class="subtitle-2 font-weight-medium"
                >Total Detections</span
                >
                <span
                    class="caption grey--text">{{ getDetectDevices ? getDetectDevices.length : 0 }} devices</span>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="cameraScanUnavailable">
            <v-col cols="12" lg="6" offset="3" class="text-center">
              <v-img :src="require(`../../assets/empty-detect-services.png`)" max-height="386px" aspect-ratio="1"
                     contain></v-img>
              <span class=" subtitle-2 grey--text font-weight-regular">We couldn't find available detection services. Check if you have a proper Runner Device installed and running selected Location</span>
              <v-btn
                  color="primary"
                  height="36px"
                  width="140px"
                  class="text-capitalize mt-4"
                  depressed
                  @click="detectDevices"
              >
              <v-progress-circular
                    indeterminate
                    :size="24"
                    :width="2"
                    color="white"
                    v-if="this.detectingCameras"
                ></v-progress-circular>
                <span
                    class="text--white font-weight-bold letter-spacing-normal"
                    v-else
                >Try Again
                </span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
              v-else-if="this.firstDetect === false && getDetectDevices && getDetectDevices.length === 0">
            <v-col cols="12" lg="6" offset="3" class="text-center">
              <v-row>
                <v-img :src="require(`../../assets/empty-detect-services.png`)" max-height="386px" aspect-ratio="1"
                     contain></v-img>
              <span class=" subtitle-2 grey--text font-weight-regular">No devices found. Make sure your devices are turned on and available on the network before triggering detection.</span>
              </v-row>
              <v-row align-content="center" justify="center">
                <v-btn
                  color="primary"
                  height="36px"
                  width="140px"
                  class="text-capitalize mt-4"
                  depressed
                  @click="detectDevices">
                <v-progress-circular
                    indeterminate
                    :size="24"
                    :width="2"
                    color="white"
                    v-if="this.detectingCameras"
                ></v-progress-circular>
                <span
                    class="text--white font-weight-bold letter-spacing-normal"
                    v-else
                >Try Again
                </span>
              </v-btn>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-else-if="this.getDetectDevices.length > 0">
            <v-col cols="12" md="12" sm="12" xs="12">
              <v-data-table
                  class="data_table"
                  v-model="selectedDevices"
                  :headers="table.headers"
                  :items="selectableDevices"
                  v-if="getDetectDevices"
                  item-key="uuid"
                  show-select
                  :items-per-page="7"
                  style="border: 1px solid #EEEEEE;"
                  hide-default-footer
              > 
                <template v-slot:[`item.online`]="{ item }">
                  <v-chip
                      text-color="white"
                      class="text-center"
                      label
                      :color="item.online ? 'green' : 'red'"
                      small
                  >{{ item.online ? "Online" : "False" }}
                  </v-chip
                  >
                </template>
                <template v-slot:[`item.location_name`]="{ item }">
                  {{ item.location_name ? item.location_name : "Not assigned" }}
                </template>
                <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                  <v-simple-checkbox
                      :ripple="false"
                      :value="isSelected || item.connected_status === 'connected'"
                      :readonly="item.connected_status === 'connected'"
                      :disabled="item.connected_status === 'connected'"
                      @click="select()"
                  ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.id`]="{ item }">
                  <div class="inline" style="width: 10px;">
                    {{ getDetectDevices.indexOf(item) + 1 }}
                  </div>
                </template>
                <template v-slot:footer>
            <v-row class="mb-2 row-footer">
            <v-col cols="2">
              <v-select
                v-model="accountRowsNumber"
                :items="itemsPerPage"
                hide-details
                dense
                height="30px"
                outlined
                class="custom-select"
              ></v-select>
            </v-col>
            <v-col class="pa-0 mr-16 pr-16">
              <template>
                <!-- Custom pagination -->
                      <div class="custom-pagination">
                        <v-btn
                          icon
                          :disabled="currentPage === 1"
                          @click="currentPage--"
                        ><v-icon medium>{{ icon.arrowLeft }}</v-icon>
                        </v-btn>
                        <div class="pagination-numbers">
                          <span
                            v-for="page in numberOfPages"
                            :key="page"
                            class="page-number"
                            :class="{ active: page === currentPage }"
                            @click="currentPage = page"
                          >
                            {{ page }}
                          </span>
                        </div>
                        <v-btn
                          icon
                          :disabled="currentPage === numberOfPages"
                          @click="currentPage++"
                        ><v-icon medium>{{ icon.arrowRight }}</v-icon>
                        </v-btn>
                      </div>
                    </template>
                      </v-col>
                    </v-row>
              </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-space-between align-center bottom-navigation-edit py-3 px-2">
      <v-col v-if="stepperActive">
        <span>Add Device {{ currentDeviceOrder }}/{{ selectedDevices.length }}</span>
      </v-col>
      <v-col class="text-right">
        <v-btn
            width="120"
            class="mr-2 font-weight-regular letter-spacing-normal text-capitalize"
            depressed
            @click="handleCancel"
            text
        >{{ $t("common.cancelBtn") }}
        </v-btn>
        <v-btn
            width="120"
            class="primary font-weight-normal letter-spacing-normal text-capitalize"
            depressed
            color="primary"
            :disabled="selectedDevices.length === 0"
            @click="addCameras"
        >Add {{ !stepperActive && selectedDevices.length > 1 ? `(${selectedDevices.length})` : '' }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FlyoutMenuHead from "../FlyoutMenuHead";
import AddDevice from "./Device/AddDevice";
import {mapGetters, mapActions} from "vuex";
import { formatDistanceToNow } from "date-fns";
import DevicesApi from "@/services/api/Devices";
import Tags from "@/services/api/Tags";
import { mdiArrowRight, mdiArrowLeft } from '@mdi/js';

export default {
  name: "DetectDevices",
  components: {
    FlyoutMenuHead,
    AddDevice,
  },
  computed: {
    ...mapGetters({
      locationsGetter: "devices/getFilledLocations",
      detectedCamerasGetter: "locations/getDetectedCameras",
      detectingCameras: "locations/getCameraDetectionState",
      getDetectDevices: "locations/getDetectedDevices",
      noDevicesDetectGetter: "devices/getNonDevices",
      detectDescription: "devices/getDescriptionDetect",
      detectTags: "devices/getTagsDetect"
    }),
    detectionTimeFormatted() {
      return formatDistanceToNow(this.detectionTime, {
        addSuffix: true,
      });
    },
    selectableDevices(){
      return this.getDetectDevices.map(x =>({...x, isSelectable: x.connected_status === 'non_connected'}))
    },
    numberOfPages(){
      return Math.ceil(this.getDetectDevices?.length / this.accountRowsNumber);
    },
  },
  watch: {
    // detectedCamerasGetter: {
    //   handler(newVal, oldVal) {
    //   },
    //   deep: true
    // },
    currentDevice: function (newVal) {
      this.cameraFlyoutMenu.name = newVal.name;
    },
    noDevicesDetectGetter: {
      handler(val){
        this.cameraScanUnavailable = val;
      },
      deep: true
    },
    cameraScanUnavailable(){
      this.detectionTime = new Date()
    }
  },
  mounted() { 
    this.getTags();
    if (this.locationsGetter.length > 0) {
      this.locations = this.locationsGetter;
    } else {
      this.getLocations().then((locations) => {
        this.locations = locations;
      });
    }
  },
  methods: {
    ...mapActions({
      getLocations: "devices/setFilledLocations",
      detectLocationDevices: "locations/detectDevices",
      addAlert: "global/addAlert",
      getCameras: "locations/getCameras",
      addCamera: "devices/addCamera",
      addDevice: "devices/addDevice",
      setDetectDevices: "locations/setDetectDevices"
    }),
    handleFormDataChange(newData) {
      // Update parent component data based on received object
      if (newData.description) {
        this.description = newData.description;
      }
      if (newData.tags) {
        this.selectedTags = newData.tags;
      }
    },
    getTags() {
      Tags.get().then(response => {
        this.tags = response.data;
      });
    },
    handleCancel() {
      if (this.stepperActive) {
        this.stepperActive = false;
        this.selectedDevices = {};
        this.currentDevice = {};
        this.currentDeviceOrder = 0;
      } else {
        this.$store.commit('locations/SET_DETECTED_DEVICES', []);
        this.$store.commit("devices/SET_NO_DETECT_DEVICES", false);
        this.$router.replace('/devices');
      }
    },
    addCameras() {
      if (!this.stepperActive && this.selectedDevices.length > 0) {
        this.stepperActive = true;
        this.currentDeviceOrder = 1;
        this.currentDevice = this.selectedDevices[this.currentDeviceOrder - 1];
      } else {
        let location_uuid = this.currentDevice.location_uuid;
        let device = {
          uuid: this.currentDevice.uuid,
          name: this.currentDevice.name,
          description: this.detectDescription,
          tag_uuids: this.detectTags
        };
        DevicesApi.installDevice(location_uuid, device).then(() => {
          this.addAlert({
            type: "success",
            message: `Device ${this.currentDevice.name} added successfully.`,
          });
          if (this.selectedDevices.length === this.currentDeviceOrder) {
            this.$router.push("/devices");
            this.$store.commit("devices/SET_DETECTION_DESCRIPTION", "");
            this.$store.commit("devices/SET_DETECTION_TAGS", []);
          } else {
            this.currentDeviceOrder++;
            this.currentDevice = this.selectedDevices[this.currentDeviceOrder - 1];
            this.$store.commit("devices/SET_DETECTION_DESCRIPTION", "");
            this.$store.commit("devices/SET_DETECTION_TAGS", []);
          }
        }).catch((err) => {
              this.addAlert({
                type: "error",
                message: `An error occurred!`,
              });
              console.log(err);
            });
        
      }
    },
    detectDevices() {
      this.detectLocationDevices(this.form.location_uuid);
        if (this.channel) {
          this.$pusher.unsubscribe(this.channel.name);
        }
        this.channel = this.$pusher.subscribe(`location_${this.form.location_uuid}`);

        const timeoutId = setTimeout(() => {
          this.handleDefault(); // Call your default handling function
        }, 5000);

        this.channel.bind("locationDetailsUpdate", (data) => {
          switch (data.type) {
            case "devicesDetected":
              this.setDetectDevices(this.form.location_uuid);
              this.$store.commit("locations/DETECTING_CAMERAS", false);
              this.$store.commit("devices/SET_NO_DETECT_DEVICES", false);
              this.cameraScanUnavailable = false;
              clearTimeout(timeoutId);
              this.detectionTime = new Date()
              break;
            case "noDevicesDetected":
              this.detectionTime = new Date()
              this.cameraScanUnavailable = true;
              clearTimeout(timeoutId);
              this.$store.commit("locations/DETECTING_CAMERAS", false);
              break;
            }
        });
    },
    handleDefault() {
      // Perform actions for the default scenario, such as:
      this.$store.commit("locations/DETECTING_CAMERAS", false);
      // ... any other necessary actions
    }
  },
  data: () => ({
    channel: null,
    stepperActive: false,
    selectedDevices: [],
    tags: [],
    description: null,
    selectedTags: null,
    detectionTime: null,
    accountRowsNumber: 10,
    currentPage: 1,
    itemsPerPage:[
        { text: '5 Rows', value: 5 },
        { text: '10 Rows', value: 10 },
        { text: '15 Rows', value: 15 },
        { text: '20 Rows', value: 20 },
      ],
    icon:{
      arrowRight: mdiArrowRight,
      arrowLeft: mdiArrowLeft,
    },
    cameraScanUnavailable: false,
    currentDevice: {},
    currentDeviceOrder: 0,
    table: {
      headers: [
        {
            class: "textColor--text",
            sortable: false,
            text: '#',
            value: 'id',
          },
        {
          text: "Device Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Device Type",
          align: "start",
          sortable: false,
          value: "device_type_name",
        },
        {
          text: "IP Address",
          align: "start",
          sortable: false,
          value: "ip_address",
        },
        {
          text: "Location",
          align: "start",
          sortable: false,
          value: "location_name",
        },
        {
          text: "Connection Status",
          align: "start",
          sortable: false,
          value: "connected_status",
        },
      ],
    },
    locations: [],
    firstDetect: true,
    form: {
      location_uuid: "",
    },
    cameraFlyoutMenu: {
      name: "",
    },
    detectDevicesFlyoutMenu: {
      name: "Detect Devices",
      subtitle:
          "Device detection services scan your local network and retrieve found devices.",
    },
  }),
};
</script>

<style scoped>
.data_table .v-data-table-header {
  background-color: var(--v-lineColor-base) !important;
}
.detect-devices-container {
  max-height: 100vh;
}
.spanItemCount{
  width: 61px;
  height: 14px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #A7A7A7;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.bottom-navigation-edit {
  position: absolute;
  bottom: 0 !important;
  left: 0;
  right: 0;
  margin: 0;
  box-shadow: 0 -4px 25px 0 rgba(0, 0, 0, 0.13);
}
.outlined{
  border-radius: 4px;
  border: 1px solid var(--v-lineColor-base);
}
.scrollable-container {
  max-height:68vh; /* or whatever maximum height you want */
  overflow-y: auto;  /* make it scrollable when content overflows */
  margin-bottom: 100px;
}
.custom-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-numbers {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-number {
  margin: 0 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color:#7A7A7A;
  transition: all 0.2s ease-in-out;
}
.page-number.active {
  color: var(--v-secondary-base);
}
.row-footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 56px;
  border-top: 1px solid #E0E0E0;
}
.custom-select {
  width: 200px;
  font-size: 12px;
  min-height: 1em;
}
.inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
</style>
