<template>
    <div class="fill-height" v-if="getLocationGroup">
        <v-row>
          <v-col>
            <FlyoutMenuHead
                type="group"
                route="group"
                :selectedItem="getLocationGroup"
                :channel="channel"
                />
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-tabs
              class="px-3 d-block transparent"
              :value="tabs.indexOf($route.params.tab)"
              active-class="selected-tab"
              >
              <v-tab
                class="tab px-6 transparent"
                @click="selectTab('general')">
                {{ $t('assets.groups.groupInfo.tabs.location') }}
            </v-tab>
          </v-tabs>
        </v-row>
        <v-divider></v-divider>
        <group-info-location :group="getLocationGroup"/>
    </div>
  </template>
  <script>
  import {mapGetters} from 'vuex';
  import FlyoutMenuHead from '@/components//FlyoutMenuHead';
  import GroupInfoLocation from './GroupInfoLocation.vue';

  
  export default {
    props: ['device', 'type', 'channel'],
    name: 'GroupInfo',
    components: {
      FlyoutMenuHead,
      GroupInfoLocation
    },
    data: () => ({
     tabs: ['location']
    }),
    computed: {
      ...mapGetters({'getLocationGroup': 'groups/getGroup'}),
    },

  };
  </script>
  <style
      lang="scss"
      scoped>
  .device-icon {
    padding: 20px;
    background-color: var(--v-lightGrey-base);
    border-radius: 5px;
    width: 64px !important;
    height: 64px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .installed {
    background-color: var(--v-blackish-base) !important;
  }
  
  ::v-deep {
    .theme--light.v-tabs > .v-tabs-bar {
      background-color: transparent !important;
    }
  
    .v-tab {
      letter-spacing: normal;
    }
  
    div.v-slide-group__prev.v-slide-group__prev--disabled {
      display: none !important;
    }
  }
  
  .selected-tab {
    color: var(--v-textColor-base);
  }
  </style>
  