<template>
  <div class="fill-height">
    <v-row v-if="!getSelectedDevice"
           class="text-center fill-height"
           align="center">
      <v-col>
        <v-progress-circular
            color="primary"
            indeterminate
            size="48">
        </v-progress-circular>
      </v-col>
    </v-row>
    <template v-else-if="getSelectedDevice.uuid">
      <v-row>
        <v-col>
          <FlyoutMenuHead
              :selectedItem="getSelectedDevice"
              :type="type"
              route="devices"/>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-tabs
            v-if="getSelectedDevice"
            class="px-3 d-block transparent"
            active-class="selected-tab">
          <v-tab
              class="tab px-6 transparent"
              @click="selectTab('general')">
            {{ $t('assets.devices.deviceInfo.tabs.general') }}
          </v-tab>
          <v-tab
              class="tab px-6"
              :disabled="getSelectedDevice.connected_status !== 'connected'"
              @click="selectTab('assetInfo')">
            Asset Info
          </v-tab>
          <v-tab
              class="tab px-6"
              :disabled="getSelectedDevice.connected_status !== 'connected'"
              @click="selectTab('logs')">
            Logs
          </v-tab>
          <v-tab
              class="tab px-6"
              :disabled="getSelectedDevice.connected_status !== 'connected'"
              @switchTab="(tab) => { selectTab(tab) }"
              @click="selectTab('reports')">
            Reports
          </v-tab>
          <v-tab
              class="tab px-6"
              :disabled="getSelectedDevice.connected_status !== 'connected'"
              @switchTab="(tab) => { selectTab(tab) }"
              @click="selectTab('settings')">
            Settings
          </v-tab>
        </v-tabs>
      </v-row>
      <v-divider ></v-divider>
      <CameraInfoGeneral
          v-if="selectedTab === 'general'"
          :device="getSelectedDevice"/>
      <CameraInfoAssetInfo
          v-if="selectedTab === 'assetInfo'"
          :device="getSelectedDevice"/>
      <CameraInfoSettings
          v-if="selectedTab === 'settings'"
          :device="getSelectedDevice"/>

    </template>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import FlyoutMenuHead from '@/components/FlyoutMenuHead';
import CameraInfoGeneral from './CameraInfoGeneral';
import CameraInfoAssetInfo from './CameraInfoAssetInfo';
import CameraInfoSettings from './CameraInfoSettings';

export default {
  props: ['device', 'type'],
  name: 'CameraInfo',
  components: {
    FlyoutMenuHead,
    CameraInfoGeneral,
    CameraInfoAssetInfo,
    CameraInfoSettings
  },
  data: () => ({
    selectedTab: null,
  }),
  computed: {
    ...mapGetters('devices', ['getSelectedDevice']),
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>
<style
    lang="scss"
    scoped>
.device-icon {
  padding: 20px;
  background-color: var(--v-lightGrey-base);
  border-radius: 5px;
  width: 64px !important;
  height: 64px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.installed {
  background-color: var(--v-blackish-base) !important;
}

::v-deep {
  .theme--light .v-tabs > .v-tabs-bar {
    background-color: transparent !important;
  }

  .v-tab {
    letter-spacing: normal;
  }

  div.v-slide-group__prev.v-slide-group__prev--disabled {
    display: none !important;
  }
}

.selected-tab {
  color: var(--v-textColor-base);
}
</style>
