import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store';
import Pusher from 'pusher-js';
import axios from 'axios';
import router from './router'
import {i18n} from './plugins/i18n';
import VueMeta from 'vue-meta';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueGeolocation from 'vue-browser-geolocation';
import './registerServiceWorker'

Vue.use(VueMeta);
Vue.use(VueGeolocation);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    installComponents: true,
    libraries: 'places'
  }
});
Vue.prototype.$pusher = new Pusher('310e1277542af485e1b6', {
  cluster: 'eu',
  forceTLS: true
});

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.config.productionTip = false;
i18n.locale = store.state.lang;
new Vue({
  i18n,
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app');
