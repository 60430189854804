import axios from 'axios';
import * as querystring from "querystring";
import router from '../router';

export class TokenStorage {

  static LOCAL_STORAGE_IAM_DATA = 'iam_data';

  static isAuthenticated() {
    return this.getAccessToken() !== undefined;
  }

  static getEmail() {
    let jwt = JSON.parse(atob(this.getAccessToken().split('.')[1]));
    return jwt.email;
  }

  static getAuthentication() {
    let headers = {};
    if (this.isAuthenticated()) {
      headers = {
        'Authorization': 'Bearer ' + this.getAccessToken()
      }
    }
    return {
      headers: {
        ...headers,
        'Accept': 'application/json'
      }
    };
  }

  static getNewAccessToken() {
    return new Promise((resolve, reject) => {
      axios.post(
        `${process.env["VUE_APP_KEYCLOAK_URL"]}/auth/realms/device-management/protocol/openid-connect/token`,
        querystring.stringify({
          "client_id": process.env["VUE_APP_KEYCLOAK_CLIENT_ID"],
          "grant_type": "refresh_token",
          "refresh_token": this.getRefreshToken()
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      ).then(response => {
        this.storeIAMData(response.data);
        resolve(response.data.access_token);
      }).catch((error) => {
        this.clear();
        reject(error);
        return router.replace({name: 'Login'});
      })
    })
  }

  static storeIAMData(data) {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_IAM_DATA, JSON.stringify(data));
  }

  static clear() {
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_IAM_DATA);
  }

  static getRefreshToken() {
    return JSON.parse(localStorage.getItem(TokenStorage.LOCAL_STORAGE_IAM_DATA))?.refresh_token;
  }

  static getAccessToken() {
    return JSON.parse(localStorage.getItem(TokenStorage.LOCAL_STORAGE_IAM_DATA))?.access_token;
  }

}
