<template>
  <v-row class="fill-height scroll-left-lg-y scroll-left-md-y">
    <v-col md="8" class="content-height overflow-y-auto">
      <v-row>
        <v-col class="mt-4 pb-0">
          <span class="subtitle text-secondary">Displays</span>
          <v-divider class="mt-4"></v-divider>
        </v-col>
      </v-row>
      <v-row class="content-height overflow-y-auto ">
        <v-col class="py-0">
          <PeripheralsDisplays
              :device="device"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mt-4 pb-0">
          <span class="subtitle text-secondary">Misc</span>
          <v-divider class="mt-4"></v-divider>
        </v-col>
      </v-row>
      <v-row class="content-height overflow-y-auto">
        <v-col class="py-0">
          <PeripheralsMisc
              :device="device"/>
        </v-col>
      </v-row>
      <v-row class="justify-start d-flex px-3 mt-4 mb-10">
        <v-btn @click="detectMisc" color="primary" class="text-capitalize letter-spacing-normal">
          Detect misc.
        </v-btn>
        <v-btn @click="detectDisplay" color="primary" class="ml-2 text-capitalize letter-spacing-normal">
          Detect displays
        </v-btn>
      </v-row>
    </v-col>
    <v-divider
        class="d-none d-md-flex"
        vertical/>
    <v-col
        cols="12"
        sm="12"
        md="4"
        class="ml-n3 py-0 pr-0 content-height overflow-y-auto">
      <PeripheralInfo :device="device">
      </PeripheralInfo>
    </v-col>
  </v-row>
</template>

<script>
import PeripheralsDisplays from './peripherals/Displays';
import PeripheralsMisc from './peripherals/Misc';
import PeripheralInfo from './peripherals/Info';
import {mapActions} from "vuex";
import Devices from '../../../services/api/Devices';

export default {
  props: ['device', 'switchTab'],
  components: {
    PeripheralsDisplays,
    PeripheralsMisc,
    PeripheralInfo
  },
  metaInfo() {
    return {
      title: `Peripherals - ${this.device.name}` || "",
    }
  },
  methods: {
    ...mapActions({
      "detectPeripherals": "devices/detectPeripherals",
      "detectDisplays": "devices/detectDisplays",
    }),
    detectMisc() {
      Devices.detectPeripherals(this.device.uuid).then((response) => {
          console.log(response)
        });
    },
    detectDisplay() {
      Devices.detectDisplays(this.device.uuid).then((response) => {
        console.log(response)
        });
    }
  }
};
</script>

<style scoped>

</style>
