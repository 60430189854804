<template>
    <div class="fill-height" v-if="getSelectedDevice">
      <v-row v-if="!getSelectedDevice.uuid"
           class="text-center fill-height"
           align="center">
      <v-col>
        <v-progress-circular
            color="primary"
            indeterminate
            size="48">
        </v-progress-circular>
      </v-col>
    </v-row>
    <template v-else-if="getSelectedDevice.uuid">
      <v-row>
        <v-col>
          <FlyoutMenuHead
              :selectedItem="getSelectedDevice"
              :type="type"
              route="monitoring"
              :channel="channel"
              />
        </v-col>
      </v-row>
      <v-divider></v-divider>
        <v-row>
        <v-tabs
            v-if="getSelectedDevice"
            class="px-3 d-block transparent"
            :value="tabs.indexOf($route.params.tab)"
            active-class="selected-tab"
            >
          <v-tab
              class="tab px-6 transparent"
              @click="selectTab('general')">
            {{ $t('assets.devices.deviceInfo.tabs.general') }}
          </v-tab>
          <v-tab
              class="tab px-6"
              :disabled="getSelectedDevice.connected_status !== 'connected'"
              @click="selectTab('peripherals')">
            {{ $t('assets.devices.deviceInfo.tabs.peripherals') }}
          </v-tab>
          <v-tab
              class="tab px-6"
              :disabled="getSelectedDevice.connected_status !== 'connected'"
              @click="selectTab('assetInfo')">
            {{ $t('assets.devices.deviceInfo.tabs.assetInfo') }}
          </v-tab>
          <v-tab
              class="tab px-6"
              :disabled="getSelectedDevice.connected_status !== 'connected'"
              @switchTab="(tab) => { selectTab(tab) }"
              @click="selectTab('logs')">
            {{ $t('assets.devices.deviceInfo.tabs.logs') }}
          </v-tab>
          <v-tab
              class="tab px-6"
              :disabled="getSelectedDevice.connected_status !== 'connected'"
              @switchTab="(tab) => { selectTab(tab) }"
              @click="selectTab('reports')">
            {{ $t('assets.devices.deviceInfo.tabs.reports') }}
          </v-tab>
          <v-tab
              class="tab px-6"
              :disabled="getSelectedDevice.connected_status !== 'connected'"
              @switchTab="(tab) => { selectTab(tab) }"
              @click="selectTab('settings')">
            {{ $t('assets.devices.deviceInfo.tabs.settings') }}
          </v-tab>
        </v-tabs>
      </v-row>
    <v-divider></v-divider>
      <DeviceInfoGeneral
          @switchTab="(tab) => { selectTab(tab) }"
          v-if="$route.params.tab === 'general'"
          :device="getSelectedDevice"/>
      <DeviceInfoPeripherals
          @switchTab="(tab) => { selectTab(tab) }"
          v-if="$route.params.tab === 'peripherals'"
          :device="getSelectedDevice"/>
      <DeviceInfoAssetInfo
          @switchTab="(tab) => { selectTab(tab) }"
          v-if="$route.params.tab === 'assetInfo'"
          :device="getSelectedDevice"/>
      <MonitorInfoLogs
          @switchTab="(tab) => { selectTab(tab) }"
          v-if="$route.params.tab === 'logs'"
          :device="getSelectedDevice"
          :channel="channel"
          />
      <MonitorInfoReports
          @switchTab="(tab) => { selectTab(tab) }"
          v-if="$route.params.tab === 'reports'"
          :device="getSelectedDevice"
          :channel="channel"
          />
      <DeviceInfoSettings
          @switchTab="(tab) => { selectTab(tab) }"
          v-if="$route.params.tab === 'settings'"
          :device="getSelectedDevice"
          :channel="channel"
          />
        </template>
    </div>
  
</template>
<script>
import {mapGetters} from 'vuex';
import FlyoutMenuHead from '@/components//FlyoutMenuHead';
import DeviceInfoGeneral from '../../Devices/Device/DeviceInfoGeneral';
import DeviceInfoPeripherals from '../../Devices/Device/DeviceInfoPeripherals';
import DeviceInfoAssetInfo from '../../Devices/Device/DeviceInfoAssetInfo';
import DeviceInfoSettings from '../../Devices/Device/DeviceInfoSettings';
import {mdiCctv,  mdiLaptop,  mdiRaspberryPi, mdiDevices} from "@mdi/js";
export default {
components: {
    DeviceInfoGeneral,
    DeviceInfoPeripherals,
    DeviceInfoAssetInfo,
    DeviceInfoSettings,
    FlyoutMenuHead
  },
  props:["device", "type", "channel"],
  data: () => ({
    showActionItems: null,
    tabs: ['general', 'peripherals', 'assetInfo', 'logs', 'reports', 'settings'],

    icons: {
        'Linux Computer': mdiLaptop,
        camera: mdiCctv,
        RaspberryPi: mdiRaspberryPi,
        Unknown: mdiDevices
      },
  }),
  metaInfo() {
    return {
      title: `General - ${this.getSelectedDevice?.name}` || "",
    }
  },
  computed: {
    ...mapGetters({'devices': 'devices/getDevices', 'getSelectedDevice': 'devices/getSelectedDevice'})
  },
  methods: {
    selectTab(tab) {
      this.$router.replace({params: {tab: tab, device: this.getSelectedDevice}})
      
    },
  },
}
</script>

<style>
.selected-tab {
  color: var(--v-textColor-base) !important;
}
.v-tab {
    letter-spacing: normal;
  }
  .device-icon {
  padding: 20px;
  background-color: var(--v-lightGrey-base);
  width: 64px !important;
  height: 64px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px !important;
}

.installed {
  background-color: var(--v-blueishGray-base) !important;

}

.offline {
  background-color: #222222 !important;
}

.connected_icon {
  color: var(--v-general_bckgr-base) !important;
}

.disconnected_icon {
  color: var(--v-secondaryText-base) !important;
  
}
 .non_connected_icon {
    color: var(--v-secondaryText-base) !important;
  }
.online {
  background-color: green !important;
}
.offline-icon {
  background-color: red !important;
}
.border-radius-4 {
  border-radius: 4px !important;
}
.online_device{
    background-color: #2b85d8 !important;
    opacity:1;
    border: 1px solid black;
  }
  .offline_device{
    background-color: #95C2EC !important;
    opacity: 0.8;
    border: 1px solid black;
    
  }
  .uninstalled_device{
    border: 1px solid black;
  }
.dark-border {
  border:1px solid white !important;
}

.light-border {
  border:1px solid black !important;
}
</style>