<template>
  <div class="fill-height text-center">
    <v-dialog v-model="dialog"
              max-width="1176"
              class="ma-12">
      <v-card tile
              flat
              class="mb-5">
        <v-img :src="getDeviceScreenshot"/>
      </v-card>
      <v-alert v-model="dialog"
               dense
               dark
               color="greyAlert"
               class="px-3 py-0 mx-auto"
               max-width="617"

               style="position: sticky">
        <v-row align="center">
          <v-col class="grow">
            Screenshot is available!
          </v-col>
          <v-col class="shrink">
            <v-btn @click="openLink(getDeviceScreenshot)"
                   icon>
              <v-icon>{{ icons.mdiDownload }}</v-icon>
            </v-btn>
          </v-col>
          <v-divider vertical/>
          <v-col class="shrink">
            <v-btn @click="closeDialog"
                   icon>
              <v-icon>{{ $vuetify.icons.values.close }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {mdiDownload} from "@mdi/js";

export default {
  name: 'DeviceScreenshot',
  data() {
    return {
      dialog: false,
      icons: {
        mdiDownload: mdiDownload
      }
    };
  },
  computed: {
    ...mapGetters('devices', ['getDeviceScreenshot']),
  },
  methods: {
    openLink(link) {
      window.open(link, '__blank');
    },
    closeDialog() {
      this.dialog = false;
    },
  },
  watch: {
    getDeviceScreenshot() {
      if (this.getDeviceScreenshot) {
        this.dialog = true;
      }
    },
  },
};
</script>

<style lang="scss"
       scoped>
::v-deep {
  .v-dialog {
    box-shadow: none;
    border-radius: 0;
  }
}
</style>
