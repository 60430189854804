import Tags from "@/services/api/Tags";

const state = {
    tags: []
}
const getters = {
    tags(state) {
        return state.tags
    }
}
const actions = {
    addTag({ commit }, tag) {
        return Tags.add(tag).then(response => {
            commit('ADD_TAG', response.data)
        })
    },
    getTags({ commit }, name) {
        return new Promise((resolve, reject) => {
            Tags.get(name).then(response => {
                console.log(response);
                commit("SET_TAGS", response.data.tags);
                resolve(response.data.tags);
            }).catch(error => {
                reject(error);
            });
        })
    },
}
const mutations = {
    SET_TAGS(state, tags) {
        state.tags = tags;
    },
    ADD_TAG(state, tag) {
        state.tags.push(tag);
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}